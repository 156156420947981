import { Component, Input } from '@angular/core';

import { QuestionGroup } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-demographics-questions-container',
  template: `
    <div *ngFor="let questionGroup of questionGroups">
      <assess-survey-demographics-questions-group [questionGroup]="questionGroup"></assess-survey-demographics-questions-group>
    </div>
  `,
  styles: [':host { height: 100%; width: 100% }']
})
export class SurveyDemographicsQuestionsContainerComponent {
  @Input() questionGroups: QuestionGroup[];
}
