import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { ReportViewerState } from './store/report-viewer.state';
import { InstrumentStatus } from '../participant-landing/models/participant.model';
import { NavigateHome } from 'src/app/store/application.actions';

@Component({
  selector: 'assess-report-viewer',
  templateUrl: './report-viewer.component.html',
})
export class ReportViewerComponent implements OnInit {
  report: Blob;

  instrumentStatus: InstrumentStatus;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.report = this._store.selectSnapshot(ReportViewerState.getReport);
    this.instrumentStatus = this._store.selectSnapshot(
      ReportViewerState.getInstrumentStatus
    );
  }

  onBack(): void {
    this._store.dispatch(new NavigateHome());
  }
}
