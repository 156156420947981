import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';

import { Config } from '../../../store/application.models';
import {
  BackgroundLanding,
  BackgroundResponseDocument,
  SaveBackgroundResponse,
} from '../store/background-landing.models';
import { ApplicationState } from '../../../store/application.state';

@Injectable({
  providedIn: 'root',
})
export class BackgroundService {
  public constructor(private _store: Store, private _httpClient: HttpClient) { }

  getBackgroundLanding(
    individualId: number,
    languageId: number
  ): Observable<BackgroundLanding> {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );
    return this._httpClient.get<BackgroundLanding>(
      `${config.assessmentWebApi}/background-landing/${individualId}/${languageId}`
    );
  }

  save(
    backgroundResponseDocument: BackgroundResponseDocument
  ): Observable<SaveBackgroundResponse> {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );
    return this._httpClient.put<SaveBackgroundResponse>(
      `${config.assessmentWebApi}/background-landing`,
      { backgroundResponseDocument }
    );
  }
}
