<div
    fxShow
    fxHide.lt-sm="true"
    fxLayout="row"
    fxLayoutAlign="start center"
    class="striped"
    [ngClass]="{'highlight': highlight}"
>
    <div fxFlex="35%" tabindex="0">{{instrumentStatus.assessmentItemName}}</div>
    <div fxFlex="25%" [fxFlex.lt-md]="20" fxLayout="column" fxLayoutAlign="start center">
        <div
            *ngIf="instrumentStatus.is360 && instrumentStatus.needMoreRaters && !instrumentStatus.assetAvailable"
            class="text-warning"
            tabindex="0"
        >
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{'raterManagement.warning' | translate}}
        </div>
    </div>
    <div fxFlex="40%" [fxFlex.lt-md]="45" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button
            mat-raised-button
            color="primary"
            *ngIf="instrumentStatus.is360 && !instrumentStatus.assetAvailable"
            (click)="onRatersClick()"
        >
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <span>{{'participant.button.raters' | translate}}</span>
                <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
            </div>
        </button>
        <div *ngIf="instrumentStatus.assetAvailable; else noAsset">
            <button mat-raised-button color="primary" (click)="onViewAssetClick()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <span>{{'participant.button.view' | translate}}</span>
                    <i class="fa fa-eye fa-fw" aria-hidden="true"></i>
                </div>
            </button>
        </div>
        <ng-template #noAsset>
            <button
                *ngIf="instrumentStatus.selfStatus.statusId !== 3; else completed"
                mat-raised-button
                color="primary"
                (click)="onSelfClick()"
            >
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <span>{{'participant.button.self' | translate}}</span>
                    <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                </div>
            </button>
            <ng-template #completed>
                <button mat-raised-button disabled>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <span>{{'participant.button.self' | translate}}</span>
                        <i class="fa fa-check text-success fa-fw" aria-hidden="true"></i>
                    </div>
                </button>
            </ng-template>
        </ng-template>
    </div>
</div>
<div fxHide fxShow.lt-sm="true" fxLayout="column" fxLayoutGap="10px">
    <div class="text-bold">{{instrumentStatus.assessmentItemName}}</div>
    <div
        *ngIf="instrumentStatus.is360 && instrumentStatus.needMoreRaters && !instrumentStatus.assetAvailable"
        class="text-warning"
    >
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{'raterManagement.warning' | translate}}
    </div>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button
            mat-raised-button
            color="primary"
            *ngIf="instrumentStatus.is360 && !instrumentStatus.assetAvailable"
            (click)="onRatersClick()"
        >
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <span>{{'participant.button.raters' | translate}}</span>
                <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
            </div>
        </button>
        <div *ngIf="instrumentStatus.assetAvailable; else noAssetMobile">
            <button mat-raised-button color="primary" (click)="onViewAssetClick()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <span>{{'participant.button.view' | translate}}</span>
                    <i class="fa fa-eye fa-fw" aria-hidden="true"></i>
                </div>
            </button>
        </div>
        <ng-template #noAssetMobile>
            <button
                *ngIf="instrumentStatus.selfStatus.statusId !== 3; else completedMobile"
                mat-raised-button
                color="primary"
                (click)="onSelfClick()"
            >
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <span>{{'participant.button.self' | translate}}</span>
                    <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                </div>
            </button>
            <ng-template #completedMobile>
                <button mat-raised-button disabled>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <span>{{'participant.button.self' | translate}}</span>
                        <i class="fa fa-check text-success fa-fw" aria-hidden="true"></i>
                    </div>
                </button>
            </ng-template>
        </ng-template>
    </div>
</div>
