<!-- need to add other edit box and figure out how to save the answer -->
<div fxLayout="column" fxLayoutGap="5px">
  <form novalidate [formGroup]="otherFormGroup">
    <div fxLayout fxLayoutAlign="start" style="padding: 10px;">
      <div fxFlex="0 0 30px">
        <mat-checkbox (change)="onOptionChanged($event)" formControlName="option"></mat-checkbox>
      </div>
      <div [innerHtml]="option.text"></div>
    </div>
    <div *ngIf="option.sequenceNumber === 99999" style="padding: 10px;">
      <mat-form-field *ngIf="showOther" class="full-width">
        <input matInput [attr.id]="'other' + option.backgroundItemOptionId" formControlName="other" autocomplete="nope"
          placeholder="{{backgroundItem.dependentBackgroundItems[0].backgroundItemText.text}}">
      </mat-form-field>
    </div>
  </form>
</div>