  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
  <div fxFlex.lt-md="100" fxFlex="0 0 900px" style="background-color: white; overflow-y:auto; height: 100%;">
    <ngx-loading [show]="showLoading"></ngx-loading>
    <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)" [selectedIndex]="initialTab$ | async"
      style="height: 100%;">
      <mat-tab *ngIf="user.aboutMe">
        <ng-template mat-tab-label>
          <div>{{'tabbedLanding.tab.yourBgi' | translate}}</div>
        </ng-template>
        <assess-background-landing (loading)="onLoading($event)"></assess-background-landing>
      </mat-tab>
      <mat-tab *ngIf="user.isParticipant">
        <ng-template mat-tab-label>
          <div>{{'tabbedLanding.tab.yourSurveys' | translate}}</div>
        </ng-template>
        <assess-participant-landing [handleLoading]="false" (loading)="onLoading($event)">
        </assess-participant-landing>
      </mat-tab>
      <mat-tab *ngIf="user.isRater">
        <ng-template mat-tab-label>
          <div>{{'tabbedLanding.tab.surveysForOthers' | translate}}</div>
        </ng-template>
        <assess-rater-landing [handleLoading]="false" (loading)="onLoading($event)"></assess-rater-landing>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>