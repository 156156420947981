import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Store, Select } from '@ngxs/store';

import {
  ComponentDescriptor,
  SurveyStepperService,
} from '../../modules/survey-stepper';
import { SurveyDocumentState } from '../../store/survey.state';
import { Navigation } from '../../store/survey.actions';
import { NavigationEvent } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<assess-survey-stepper
    [componentDescriptors]="componentDescriptors$ | async"
    [currentSectionIndex]="currentSectionIndex$ | async"
  ></assess-survey-stepper>`,
})
export class SurveyContainerComponent implements OnInit, OnDestroy {
  @Select(SurveyDocumentState.getComponentDescriptors)
  componentDescriptors$: Observable<ComponentDescriptor[]>;

  @Select(SurveyDocumentState.getCurrentSectionIndex)
  currentSectionIndex$: Observable<number>;

  subscription: Subscription;

  constructor(
    private _store: Store,
    private _surveyStepperService: SurveyStepperService
  ) {}

  ngOnInit(): void {
    this.subscription = this._surveyStepperService.sectionIndexChanged$.subscribe(
      (event: NavigationEvent) => this._store.dispatch(new Navigation(event))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
