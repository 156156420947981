import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { NgxLoadingModule } from 'ngx-loading';

import { MatTabsModule } from '@angular/material/tabs';

import { ParticipantLandingModule } from '../participant-landing/participant-landing.module';
import { RaterLandingModule } from '../rater-landing/rater-landing.module';

import { TabbedLandingRoutingModule } from './tabbed-landing-routing.module';
import { TabbedLandingComponent } from './tabbed-landing.component';
import { BackgroundLandingModule } from '../background-landing/background-landing.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatTabsModule,
    NgxLoadingModule,
    TabbedLandingRoutingModule,
    ParticipantLandingModule,
    RaterLandingModule,
    BackgroundLandingModule,
  ],
  declarations: [TabbedLandingComponent],
})
export class TabbedLandingModule {}
