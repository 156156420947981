import { InstrumentStatus } from "../../participant-landing/models/participant.model";
import { RaterManagement, Rater, RaterStatus } from "./rater-management.models";

// actions
export class ManageRaters {
  static readonly type = "[Rater Management] Manage Raters";
  constructor(public instrumentStatus: InstrumentStatus) {}
}

export class LoadRaterManagement {
  static readonly type = "[Rater Management] Load Rater Management";
}

export class DeleteRater {
  static readonly type = "[Rater Management] Delete Rater";
  constructor(public rater: Rater) {}
}

export class RemindRaters {
  static readonly type = "[Rater Management] Remind Raters";
  constructor(public raterStatus: RaterStatus) {}
}

export class RemindAllRaters {
  static readonly type = "[Rater Management] Remind All Raters";
  constructor() {}
}

export class ResendInvitation {
  static readonly type = "[Rater Management] Resend Invitation";
  constructor(public raterStatus: RaterStatus, public rater: Rater) {}
}

export class ExpandRaters {
  static readonly type = "[Rater Management] Expand Raters";
  constructor(public raterStatus: RaterStatus, public isExpanded: boolean) {}
}

// events
export class LoadRaterManagementSuccess {
  static readonly type = "[Rater Management] Load Rater Management Success";
  constructor(public raterManagement: RaterManagement) {}
}

export class RemindRatersSuccess {
  static readonly type = "[Rater Management] Remind Raters Success";
}

export class ResendInvitationSuccess {
  static readonly type = "[Rater Management] Resend Invitation Success";
}
