<div>
    <div *ngIf="(user$ | async) as user" class="mat-title" tabindex="0">
        {{user.firstName}} {{user.lastName}}
    </div>
    <div *ngIf="(survey$ | async) as survey" class="mat-subheading-1" tabindex="0">
        {{survey.name}}
    </div>
    <div *ngIf="ratingAs" class="mat-subheading-1" tabindex="0">
        {{ratingAs}}
    </div>
</div>
<div *ngIf="(surveySection$ | async) as surveySection" tabindex="0">
    <div mat-dialog-content *ngIf="surveySection.information" tabindex="0">
        <mat-divider></mat-divider>
        <assess-survey-section-header [surveySection]="surveySection "></assess-survey-section-header>
        <assess-survey-information [surveySection]="surveySection "></assess-survey-information>
    </div>
</div>