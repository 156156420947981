import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

import { FlexLayoutModule } from '@angular/flex-layout';

import * as Registry from '../../modules/survey-stepper/type-registry';

import { MultiEditComponent } from './components/multi-edits/multi-edit.component';
import { MultiEditsComponent } from './components/multi-edits/multi-edits.component';
import { MultiOptionComponent } from './components/multi-options/multi-option.component';
import { MultiOptionsComponent } from './components/multi-options/multi-options.component';
import { SingleOptionComponent } from './components/single-option/single-option.component';

import { SurveyDemographicsQuestionsContainerComponent } from './survey-demographics-questions-container.component';
import { SurveyDemographicsService } from './services/survey-demographics.service';
import { SurveyDemographicsQuestionsGroupComponent } from './components/survey-demographics-questions-group/survey-demographics-questions-group.component';
import { SingleEditComponent } from './components/single-edit/single-edit.component';
import { GroupedSingleOptionComponent } from './components/grouped-single-option/grouped-single-option.component';
import { QuestionsHostDirective } from './directives/questions-host.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    FlexLayoutModule,
  ],
  declarations: [
    MultiEditComponent,
    MultiEditsComponent,
    MultiOptionComponent,
    MultiOptionsComponent,
    SingleOptionComponent,
    SurveyDemographicsQuestionsContainerComponent,
    SurveyDemographicsQuestionsGroupComponent,
    SingleEditComponent,
    GroupedSingleOptionComponent,
    QuestionsHostDirective,
  ],
  providers: [SurveyDemographicsService],
  exports: [SurveyDemographicsQuestionsContainerComponent],
  entryComponents: [
    MultiEditsComponent,
    MultiOptionsComponent,
    SingleOptionComponent,
    SingleEditComponent,
    GroupedSingleOptionComponent,
  ],
})
export class SurveyDemographicsModule {
  constructor() {
    // register the component for the stepper
    Registry.setTypeFor(
      'GroupedSingleOptionComponent',
      GroupedSingleOptionComponent
    );

    // register the component for the stepper
    Registry.setTypeFor('MultiEditsComponent', MultiEditsComponent);

    // register the component for the stepper
    Registry.setTypeFor('MultiOptionsComponent', MultiOptionsComponent);

    // register the component for the stepper
    Registry.setTypeFor('SingleEditComponent', SingleEditComponent);
    // register the component for the stepper
    Registry.setTypeFor('SingleOptionComponent', SingleOptionComponent);
  }
}
