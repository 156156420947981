import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '../../../../node_modules/@ngx-translate/core';

import { FlexLayoutModule } from '../../../../node_modules/@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';

import { NgxLoadingModule } from 'ngx-loading';

import { SharedModule } from '../../shared/shared.module';
import { RaterLandingComponent } from './rater-landing.component';
import { RaterLandingRoutingModule } from './rater-landing-routing.module';
import { SurveyCardComponent } from './components/survey-card/survey-card.component';

@NgModule({
  imports: [
    CommonModule,
    RaterLandingRoutingModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatDividerModule,
    NgxLoadingModule,
    SharedModule,
  ],
  declarations: [RaterLandingComponent, SurveyCardComponent],
  exports: [RaterLandingComponent],
})
export class RaterLandingModule {}
