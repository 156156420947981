import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { MatCheckboxChange } from '@angular/material/checkbox';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  QuestionAnswer,
  QuestionListItem,
} from '../../store/survey.model';

import { SurveyDocumentState } from '../../store/survey.state';

@Component({
  selector: 'assess-survey-competencies-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-competencies-question.component.html',
})
export class SurveyCompetenciesQuestionComponent implements OnInit, OnDestroy {
  @Input()
  question: Question;

  @Input()
  complete: boolean;

  @Output()
  compatencyQuestionChange: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  questionListItem$: Observable<QuestionListItem>;

  isChecked: boolean = false;

  private _questionListItemSubscription: Subscription;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.question.formItemId)));

    this._questionListItemSubscription = this.questionListItem$.subscribe(
      (questionListItem: QuestionListItem) => {
        this.isChecked =
          questionListItem.answer && questionListItem.answer === '1'
            ? true
            : false;
      }
    );
  }

  ngOnDestroy(): void {
    this._questionListItemSubscription.unsubscribe();
  }

  onQuestionCheckboxChange(value: MatCheckboxChange): void {
    let question: QuestionAnswer = Object.assign(
      {},
      {
        ...this.question,
        previousAnswer: this.question.answer,
        answer: value.checked ? '1' : null,
      }
    );
    this.compatencyQuestionChange.emit(question);
  }
}
