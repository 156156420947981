import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { QuestionAnswer } from '../../../store/survey.model';

@Injectable()
export class SurveyDemographicsService {
  questionAnswered$: Subject<QuestionAnswer> = new Subject<QuestionAnswer>();

  answerQuestion(question: QuestionAnswer): void {
    this.questionAnswered$.next(question);
  }
}
