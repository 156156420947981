import { Rule } from 'json-rules-engine';

// navigation sources
export const CHANGE_TAB: string = 'change-tab';
export const MENU: string = 'menu';
export const NEXT_GROUP: string = 'next-group';
export const PREVIOUS_GROUP: string = 'previous-group';
export const REVIEW_QUESTION: string = 'review-question';
export const REVIEW_SECTION: string = 'review-section';
export const START: string = 'start';
export const SUBMIT: string = 'submit';

export enum UserStatus {
  NotFound,
  Found
}

export interface NavigationEvent {
  source: string;
  sectionIndex: number;
}

export interface RaterType {
  raterTypeId: number;
  name: string;
}

export interface Participant {
  participantId: number;
  firstName: string;
  lastName: string;
}

export interface Language {
  languageId: number;
  name: string;
  cultureName: string;
  translatedLanguageName: string;
}

export interface JumpMenu {
  sectionId: string;
  jumpMenu: string;
}

export interface Survey {
  instrumentId: number;
  surveyTypeId: number;
  languageId: number;
  formMasterId: number;
  name: string;
  nameTreatment: string;
  lastAnsweredFormItemId: number;
  extensions: Extension[];
  surveySections: SurveySection[];
}

export interface SurveySection {
  sectionId: string;
  component: string;
  heading: string;
  jumpMenu?: string;
  instructions?: string[];
  additionalInstructions: AdditionalInstructions;
  nextButtonText?: string;
  previousButtonText?: string;
  nextGroupButtonText?: string;
  previousGroupButtonText?: string;
  exitButtonText?: string;
  optional: boolean;
  reviewable: boolean;
  extensions: Extension[];
  questionGroups: QuestionGroup[];
  validationRule?: Rule;
  subheadings?: string[];
  information?: string[];
  surveyScale?: SurveyScale;
  submitButtonText?: string;
  warnings?: string[];
}

export interface AdditionalInstructions {
  title: string;
  instructions: string;
}

export interface Extension {
  name: string;
  value: string;
}

export interface AnswerGroupItem {
  text: string;
  value: string;
}

export interface QuestionGroupHeading {
  formItemSequenceNumber: number;
  text: string;
}
export interface QuestionGroup {
  questions: Question[];
  instructions: string[];
  heading: QuestionGroupHeading;
  tabText?: string;
  surveyScale: SurveyScale;
  answerGroup: AnswerGroupItem[];
}

export interface ResponseValidator {
  name: string;
  message: string;
  parameters: any[];
}

export interface Question {
  component: string;
  formItemSequenceNumber: number;
  formItemId: number;
  parentFormItemId: number;
  number: string;
  text?: string;
  subtext?: string;
  options?: Option[];
  optionGroups?: OptionGroup[];
  optional?: boolean;
  maxLength?: number;
  multiline?: boolean;
  hasOptions?: boolean;
  answer?: any;
  placeholder: string;
  extensions: Extension[];
  validators: ResponseValidator[];
  dependentOption: DependentOption;
}

// used internally when answering a question
export interface QuestionAnswer {
  formItemId: number;
  answer?: any;
  previousAnswer?: any;
  formItemSequenceNumber: number;
  optional?: boolean;
  answeredTimeMs?: number;
}

// sent from the backend from call to get the survey answers
export interface SurveyAnswer {
  surveyDocumentId: string;
  formItemId: number;
  answer: string;
  formItemSequenceNumber: number;
  optional: boolean;
  answeredTimeMs: number;
}

export interface DependentOption {
  formItemId?: number;
  formItemSequenceNumber?: number;
  value: string;
  answer?: any;
  maxLength?: number;
  multiline?: boolean;
  placeholder: string;
  optional?: boolean;
}

export interface Option {
  text: string;
  value?: any;
  formItemId?: number;
  formItemSequenceNumber?: number;
  dependentFormItemSequenceNumber?: number;
  number: string;
  answer?: any;
  maxLength?: number;
  multiline?: boolean;
  placeholder: string;
  dependentOption: DependentOption;
}

export interface OptionGroup {
  name: string;
  options: Option[];
}

export interface SurveyScale {
  buttons: ScaleButton[];
  notApplicableButton?: ScaleButton;
}

export interface ScaleButton {
  buttonText: string;
  buttonValue: string;
  meaning?: string;
  reviewable?: boolean;
}

export interface SurveyDocument {
  id: string;
  participantId: number;
  individualId: number;
  surveyStatusId: number;
  languages: Language[];
  survey: Survey;
}

export interface ComponentDescriptor {
  component: string;
}

export interface SectionReviewCard {
  sectionId: string;
  heading: string;
  numberAnswered: number;
  notObservedAnswered: number;
  validationRule: Rule;
  optional?: boolean;
  unansweredQuestions: Question[];
  notObservedQuestions: Question[];
}

export interface CompetencyReview {
  name: string;
  groups: CompetencyReviewGroup[];
}

export interface CompetencyReviewGroup {
  name: string;
  questions: Question[];
}

export interface StartSurveyResponse {
  surveyDocument: SurveyDocument;
  surveyAnswers: SurveyAnswer[];
  participant: Participant;
  raterType: RaterType;
  userStatus: UserStatus;
}

export interface QuestionListItem {
  sectionId: string;
  formItemId: number;
  formItemSequenceNumber: number;
  answer?: string;
  question?: Question;
}
