import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'assess-no-work-landing',
  templateUrl: './no-work-landing.component.html'
})
export class NoWorkLandingComponent implements OnInit {
  title$: Observable<any>;
  text$: Observable<any>;
  contactUs$: Observable<any>;

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    document.body.style.background = '#80e2da';
    setTimeout(() => {
      this.title$ = this._translateService.get('noWorkLanding.header.title');
      this.text$ = this._translateService.get('noWorkLanding.text');
      this.contactUs$ = this._translateService.get('globalException.contactUs');
    }, 250);
  }
}
