import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LOG_ENTRIES_CONFIG_FACTORY } from './log-entries-log.model';

import { LogEntriesLogPublisherFactory } from './log-entries-log-publisher.factory';
import { LOG_PUBLISHER_FACTORIES } from '../../log.model';

@NgModule()
export class LogEntriesLogPluginModule {
  public static forRoot(): ModuleWithProviders<LogEntriesLogPluginModule> {
    return {
      ngModule: LogEntriesLogPluginModule,
      providers: [
        {
          provide: LOG_PUBLISHER_FACTORIES,
          useClass: LogEntriesLogPublisherFactory,
          deps: [LOG_ENTRIES_CONFIG_FACTORY, HttpClient],
          multi: true,
        },
      ],
    };
  }
}
