<div fxLayout fxLayoutAlign="center" style="height: 100%">
  <div fxLayout="column" fxFlexAlign="top" fxFlex.lt-md="100" fxFlex="0 0 900px"
    style="background-color: white; overflow-y:auto; height: 100%;">
    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="5px">
      <button mat-icon-button color="primary" (click)="onBack()"><i class="fa fa-arrow-circle-left fa-2x"></i></button>
      <span class="mat-title compressed">{{instrumentStatus.assessmentItemName}}</span>
    </div>
    <div fxFlex>
      <ngx-extended-pdf-viewer [src]="report" useBrowserLocale="true"
        [filenameForDownload]="instrumentStatus.assetInfo ? instrumentStatus.assetInfo.name : instrumentStatus.documentInfo.name"
        [showOpenFileButton]="false" [showPropertiesButton]="false">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</div>