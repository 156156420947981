import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  Injector,
  Type,
  ComponentFactory,
  ComponentRef,
  Output,
  EventEmitter,
} from '@angular/core';

import * as Registry from '../../type-registry';

import {
  BackgroundItemCategory,
  BackgroundItem,
  BackgroundResponse,
} from '../../store/background-landing.models';

import { BackgroundComponent } from '../../background-component';
import { BackgroundItemHostDirective } from '../../directives/background-item-host.directive';

@Component({
  selector: 'assess-background-item-category',
  templateUrl: './background-item-category.component.html',
})
export class BackgroundItemCategoryComponent implements OnInit {
  @ViewChild(BackgroundItemHostDirective, { static: true })
  backgroundItemHost: BackgroundItemHostDirective;

  @Input()
  backgroundItemCategory: BackgroundItemCategory;
  @Input()
  responses: BackgroundResponse[];

  @Output()
  validationStatus: EventEmitter<string> = new EventEmitter<string>();

  private _backgroundComponents: ComponentRef<any>[] = [];

  constructor(private _resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.backgroundItemCategory.backgroundItems.forEach(
      (backgroundItem: BackgroundItem) => {
        this.createComponent(backgroundItem, this.responses);
      }
    );
  }

  getResponses(): BackgroundResponse[] {
    let responses: BackgroundResponse[] = [];

    // get all the responses and package them up to be saved
    this._backgroundComponents.forEach((component: ComponentRef<any>) => {
      responses = responses.concat(
        (component.instance as BackgroundComponent).getResponse()
      );
    });

    return responses;
  }

  private createComponent(
    backgroundItem: BackgroundItem,
    backgroundResponse: BackgroundResponse[]
  ): void {
    let componentType = backgroundItem.backgroundItemType.name;

    let type = Registry.getTypeFor(componentType);
    if (!type) {
      throw `
      ${componentType} not registered.
      Usage:
      The following line must be placed in the module's constructor:
      Registry.setTypeFor('${componentType}', ${componentType});`;
    }

    this.renderComponent(
      type,
      this.createInjector(),
      backgroundItem,
      backgroundResponse
    );
  }

  private createInjector(): Injector {
    let injector: Injector = Injector.create(
      [],
      this.backgroundItemHost.viewContainerRef.parentInjector
    );

    return injector;
  }

  private renderComponent(
    type: Type<any>,
    injector: Injector,
    backgroundItem: BackgroundItem,
    backgroundResponses: BackgroundResponse[]
  ): void {
    let factory: ComponentFactory<any> = this._resolver.resolveComponentFactory(
      type
    );

    let component: ComponentRef<any> = factory.create(injector);
    component.instance.backgroundItem = backgroundItem;
    component.instance.backgroundResponses = backgroundResponses;
    if (backgroundItem.backgroundItemType.name === 'Text') {
      component.instance.validationStatus.subscribe((status: string) => {
        this.validationStatus.emit(status);
      });
    }

    this.backgroundItemHost.viewContainerRef.insert(component.hostView);
    this._backgroundComponents.push(component);
  }
}
