<div *ngIf="(backgroundLanding$ | async) as backgroundLanding" style="height: 100%">
  <div fxLayout fxLayoutAlign="center" style="height: 100%">
    <div fxFlex.lt-md="100" fxFlex="0 0 900px" style="background-color: white; height: 100%;">
      <div fxLayout="column" style="height: 100%;">
        <div style="height: 100%; overflow-y: auto; padding: 10px;">
          <div class="mat-body" [innerHtml]="'backgroundLanding.welcomeprogram' | translate">
          </div>
          <div>
            <assess-background-item-category
              *ngFor="let backgroundItemCategory of backgroundLanding.backgroundItemCategories"
              (validationStatus)="onValidationStatusChanged($event)" [backgroundItemCategory]="backgroundItemCategory"
              [responses]="backgroundLanding.backgroundResponseDocument.responses"></assess-background-item-category>
          </div>
        </div>
        <div fxLayout fxLayoutAlign="space-between center" class="background-landing button-panel">
          <button mat-raised-button color="primary" (click)="skip()" [disabled]="loading | async">
            <span>{{'backgroundLanding.button.skip' | translate}}</span>
          </button>
          <button mat-raised-button color="primary" (click)="save()" [disabled]="invalid || (loading | async)">
            <span>{{'backgroundLanding.button.save' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>