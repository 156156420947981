<div fxLayout fxLayoutAlign="center" class="mt-2">
    <div fxFlex.lt-md="90" fxFlex="0 0 900px" style="background-color: white">
        <mat-card>
            <mat-card-title>
                {{title}}
            </mat-card-title>
            <mat-card-content>
                <p>
                    {{suggestion}}
                </p>
                <div [innerHtml]="'globalException.contactUs' | translate">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>