import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  ScrollToConfigOptions,
  ScrollToService,
} from '@nicky-lenaers/ngx-scroll-to';

import { Idle } from '@ng-idle/core';

import { SurveySection, QuestionAnswer } from '../../store/survey.model';

import { AnswerQuestions } from '../../store/survey.actions';
import { SurveyDocumentState } from '../../store/survey.state';
import { DirectFeedbackAnswerService } from '../../services/direct-feedback-answer.service';

@Component({
  selector: 'assess-survey-direct-feedback-questions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-direct-feedback-questions.component.html',
})
export class SurveyDirectFeedbackQuestionsComponent
  implements OnInit, OnDestroy {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  constructor(
    private _store: Store,
    private _directFeedbackAnswerService: DirectFeedbackAnswerService,
    private _idle: Idle,
    private _scrollToService: ScrollToService
  ) {}

  ngOnInit(): void {
    const scrollTarget: number = this._store.selectSnapshot(
      SurveyDocumentState.getScrollTarget
    );

    setTimeout(() => {
      const config: ScrollToConfigOptions = {
        target: scrollTarget,
      };
      this._scrollToService.scrollTo(config);
    }, 250);

    this._idle.onTimeoutWarning.subscribe((count: number) => {
      if (count === this._idle.getTimeout()) {
        this._store.dispatch(
          new AnswerQuestions(this._directFeedbackAnswerService.answers)
        );
      }
    });
  }

  ngOnDestroy(): void {
    this._store.dispatch(
      new AnswerQuestions(this._directFeedbackAnswerService.answers)
    );
  }

  onDirectFeedbackChanged(answer: QuestionAnswer): void {
    const questionAnswer: QuestionAnswer = {
      formItemId: answer.formItemId,
      previousAnswer: answer.previousAnswer,
      answer: answer.answer,
      optional: answer.optional,
      formItemSequenceNumber: answer.formItemSequenceNumber,
      answeredTimeMs: new Date().getTime(),
    };
    this._directFeedbackAnswerService.upsertAnswer(questionAnswer);
  }
}
