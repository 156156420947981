import { NgModule, ModuleWithProviders, Inject } from '@angular/core';

import { LogService } from './log.service';
import { LogPublishersService } from './log-publishers.service';
import {
  LOG_PUBLISHER_FACTORIES,
  LogConfig,
  LogConfigService,
} from './log.model';
import { ConsoleLogPublisherFactory } from './console-log-publisher.factory';

@NgModule()
export class LogModule {
  public static forRoot(_config?: LogConfig): ModuleWithProviders<LogModule> {
    return {
      ngModule: LogModule,
      providers: [
        LogPublishersService,
        LogService,
        {
          provide: LogConfigService,
          useValue: _config,
        },
        {
          provide: LOG_PUBLISHER_FACTORIES,
          useClass: ConsoleLogPublisherFactory,
          multi: true,
        },
      ],
    };
  }
}
