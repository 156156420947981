<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column" style="height: 100%;">
      <assess-survey-section-title></assess-survey-section-title>
      <div fxFlex style="background-color: white; overflow-y: auto; padding: 20px;">
        <assess-survey-instruction [surveySection]="surveySection"></assess-survey-instruction>
        <ng-content></ng-content>
      </div>
      <div class="survey button-panel" fxLayout="row" fxLayoutAlign="space-between center">
        <assess-survey-previous-step-button [surveySection]="surveySection"></assess-survey-previous-step-button>
        <assess-survey-next-step-button [surveySection]="surveySection"></assess-survey-next-step-button>
      </div>
    </div>
  </div>