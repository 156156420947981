import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HypermediaService } from '../../../core/hypermedia/hypermedia.service';
import { HypermediaFactoryService } from '../../../core/hypermedia/hypermedia-factory.service';

import { ParticipantLanding, Program } from '../models/participant.model';
import { Config } from '../../../store/application.models';

interface ParticipantLandingResponse {
  programInstrumentStatuses: Program[];
}

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  private _hypermediaService: HypermediaService = null;

  public constructor(
    private _store: Store,
    private _hypermediaFactoryService: HypermediaFactoryService
  ) {}

  initializeService(): void {
    if (this._hypermediaService === null) {
      const config: Config = this._store.selectSnapshot(
        state => state.application.config
      );

      this._hypermediaService = this._hypermediaFactoryService.create(
        config.assessmentWebApi,
        'participant-landing'
      );
    }
  }

  getParticipantLanding(
    individualId: number,
    preferredLanguageId: number
  ): Observable<ParticipantLanding> {
    this.initializeService();
    return this._hypermediaService
      .get('get', {
        individualId: individualId,
        preferredLanguageId: preferredLanguageId
      })
      .pipe(map((r: ParticipantLandingResponse) => r));
  }
}
