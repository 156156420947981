import { Injectable } from '@angular/core';

import * as Auth0 from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _auth0: Auth0.WebAuth;

  constructor(private _jwtHelperService: JwtHelperService) {}

  public authenticate(
    authClientId: string,
    authDomain: string,
    authRedirectUri: string,
    grandCentralLoginApplicationId: string,
    grandCentralUrl: string,
    grandCentralReturnUrl: string
  ): Promise<any> {
    // setup Auth0
    this._auth0 = new Auth0.WebAuth({
      domain: authDomain,
      clientID: authClientId,
      scope: `openid profile loginApplicationId-${grandCentralLoginApplicationId}`,
      responseType: 'token id_token',
      redirectUri: authRedirectUri,
    });

    // give it a go
    return new Promise((resolve: any, reject: any) => {
      // Timeout is here to allow angular to finish up whatever it is doing
      // if this timeout isn't here then angular will cancel the iframe
      // call that is being injected into the DOM by the renewAuth call.
      // At least that is what I think is happening.  (CR)
      setTimeout(() => {
        this._auth0.checkSession({}, (err, result) => {
          if (err) {
            // FOR DEBUG PURPOSES: Uncomment to get a chance to look at localhost:4200
            // before it reroutes to Grand Central
            // TODO Consider a nicer way to display the error to the user.
            // if(err.error !== "login_required"){
            //     console.log("Then there was trouble...");
            //     console.log(err);
            //     console.log(result);
            //     alert('There was an error during your login: '  + err.error);
            // }

            window.location.href = `${grandCentralUrl}/client-login/?loginApplicationId=${grandCentralLoginApplicationId}&returnURL=${grandCentralReturnUrl}`;

            reject(null);
          } else {
            // Remove the previous token, if any.
            localStorage.removeItem('token');

            // Set the new token.
            localStorage.setItem('token', result.idToken);

            const token = this._jwtHelperService.decodeToken(result.idToken);
            const user: User = {
              individualId:
                +token[
                  'http://schemas.ccl.org/accounts/claims/ccl-identity/id'
                ],
              email:
                token[
                  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
                ],
              firstName:
                token[
                  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
                ],
              lastName:
                token[
                  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
                ],
              landingPage:
                token[
                  'http://schemas.ccl.org/assessments/claims/user/landingPage'
                ],
              preferredLanguageCultureName:
                token[
                  'http://schemas.ccl.org/assessments/claims/user/preferredLanguageCultureName'
                ],
              preferredLanguageId:
                +token[
                  'http://schemas.ccl.org/assessments/claims/user/preferredLanguageId'
                ],
              isParticipant:
                token[
                  'http://schemas.ccl.org/assessments/claims/user/isParticipant'
                ] === 'True'
                  ? true
                  : false,
              isRater:
                token[
                  'http://schemas.ccl.org/assessments/claims/user/isRater'
                ] === 'True'
                  ? true
                  : false,
              aboutMe:
                token[
                  'http://schemas.ccl.org/assessments/claims/user/aboutMe'
                ] === 'True'
                  ? true
                  : false,
            };

            // FOR DEBUG PURPOSES: Uncomment to get a chance to look at localhost:4200
            // before it reroutes to Grand Central
            //console.log('Retrieved a new token using silent authentication.');
            //alert('pause');

            resolve(user);
          }
        });
      }, 1000);
    });
  }
}
