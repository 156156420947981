<div fxLayout fxLayoutAlign="center" style="height: 100%;">
  <div fxFlex.lt-md="100" fxFlex="0 0 900px" style="background-color: white; overflow-y:auto; height: 100%;">
    <ngx-loading [show]="showLoading"></ngx-loading>
    <div style="padding: 20px;">
      <div fxLayout fxLayoutAlign="start center" fxLayoutGap="5px">
        <button mat-icon-button color="primary" (click)="onBack()"><i
            class="fa fa-arrow-circle-left fa-2x" aria-label="Back to assessment rater page"></i></button>
        <div class="mat-title compressed">
          <span tabindex="0" [translate]="'raterManagement.addRater.addRaterName'"
            [translateParams]="{raterName: raterStatus.raterType.name}"></span>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div class="text-muted mt-1" fxLayout fxLayout.lt-md="column">
          <span *ngIf="raterStatus.raterRecommendedCount === 1" [translate]="'raterManagement.card.recommended'"
            [translateParams]="{count: raterStatus.raterRecommendedCount}" tabindex="0"></span>
          <span *ngIf="raterStatus.raterRecommendedCount > 1" [translate]="'raterManagement.card.recommendedPlural'"
            [translateParams]="{count: raterStatus.raterRecommendedCount}" tabindex="0"></span>
          <span *ngIf="raterStatus.raterRecommendedCount > 0 && raterStatus.raterRequiredCount > 0"
            fxHide.lt-md="true">,&nbsp;</span>
          <span *ngIf="raterStatus.raterRequiredCount === 1" [translate]="'raterManagement.card.required'"
            [translateParams]="{count: raterStatus.raterRequiredCount}" tabindex="0"></span>
          <span *ngIf="raterStatus.raterRequiredCount > 1" [translate]="'raterManagement.card.requiredPlural'"
            [translateParams]="{count: raterStatus.raterRequiredCount}" tabindex="0"></span>
        </div>
        <mat-divider></mat-divider>
        <form novalidate [formGroup]="ratersFormGroup">
          <div fxLayout="column">
            <assess-rater-form *ngFor="let rater of controls; let i = index" [index]="i" [raterFormGroup]="rater"
              (removeRaterClick)="onRemoveRaterClick($event)"></assess-rater-form>
            <div *ngIf="error !== null" class="mt-1 text-error">
              {{error}}
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" class="mt-1">
              <button fxHide fxShow.lt-md="true" mat-raised-button color="primary" apNoDblClickMat enableAfter="500"
                type="button" (click)="onClearRaterClick()">{{'raterManagement.addRater.clear'
                | translate}}</button>
              <button fxShow fxHide.lt-md="true" mat-mini-fab color="primary" apNoDblClickMat enableAfter="500"
                type="button" (click)="onAddRaterClick()"><i class="fa fa-plus" aria-label="Add additional raters"></i></button>
              <button mat-raised-button color="primary" (click)="submit()" apNoDblClickMat
                [disabled]="ratersFormGroup?.invalid">{{'raterManagement.addRater.invite'
                | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>