import {
  RaterStatus,
  Language,
  Individual
} from '../../../store/rater-management.models';

// commands
export class AddRaters {
  static readonly type = '[Rater Management] Add Raters';
  constructor(public raterStatus: RaterStatus) {}
}

export class LoadMruRaters {
  static readonly type = '[Rater Management] Load MRU Raters';
}

export class LoadLanguages {
  static readonly type = '[Rater Management] Load Languages';
}

export class InviteRaters {
  static readonly type = '[Rater Management] Invite Raters';
  constructor(public individuals: Individual[]) {}
}

export class SearchIndividual {
  static readonly type = '[Rater Management] Search Individual';
  constructor(public email: string, public source: number) {}
}

// events
export class LoadMruRatersSuccess {
  static readonly type = '[Rater Management] Load MRU Raters Success';
  constructor(public individuals: Individual[]) {}
}

export class LoadLanguagesSuccess {
  static readonly type = '[Rater Management] Load Languages Success';
  constructor(public languages: Language[]) {}
}

export class InviteRatersSuccess {
  static readonly type = '[Rater Management] Invite Raters Success';
}

export class InviteRatersFailed {
  static readonly type = '[Rater Management] Invite Raters Failed';
  constructor(public status: number, public email: string) {}
}

export class SearchIndividualSuccess {
  static readonly type = '[Rater Management] Search Individual Success';
  constructor(public individual: Individual, public source: number) {}
}
