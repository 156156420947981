import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';

import { Store } from '@ngxs/store';

import { User } from '../../core/auth/user.model';
import { ApplicationState } from '../../store/application.state';
import { DOCUMENT } from '@angular/common';

import { MyProfileService } from './services/my-profile.service';
import {
  MyProfileResponse,
  Profile,
  Language,
} from './models/my-profile.model';
import {
  NavigateHome,
  MyProfileSaved,
} from 'src/app/store/application.actions';

@Component({
  selector: 'assess-my-profile',
  templateUrl: './my-profile.component.html',
})
export class MyProfileComponent implements OnInit, OnDestroy {
  myProfileGroup: FormGroup;

  showLoading: boolean = false;

  languages: Language[];

  saving: boolean = false;

  private _user: User;

  private _myProfileSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,

    private _formBuilder: FormBuilder,
    private _store: Store,
    private _myProfileService: MyProfileService
  ) {}

  ngOnInit() {
    
    this.myProfileGroup = this._formBuilder.group({
      prefix: [''],
      firstName: [''],
      middleInitials: [''],
      lastName: [''],
      preferredName: [''],
      businessPhone: [''],
      mobilePhone: [''],
      jobTitle: [''],
      preferredApplicationLanguage: [null],
    });

    this._user = this._store.selectSnapshot(ApplicationState.getUser);
    this._myProfileSubscription = this._myProfileService
      .getMyProfile(this._user.individualId, this._user.preferredLanguageId)
      .subscribe((myProfileResponse: MyProfileResponse) => {
        this.languages = [...myProfileResponse.applicationLanguages];

        this.myProfileGroup = this._formBuilder.group({
          prefix: [myProfileResponse.profile.individual.prefix],
          firstName: [myProfileResponse.profile.individual.firstName],
          middleInitials: [myProfileResponse.profile.individual.middleInitials],
          lastName: [myProfileResponse.profile.individual.lastName],
          preferredName: [myProfileResponse.profile.individual.preferredName],
          businessPhone: [
            myProfileResponse.profile.communication.businessPhone,
          ],
          mobilePhone: [myProfileResponse.profile.communication.mobilePhone],
          jobTitle: [myProfileResponse.profile.individual.jobTitle],
          preferredApplicationLanguage: [
            this.languages.find(
              (language) =>
                language.languageId ===
                myProfileResponse.profile.individual
                  .preferredApplicationLanguageId
            ),
          ],
        });
      });
  }

  ngOnDestroy(): void {
    this._myProfileSubscription.unsubscribe();
  }

  get formControls() {
    return this.myProfileGroup.controls;
  }

  onBack(): void {
    this._store.dispatch(new NavigateHome());
  }

  save(): void {
    // get the values from the form
    
    const profileValues = this.myProfileGroup.getRawValue();
    this.document.documentElement.lang = profileValues.preferredApplicationLanguage.cultureName;
    const newProfile: Profile = {
      individual: {
        individualId: this._user.individualId,
        prefix: profileValues.prefix,
        firstName: profileValues.firstName,
        middleInitials: profileValues.middleInitials,
        lastName: profileValues.lastName,
        preferredName: profileValues.preferredName,
        jobTitle: profileValues.jobTitle,
        preferredApplicationLanguageId:
          profileValues.preferredApplicationLanguage.languageId,
        preferredApplicationLanguageCultureName:
          profileValues.preferredApplicationLanguage.cultureName,
      },
      communication: {
        businessPhone: profileValues.businessPhone,
        mobilePhone: profileValues.mobilePhone,
        email: this._user.email,
      },
    };

    this.saving = true;

    this._myProfileService.save(newProfile).subscribe(() => {
      this.saving = false;
      this._store.dispatch(new MyProfileSaved(newProfile));
    });
  }
}
