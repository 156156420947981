import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { QuestionGroup, QuestionAnswer } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-scales-questions-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-scales-questions-group.component.html'
})
export class SurveyScalesQuestionsGroupComponent {
  @Input() questionGroup: QuestionGroup;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  onQuestionAnswered(question: QuestionAnswer): void {
    this.questionAnswered.emit(question);
  }
}
