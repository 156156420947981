import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
import { HypermediaFactoryService } from './hypermedia/hypermedia-factory.service';
import { ApiVersionRequestInterceptor } from './interceptors/api-version-request.interceptor';
import { AuthenticationService } from './auth/authentication.service';

@NgModule({
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionRequestInterceptor,
      multi: true
    },
    AuthService,
    HypermediaFactoryService
  ]
})
export class CoreModule {}
