import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { ApplicationState } from '../store/application.state';
import { Exception } from '../store/application.models';

@Component({
  selector: 'assess-global-exception',
  templateUrl: './global-exception.component.html',
})
export class GlobalExceptionComponent implements OnInit {
  suggestion: string;
  title: string;

  constructor(
    private _store: Store,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const exception: Exception = this._store.selectSnapshot(
      ApplicationState.getException
    );

    switch (exception.status) {
      case 595:
        this.title = this._translateService.instant(
          'globalException.versionMismatch.title'
        );
        this.suggestion = this._translateService.instant(
          'globalException.versionMismatch.suggestion'
        );
        break;
      default:
        this.title = this._translateService.instant(
          'globalException.unhandled.title'
        );
        this.suggestion = this._translateService.instant(
          'globalException.unhandled.suggestion'
        );
        break;
    }
  }
}
