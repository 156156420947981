import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ViewChild,
  Injector,
  Type,
  ComponentFactory,
  ComponentRef,
} from '@angular/core';

import * as Registry from '../../../survey-stepper/type-registry';

import { QuestionGroup, Question } from '../../../../store/survey.model';
import { QuestionsHostDirective } from '../../directives/questions-host.directive';

@Component({
  selector: 'assess-survey-demographics-questions-group',
  template: '<ng-template assessQuestionsHost></ng-template>',
  styles: [':host { height: 100%; width: 100% }'],
})
export class SurveyDemographicsQuestionsGroupComponent implements OnInit {
  @ViewChild(QuestionsHostDirective, { static: true })
  questionsHost: QuestionsHostDirective;

  @Input() questionGroup: QuestionGroup;

  constructor(private _resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.createQuestionGroups();
  }

  createQuestionGroups(): void {
    this.questionGroup.questions.forEach(
      (question: Question, index: number) => {
        let type = Registry.getTypeFor(question.component);
        if (!type) {
          throw `
          ${question.component} not registered.
          Usage:
          The following line must be placed in the module's constructor:
          Registry.setTypeFor('${question.component}', ${question.component});
        `;
        }

        this.renderComponent(type, this.createInjector(), question, index);
      }
    );
  }

  private createInjector(): Injector {
    let injector: Injector = Injector.create(
      [],
      this.questionsHost.viewContainerRef.parentInjector
    );

    return injector;
  }

  private renderComponent(
    type: Type<any>,
    injector: Injector,
    question: Question,
    index: number
  ): void {
    let factory: ComponentFactory<any> = this._resolver.resolveComponentFactory(
      type
    );

    let component: ComponentRef<any> = factory.create(injector);
    component.instance.question = question;
    component.instance.index = index;

    this.questionsHost.viewContainerRef.insert(component.hostView);
  }
}
