import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl, Validators, ValidatorFn } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BackgroundComponent } from '../../background-component';
import {
  BackgroundItem,
  BackgroundResponse,
  ResponseValidator,
} from '../../store/background-landing.models';

@Component({
  selector: 'assess-text',
  templateUrl: './text.component.html',
})
export class TextComponent implements OnInit, OnDestroy, BackgroundComponent {
  @Input()
  backgroundItem: BackgroundItem;
  @Input()
  backgroundResponses: BackgroundResponse[];

  @Output()
  validationStatus: EventEmitter<string> = new EventEmitter<string>();

  response: FormControl;

  validationStatusSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    const response: BackgroundResponse = this.backgroundResponses.find(
      (response: BackgroundResponse) =>
        response.backgroundItemId === this.backgroundItem.backgroundItemId
    );
    this.response = new FormControl(response ? response.response : null, {
      validators: this.getValidators(),
      updateOn: 'blur',
    });
    this.validationStatusSubscription = this.response.statusChanges.subscribe(
      (status: any) => {
        this.validationStatus.emit(status);
      }
    );
  }

  ngOnDestroy() {
    if (this.validationStatusSubscription) {
      this.validationStatusSubscription.unsubscribe();
    }
  }

  getResponse(): any {
    return <BackgroundResponse>{
      backgroundItemId: this.backgroundItem.backgroundItemId,
      response: this.response.value,
    };
  }

  getErrorMessage(): string {
    let validator: ResponseValidator = null;

    if (this.response.hasError('min')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'min'
      );
    } else if (this.response.hasError('max')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'max'
      );
    } else if (this.response.hasError('required')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'required'
      );
    } else if (this.response.hasError('email')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'email'
      );
    } else if (this.response.hasError('minLength')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'minLength'
      );
    } else if (this.response.hasError('maxLength')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'maxLength'
      );
    } else if (this.response.hasError('pattern')) {
      validator = this.backgroundItem.validators.find(
        (validator: ResponseValidator) => validator.name === 'pattern'
      );
    }

    return validator !== null ? validator.message : null;
  }

  getValidators(): ValidatorFn[] {
    let validators: ValidatorFn[] = [];

    if (this.backgroundItem.validators !== null) {
      this.backgroundItem.validators.forEach((validator: ResponseValidator) => {
        switch (validator.name) {
          case 'min': {
            validators.push(Validators.min(parseInt(validator.parameter)));
            break;
          }
          case 'max': {
            validators.push(Validators.max(parseInt(validator.parameter)));
            break;
          }
          case 'required': {
            validators.push(Validators.required);
            break;
          }
          case 'email': {
            validators.push(Validators.email);
            break;
          }
          case 'minLength': {
            validators.push(
              Validators.minLength(parseInt(validator.parameter))
            );
            break;
          }
          case 'maxLength': {
            validators.push(
              Validators.maxLength(parseInt(validator.parameter))
            );
            break;
          }
          case 'pattern': {
            validators.push(Validators.pattern(validator.parameter));
            break;
          }
        }
      });
    }

    return validators;
  }
}
