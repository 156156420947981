import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { LogPublisherFactory } from './log-publisher-factory';
import { LogPublisher } from './log-publisher';
import { ConsoleLogPublisher } from './console-log-publisher';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLogPublisherFactory extends LogPublisherFactory {
  constructor() {
    super();
  }

  create(): Observable<LogPublisher> {
    return of(new ConsoleLogPublisher());
  }
}
