<div style="padding: 5px 10px;" fxLayout="column">
  <div>{{backgroundItem.questionNumber}}. {{backgroundItem.backgroundItemText.text}}&nbsp;&nbsp;<span
      *ngIf="backgroundItem.important"><i class="fa fa-exclamation-triangle text-warning"></i></span></div>
  <div>{{backgroundItem.backgroundItemText.subtext}}</div>
  <div>
    <form novalidate [formGroup]="otherFormGroup">
      <mat-form-field class="full-width">
        <mat-select [(value)]="selectedValue" (selectionChange)="onItemChange($event)">
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of backgroundItem.backgroundItemText.backgroundItemOptionGroup.backgroundItemOptions"
            [value]="item.value">
            {{ item.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="backgroundItem.dependentBackgroundItems.length > 0">
        <mat-form-field *ngIf="showOther" class="full-width">
          <input matInput [attr.id]="'other' + backgroundItem.backgroundItemOptionId" formControlName="other"
            autocomplete="nope" [placeholder]="backgroundItem.dependentBackgroundItems[0].backgroundItemText.text">
        </mat-form-field>
      </div>
    </form>
  </div>
</div>