import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';

import { FlexLayoutModule } from '@angular/flex-layout';

import { NgxLoadingModule } from 'ngx-loading';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxsModule } from '@ngxs/store';

import { SharedModule } from 'src/app/shared/shared.module';
import { ReportViewerRoutingModule } from './report-viewer-routing.module';
import { ReportViewerComponent } from './report-viewer.component';
import { ReportViewerState } from './store/report-viewer.state';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgxLoadingModule,
    NgxExtendedPdfViewerModule,
    NgxsModule.forFeature([ReportViewerState]),
    MatButtonModule,
    SharedModule,
    ReportViewerRoutingModule,
  ],
  declarations: [ReportViewerComponent],
})
export class ReportViewerModule {}
