import { Component, ChangeDetectionStrategy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { SurveySection } from '../../store/survey.model';
import { SurveySectionInfoDialogComponent } from '../survey-section-info-dialog/survey-section-info-dialog.component';
import { SurveyDocumentState } from '../../store/survey.state';

@Component({
  selector: 'assess-survey-section-title',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-section-title.component.html',
})
export class SurveySectionTitleComponent {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  constructor(private _dialog: MatDialog) {}

  onInfoButtonClick(): void {
    this._dialog.open(SurveySectionInfoDialogComponent, {
      width: '450px',
    });
  }
}
