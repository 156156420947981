import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';

import {
  BackgroundItemOption,
  BackgroundResponse,
  BackgroundItem,
} from '../../store/background-landing.models';

@Component({
  selector: 'assess-multi-select-option',
  templateUrl: './multi-select-option.component.html',
})
export class MultiSelectOptionComponent implements OnInit {
  @Input()
  backgroundItem: BackgroundItem;
  @Input()
  option: BackgroundItemOption;
  @Input()
  backgroundResponses: BackgroundResponse[];

  otherFormGroup: FormGroup;
  showOther: boolean = false;

  constructor() {}

  ngOnInit() {
    const backgroundResponse: BackgroundResponse = this.backgroundResponses.find(
      (response: BackgroundResponse) =>
        response.backgroundItemId === this.backgroundItem.backgroundItemId &&
        response.response === this.option.value
    );

    let otherResponse: BackgroundResponse;
    if (this.backgroundItem.dependentBackgroundItems.length > 0) {
      otherResponse = this.backgroundResponses.find(
        (response: BackgroundResponse) =>
          response.backgroundItemId ===
          this.backgroundItem.dependentBackgroundItems[0].backgroundItemId
      );
    }

    // create an empty form in case there is no response
    this.otherFormGroup = new FormGroup({});
    this.otherFormGroup.addControl('option', new FormControl(null));
    this.otherFormGroup.addControl('other', new FormControl(null));

    if (backgroundResponse) {
      this.otherFormGroup = new FormGroup({});
      this.otherFormGroup.addControl(
        'option',
        new FormControl(
          backgroundResponse.response === this.option.value ? true : null
        )
      );

      const otherSelected: boolean = backgroundResponse.response === '99999';

      this.otherFormGroup.addControl(
        'other',
        new FormControl(
          otherSelected && otherResponse ? otherResponse.response : null
        )
      );

      this.showOther = this.option.value === '99999' && otherSelected;
    }
  }

  onOptionChanged(event: MatCheckboxChange): void {
    this.showOther = event.checked;
    if (!event.checked && this.option.sequenceNumber === 99999) {
      this.otherTextbox.setValue(null);
    }
  }

  getResponse(): any {
    return this.optionCheckbox.value
      ? {
          value: this.option.value,
          other: this.otherTextbox.value,
        }
      : null;
  }

  get optionCheckbox() {
    return this.otherFormGroup.get('option');
  }

  get otherTextbox() {
    return this.otherFormGroup.get('other');
  }
}
