<div *ngIf="(participantLanding$ | async) as participantLanding" style="height: 100%;">
    <div fxLayout fxLayoutAlign="center" style="height: 100%;">
        <div
            fxFlex.lt-md="100"
            fxFlex="0 0 900px"
            style="background-color: white; overflow-y: auto; height: 100%;"
        >
            <ngx-loading [show]="showLoading"></ngx-loading>
            <!-- <div fxLayout="column" fxLayoutGap="15px" style="padding: 20px;">
                <div *ngIf="(applicationMessage$ | async) as applicationMessage">
                    <assess-warning-bar
                        *ngIf="applicationMessage.haveMessage"
                        [warning]="applicationMessage.message"
                        tabindex="0"
                    >
                    </assess-warning-bar>
                </div>
                <div>
                    <span class="mat-title" tabindex="0"
                        >{{'participant.header.title' | translate}}</span
                    >
                </div>
                <div call="mat-body" fxShow fxHide.lt-sm="true" tabindex="0">
                    {{'participant.header.instructions' | translate}}
                </div>

                <assess-aboutme-status
                    *ngIf="hasInstrumentId(participantLanding.programInstrumentStatuses, 2946)"
                    [program]="program"
                    (selfClick)="onSelfClick($event)"
                >
                </assess-aboutme-status>

                <assess-program-card
                    *ngFor="let program of participantLanding.programInstrumentStatuses"
                    [program]="program"
                    (ratersClick)="onRatersClick($event)"
                    (selfClick)="onSelfClick($event)"
                    (viewAssetClick)="onViewAssetClick($event)"
                    (viewDocumentClick)="onViewDocumentClick($event)"
                >
                </assess-program-card>
            </div> -->
        </div>
    </div>
</div>
