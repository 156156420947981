import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';

import { Observable, Subscription, config } from 'rxjs';

import { ParticipantService } from './services/participant.service';
import {
  InstrumentStatus,
  ParticipantLanding,
  AssessmentLinkInfo,
  Program,
} from './models/participant.model';

import { Store, Select, ofActionSuccessful, Actions } from '@ngxs/store';
import { ApplicationState } from '../../store/application.state';
import { User } from '../../core/auth/user.model';
import { ManageRaters } from '../rater-management/store/rater-management.actions';
import { StartSurvey } from '../survey/store/survey.actions';
import { ApplicationMessage, Config } from '../../store/application.models';

import {
  LoadAsset,
  LoadDocument,
  LoadAssetSuccess,
} from '../report-viewer/store/report-viewer.actions';

@Component({
  selector: 'assess-participant-landing',
  templateUrl: './participant-landing.component.html',
})
export class ParticipantLandingComponent implements OnInit, OnDestroy {
  @Select(ApplicationState.getApplicationMessage)
  applicationMessage$: Observable<ApplicationMessage>;

  @Input()
  handleLoading: boolean = true;

  @Output()
  loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  participantLanding$: Observable<ParticipantLanding>;
  user: User = null;
  showLoading: boolean = false;
  private _loadAssetSuccessSubscription: Subscription = null;
  private _cards: number = 0;
  public assessmentsUri;
  public assessments2Uri;
  public instrumentId;
  public participantId;
  public retUrl;
  public surveyType;

  constructor(
    private _store: Store,
    private _participantService: ParticipantService,
    private _actions$: Actions
    
  ) { 
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );
    this.assessmentsUri = config.authRedirectUri;
    this.assessments2Uri = config.assessments2Uri;
    this.instrumentId = this._store.selectSnapshot(ApplicationState.getInstrumentId);
    this.participantId = this._store.selectSnapshot(ApplicationState.getParticipantId);  
    this.retUrl = this._store.selectSnapshot(ApplicationState.getRedirectUrl);
    this.surveyType = this._store.selectSnapshot(ApplicationState.getSurveyType);  
  }

  ngOnInit() {
    document.body.style.background = '#80e2da';
    this.user = this._store.selectSnapshot(ApplicationState.getUser);
    this.participantLanding$ = this._participantService.getParticipantLanding(
      this.user.individualId,
      this.user.preferredLanguageId
    );

    if (this.instrumentId > 0 && this.participantId > 0) {
      let instrumentArray = [];
      instrumentArray.push({
        'id': this.instrumentId,
        'pid': this.participantId,
        'type': this.surveyType,
        'retUrl': this.assessments2Uri
      });

      if (instrumentArray.length > 0) {
        localStorage.setItem("instrumentArray", JSON.stringify([]));
      }
      this.participantLanding$.forEach(x => {
        x.programInstrumentStatuses.forEach(y => {
          y.instrumentStatuses.forEach(z => {
            if (z.instrumentId == this.instrumentId && z.participantId == this.participantId) {
              this.onSelfClick(z);
            }
          })
        });
      });
    }
    else {
      localStorage.setItem("instrumentArray", JSON.stringify([]));
      window.location.href = this.assessments2Uri;
    }

    this._loadAssetSuccessSubscription = this._actions$
      .pipe(ofActionSuccessful(LoadAssetSuccess))
      .subscribe(() => {
        if (this.handleLoading) {
          this.showLoading = false;
        } else {
          this.loading.emit(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this._loadAssetSuccessSubscription !== null) {
      this._loadAssetSuccessSubscription.unsubscribe();
    }
  }
  onRatersClick(instrumentStatus: InstrumentStatus): void {
    if (this.handleLoading) {
      this.showLoading = true;
    } else {
      this.loading.emit(true);
    }
    this._store.dispatch(new ManageRaters(instrumentStatus));
  }

  hasAboutMe(programs: readonly Program[]): number {
    let foundAboutMe: number = 0;
    programs.forEach(program => {

      program.instrumentStatuses.forEach(instrument => {
        if (instrument.instrumentTypeId === 1003) {
          foundAboutMe = 1;
        }
      });

    });
    return foundAboutMe;
  }

  hasInstrumentId(programs: readonly Program[], instrumentId: number): number {
    let foundInsturment: number = 0;
    programs.forEach(program => {

      program.instrumentStatuses.forEach(instrument => {
        if (instrument.instrumentId === instrumentId) {
          foundInsturment = 1;
        }
      });

    });
    return foundInsturment;
  }

  onSelfClick(instrumentStatus: InstrumentStatus): void {
    let assessmentLinkInfo: AssessmentLinkInfo = null;

    if (instrumentStatus.instrumentTypeId !== 6) {
      if (instrumentStatus.instrumentTypeId !== 1003) {
        if (this.handleLoading) {
          this.showLoading = true;
        } else {
          this.loading.emit(true);
        }
      }
    }
    else {
      assessmentLinkInfo = {
        assessmentLink: instrumentStatus.assessmentLinkInfo.assessmentLink,
        reportLink: instrumentStatus.assessmentLinkInfo.reportLink,
      };
    }

    this._store.dispatch(
      new StartSurvey(
        instrumentStatus.instrumentId,
        0,
        this.user.preferredLanguageId,
        instrumentStatus.participantId,
        this.user.individualId,
        instrumentStatus.instrumentTypeId,
        assessmentLinkInfo
      )
    );
  }

  onViewAssetClick(instrumentStatus: InstrumentStatus): void {
    if (instrumentStatus.instrumentTypeId !== 6) {
      if (this.handleLoading) {
        this.showLoading = true;
      } else {
        this.loading.emit(true);
      }
    }
    this._store.dispatch(new LoadAsset(this.user, instrumentStatus));
  }

  onViewDocumentClick(instrumentStatus: InstrumentStatus): void {
    if (this.handleLoading) {
      this.showLoading = true;
    } else {
      this.loading.emit(true);
    }
    this._store.dispatch(new LoadDocument(instrumentStatus));
  }
}
