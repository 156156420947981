import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Select, Store } from '../../../../../../node_modules/@ngxs/store';
import { Observable } from 'rxjs';

import { SurveyDocumentState } from '../../store/survey.state';
import {
  SurveySection,
  CompetencyReview,
  Extension,
} from '../../store/survey.model';

@Component({
  selector: 'assess-survey-competencies-review',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-competencies-review.component.html',
})
export class SurveyCompetenciesReviewComponent implements OnInit {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  @Select(SurveyDocumentState.getCompetenciesReviews)
  competenciesReviews$: Observable<CompetencyReview[]>;

  extensions: Extension[];

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.extensions = this._store.selectSnapshot(
      SurveyDocumentState.getSurveySectionExtensions
    );
  }

  haveQuestionsForAllGroups(competency): boolean {
    let hasQuestions: boolean = false;
    competency.groups.forEach((group) => {
      hasQuestions = hasQuestions || group.questions.length > 0;
    });

    return hasQuestions;
  }

  getExtensionValue(name: string): string {
    const extension: Extension = this.extensions.find(
      (extension: Extension) => extension.name === name
    );

    return extension ? extension.value : null;
  }
}
