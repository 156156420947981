import { Component, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { AdditionalInstructions } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-additional-instructions',
  templateUrl: './survey-additional-instructions.component.html',
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('disclosureState', [
      state('closed', style({ transform: 'rotate(0)' })),
      state('opened', style({ transform: 'rotate(-180deg)' })),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in'))
    ]),
    trigger('collapsedState', [
      state(
        'closed',
        style({ height: '0', 'overflow-y': 'hidden', padding: '0' })
      ),
      state(
        'opened',
        style({ height: '*', 'overflow-y': 'hidden', padding: '0' })
      ),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in'))
    ])
  ]
})
export class SurveyAdditionalInstructionsComponent {
  @Input()
  additionalInstructions: AdditionalInstructions;

  state: string = 'closed';

  toggle(): void {
    this.state = this.state === 'closed' ? 'opened' : 'closed';
  }
}
