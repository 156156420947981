<div fxLayout="column" fxLayoutGap="5px" style="padding: 10px;">
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" [id]="question.formItemId">
    <div fxLayout fxLayoutAlign="start start" [ngClass]="{'not-answered': !answered()}">
      <div fxFlex="0 0 20px">
        <i class="fa fa-star text-success" *ngIf="answered()"></i>
      </div>
      <p style="margin: 0;" fxLayout fxLayoutGap="5px">
        <span>{{question.number}}.</span>
        <span *ngIf="question.text && question.text.length > 0" [innerHtml]="question.text" tabindex="0"></span>
      </p>
    </div>
    <div style="padding-left: 40px;">
      <div fxLayout="column">
        <div *ngFor="let option of question.options; let i = index;">
          <mat-select fxFlex="0 0 50px" (selectionChange)="onSelectionChanged($event, i)" [ngModel]="getAnswer(i)" tabindex="0">
            <mat-option [value]="-1" tabindex="0"></mat-option>
            <mat-option *ngFor="let answerGroupItem of answerGroup" [value]="answerGroupItem.value" tabindex="0">
              {{ answerGroupItem.text }}
            </mat-option>
          </mat-select>
          <span style="margin-left: 10px;" tabindex="0">{{option.text}}</span>
        </div>
      </div>    </div>
    <div *ngIf="!valid()" class="text-warning" tabindex="0">{{getExtensionValue('validationMessage')}}</div>
  </div>
</div>