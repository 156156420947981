<div>
  <div *ngIf="questionGroup.heading" [id]="questionGroup.heading.formItemSequenceNumber" class="question-group header" tabindex="0"> 
    {{questionGroup.heading.text}}</div>
  <div *ngFor="let question of questionGroup.questions">
    <assess-survey-scales-question *ngIf="!question.hasOptions else multiScales"
      [surveyScale]="questionGroup.surveyScale" [question]="question" (questionAnswered)="onQuestionAnswered($event)">
    </assess-survey-scales-question>
    <ng-template #multiScales>
      <assess-survey-multi-scales-question [surveyScale]="questionGroup.surveyScale" [question]="question"
        (questionAnswered)="onQuestionAnswered($event)">
      </assess-survey-multi-scales-question>
    </ng-template>
  </div>
</div>