import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BackgroundComponent } from '../../background-component';
import {
  BackgroundItem,
  BackgroundResponse,
} from '../../store/background-landing.models';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'assess-school',
  templateUrl: './school.component.html',
})
export class SchoolComponent implements OnInit, BackgroundComponent {
  @Input()
  backgroundItem: BackgroundItem;
  @Input()
  backgroundResponses: BackgroundResponse[];
  @Input()
  id: string;

  @Output()
  schoolDeleted: EventEmitter<string> = new EventEmitter<string>();

  schoolFormGroup: FormGroup;

  schoolNamePlaceHolder: string;
  majorStudyPlaceHolder: string;

  constructor() {}

  ngOnInit() {
    this.schoolFormGroup = new FormGroup({});

    this.backgroundItem.dependentBackgroundItems.forEach(
      (dependentBackgroundItem: BackgroundItem) => {
        switch (dependentBackgroundItem.backgroundItemId) {
          case 13:
          case 35:
          case 37:
          case 39:
          case 41:
            {
              const backgroundResponse: BackgroundResponse =
                this.backgroundResponses !== null
                  ? this.backgroundResponses.find(
                      (backgroundResponse: BackgroundResponse) =>
                        backgroundResponse.backgroundItemId ===
                        dependentBackgroundItem.backgroundItemId
                    )
                  : null;
              this.schoolFormGroup.addControl(
                'schoolName',
                new FormControl(
                  backgroundResponse !== null
                    ? backgroundResponse.response
                    : null
                )
              );
              this.schoolNamePlaceHolder =
                dependentBackgroundItem.backgroundItemText.text;
            }
            break;
          case 14:
          case 36:
          case 38:
          case 40:
          case 42:
            {
              const backgroundResponse: BackgroundResponse =
                this.backgroundResponses !== null
                  ? this.backgroundResponses.find(
                      (backgroundResponse: BackgroundResponse) =>
                        backgroundResponse.backgroundItemId ===
                        dependentBackgroundItem.backgroundItemId
                    )
                  : null;
              this.schoolFormGroup.addControl(
                'majorStudy',
                new FormControl(
                  backgroundResponse !== null
                    ? backgroundResponse.response
                    : null
                )
              );
              this.majorStudyPlaceHolder =
                dependentBackgroundItem.backgroundItemText.text;
            }
            break;
        }
      }
    );
  }

  getResponse(): any {
    let responses: Map<string, string> = new Map<string, string>();

    // get all the responses and package them up to be saved
    if (
      (this.schoolName.value && this.schoolName.value.trim() !== '') ||
      (this.majorStudy.value && this.majorStudy.value.trim() !== '')
    ) {
      responses.set('school-name', this.schoolName.value);
      responses.set('major-study', this.majorStudy.value);
    }

    return responses.size > 0 ? responses : null;
  }

  onRemoveSchoolClick(): void {
    this.schoolDeleted.emit(this.id);
  }

  get schoolName() {
    return this.schoolFormGroup.get('schoolName');
  }

  get majorStudy() {
    return this.schoolFormGroup.get('majorStudy');
  }
}
