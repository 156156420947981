import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { SurveySection } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-section-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-section-header.component.html'
})
export class SurveySectionHeaderComponent {
  @Input()
  surveySection: SurveySection;
}
