import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';

import { Config } from '../../../store/application.models';

import { MyProfileResponse, Profile } from '../models/my-profile.model';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  private config: Config;

  public constructor(private _store: Store, private _httpClient: HttpClient) {
    this.config = this._store.selectSnapshot(state => state.application.config);
  }

  getMyProfile(
    individualId: number,
    languageId: number
  ): Observable<MyProfileResponse> {
    return this._httpClient.get<MyProfileResponse>(
      `${this.config.assessmentWebApi}/my-profile/${individualId}/${languageId}`
    );
  }

  save(profile: Profile): Observable<Profile> {
    return this._httpClient.put<Profile>(
      `${this.config.assessmentWebApi}/my-profile`,
      { profile }
    );
  }
}
