import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

import {
  SurveySection,
  QuestionGroup,
  Question,
  NavigationEvent,
  NEXT_GROUP,
  PREVIOUS_GROUP,
  MENU,
  REVIEW_QUESTION,
  REVIEW_SECTION,
  CHANGE_TAB,
  QuestionListItem,
} from './survey.model';
import {
  BuildTabbedSection,
  SetQuestionGroups,
  SetTabIndex,
  PreviousGroup,
  NextGroup,
  SetQuestionGroupsSuccess,
  SetStartTab,
  UpdateTabbedSection,
  ChangeTab,
} from './tabbed-section.actions';

import { SurveyDocumentState } from './survey.state';

import { TabGroup } from './tabbed-section.model';
import { SetScrollTarget, Navigation } from './survey.actions';
import {
  NEXT_SECTION,
  PREVIOUS_SECTION,
} from '../modules/survey-stepper/survey-stepper.model';
import { ThrowException } from '../../../store/application.actions';
import { GlobalExceptionStatus } from '../../../global-exception/global-exception-status.enum';
import { Injectable } from '@angular/core';

export class TabbedSectionStateModel {
  tabGroups: TabGroup[];
  questionGroups: QuestionGroup[];
  currentTabIndex: number;
}

@State<TabbedSectionStateModel>({
  name: 'tabbedSection',
  defaults: {
    tabGroups: null,
    questionGroups: null,
    currentTabIndex: 0,
  },
})
@Injectable({
  providedIn: 'root',
})
export class TabbedSectionState {
  static NUMBER_OF_TABS: number = 5;

  constructor(private _store: Store) {}

  @Selector()
  static getTabs(state: TabbedSectionStateModel): TabGroup[] {
    return state.tabGroups;
  }

  @Selector()
  static getQuestionGroups(state: TabbedSectionStateModel): QuestionGroup[] {
    return state.questionGroups;
  }

  @Selector()
  static getTabIndex(state: TabbedSectionStateModel): number {
    return state.currentTabIndex;
  }

  @Selector()
  static getFirstGroup(state: TabbedSectionStateModel): boolean {
    return state.currentTabIndex === 0;
  }

  @Selector()
  static getLastGroup(state: TabbedSectionStateModel): boolean {
    return state.currentTabIndex === state.tabGroups.length - 1;
  }

  @Action(BuildTabbedSection)
  buildTabbedSection(ctx: StateContext<TabbedSectionStateModel>) {
    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );

    // if no question groups then no tabs to build
    if (surveySection.questionGroups === null) {
      return;
    }

    const tabGroups: TabGroup[] = this.getTabs(surveySection);

    ctx.patchState({
      tabGroups: [...tabGroups],
    });

    ctx.dispatch(new SetStartTab());
  }

  @Action(SetStartTab)
  setStartTab(ctx: StateContext<TabbedSectionStateModel>) {
    const state: TabbedSectionStateModel = ctx.getState();

    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );
    const lastNavigationEvent: NavigationEvent = this._store.selectSnapshot(
      SurveyDocumentState.getLastNavigationEvent
    );
    const scrollTarget: number = this._store.selectSnapshot(
      SurveyDocumentState.getScrollTarget
    );

    let currentTabIndex: number = -1;
    let newScrollTarget: number = -1;

    // if navigation is any of these four then just start
    // at the beginning of the section
    if (
      lastNavigationEvent.source === NEXT_SECTION ||
      lastNavigationEvent.source === PREVIOUS_SECTION ||
      lastNavigationEvent.source === REVIEW_SECTION ||
      lastNavigationEvent.source === MENU
    ) {
      currentTabIndex = 0;
      if (!!surveySection.questionGroups[0].heading) {
        newScrollTarget =
          surveySection.questionGroups[0].heading.formItemSequenceNumber;
      } else {
        newScrollTarget =
          surveySection.questionGroups[0].questions[0].formItemId;
      }
    } else {
      // user navigated here from the review page or from the start button
      // or clicked on a question number from the review page
      for (let i = 0; i < state.tabGroups.length; i++) {
        let questionIndex: number = -1;
        if (lastNavigationEvent.source === REVIEW_QUESTION) {
          for (
            let j = 0;
            j < state.tabGroups[i].questionGroupIndexes.length;
            j++
          ) {
            questionIndex = surveySection.questionGroups[
              state.tabGroups[i].questionGroupIndexes[j]
            ].questions.findIndex(
              (question: Question) => question.formItemId === scrollTarget
            );
            if (questionIndex > -1) {
              currentTabIndex = i;
              break;
            }
          }
          newScrollTarget = scrollTarget;
        } else {
          // find first answered question in group
          const firstUnansweredQuestion: Question = this._store.selectSnapshot(
            SurveyDocumentState.getSurveySectionFirstUnansweredQuestion
          );

          for (
            let j = 0;
            j < state.tabGroups[i].questionGroupIndexes.length;
            j++
          ) {
            questionIndex = surveySection.questionGroups[
              state.tabGroups[i].questionGroupIndexes[j]
            ].questions.findIndex(
              (question: Question) =>
                question.formItemId === firstUnansweredQuestion.formItemId
            );

            if (questionIndex > -1) {
              currentTabIndex = i;
              newScrollTarget = firstUnansweredQuestion.formItemId;
              break;
            }
          }
        }

        if (questionIndex > -1) {
          break;
        }
      }
    }

    ctx.dispatch(new SetScrollTarget(newScrollTarget));
    ctx.dispatch(
      new SetTabIndex(
        currentTabIndex < 0 ? state.tabGroups.length - 1 : currentTabIndex
      )
    );
  }

  @Action(SetQuestionGroups)
  setQuestionGroups(ctx: StateContext<TabbedSectionStateModel>) {
    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );
    const state: TabbedSectionStateModel = ctx.getState();

    const start =
      state.tabGroups[state.currentTabIndex].questionGroupIndexes[0];
    const end =
      state.tabGroups[state.currentTabIndex].questionGroupIndexes[
        state.tabGroups[state.currentTabIndex].questionGroupIndexes.length - 1
      ] + 1;

    const currentQuestionGroups: QuestionGroup[] = surveySection.questionGroups.slice(
      start,
      end
    );

    ctx.patchState({
      questionGroups: JSON.parse(JSON.stringify(currentQuestionGroups)),
    });

    ctx.dispatch(new SetQuestionGroupsSuccess());
  }

  @Action(SetTabIndex)
  setTabIndex(ctx: StateContext<TabbedSectionStateModel>, action: SetTabIndex) {
    ctx.patchState({
      currentTabIndex: action.tabIndex,
    });
  }

  @Action(ChangeTab)
  changeTab(ctx: StateContext<TabbedSectionStateModel>, action: ChangeTab) {
    const currentSectionIndex: number = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSectionIndex
    );
    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );
    const state: TabbedSectionStateModel = ctx.getState();
    const start = state.tabGroups[action.tabIndex].questionGroupIndexes[0];

    if (surveySection.questionGroups[start].heading) {
      ctx.dispatch(
        new SetScrollTarget(
          surveySection.questionGroups[start].heading.formItemSequenceNumber
        )
      );
    } else {
      ctx.dispatch(
        new SetScrollTarget(
          surveySection.questionGroups[start].questions[0].formItemId
        )
      );
    }

    ctx.dispatch(
      new Navigation({
        source: CHANGE_TAB,
        sectionIndex: currentSectionIndex,
      })
    );
    ctx.dispatch(new SetTabIndex(action.tabIndex));
  }

  @Action(PreviousGroup)
  previousGroup(ctx: StateContext<TabbedSectionStateModel>) {
    const currentSectionIndex: number = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSectionIndex
    );
    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );
    const state: TabbedSectionStateModel = ctx.getState();

    const start =
      state.tabGroups[state.currentTabIndex].questionGroupIndexes[0];

    if (surveySection.questionGroups[start].heading) {
      ctx.dispatch(
        new SetScrollTarget(
          surveySection.questionGroups[start].heading.formItemSequenceNumber
        )
      );
    } else {
      ctx.dispatch(
        new SetScrollTarget(
          surveySection.questionGroups[start].questions[0].formItemId
        )
      );
    }

    ctx.dispatch(
      new Navigation({
        source: PREVIOUS_GROUP,
        sectionIndex: currentSectionIndex,
      })
    );
    ctx.dispatch(new SetTabIndex(state.currentTabIndex - 1));
  }

  @Action(NextGroup)
  nextGroup(ctx: StateContext<TabbedSectionStateModel>) {
    const currentSectionIndex: number = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSectionIndex
    );
    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );
    const state: TabbedSectionStateModel = ctx.getState();

    const start =
      state.tabGroups[state.currentTabIndex + 1].questionGroupIndexes[0];

    if (surveySection.questionGroups[start].heading) {
      ctx.dispatch(
        new SetScrollTarget(
          surveySection.questionGroups[start].heading.formItemSequenceNumber
        )
      );
    } else {
      ctx.dispatch(
        new SetScrollTarget(
          surveySection.questionGroups[start].questions[0].formItemId
        )
      );
    }

    ctx.dispatch(
      new Navigation({
        source: NEXT_GROUP,
        sectionIndex: currentSectionIndex,
      })
    );
    ctx.dispatch(new SetTabIndex(state.currentTabIndex + 1));
  }

  @Action(UpdateTabbedSection)
  updateTabbedSection(ctx: StateContext<TabbedSectionStateModel>) {
    const surveySection: SurveySection = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentSurveySection
    );

    // if no question groups then no tabs to build
    if (surveySection.questionGroups === null) {
      return;
    }

    const tabGroups: TabGroup[] = this.getTabs(surveySection);

    ctx.patchState({
      tabGroups: [...tabGroups],
    });
  }

  private getTabs(surveySection: SurveySection): TabGroup[] {
    const questionList: QuestionListItem[] = this._store.selectSnapshot(
      SurveyDocumentState.getQuestionList
    );

    const tabGroups: any[] = this.buildTabGroups(surveySection);

    tabGroups.forEach((tab: TabGroup) => {
      let allAnswered: boolean = true;

      tab.questionGroupIndexes.forEach((questionGroupIndex: number) => {
        allAnswered =
          allAnswered &&
          surveySection.questionGroups[
            questionGroupIndex
          ].questions.every((question: Question) =>
            questionList.some(
              (questionListItem: QuestionListItem) =>
                questionListItem.formItemId === question.formItemId &&
                questionListItem.answer &&
                questionListItem.answer !== null
            )
          );
      });

      if (tab.tabText === null) {
        let start: string =
          surveySection.questionGroups[tab.questionGroupIndexes[0]].questions[0]
            .number;
        let lastQuestionGroup: QuestionGroup =
          surveySection.questionGroups[
            tab.questionGroupIndexes[tab.questionGroupIndexes.length - 1]
          ];
        let end: string =
          lastQuestionGroup.questions[lastQuestionGroup.questions.length - 1]
            .number;
        tab.tabText = `${start} - ${end}`;
      }

      tab.allAnswered = allAnswered;
    });

    return tabGroups;
  }

  private groupsPerTab(surveySection: SurveySection): number {
    return Math.ceil(
      surveySection.questionGroups.length / TabbedSectionState.NUMBER_OF_TABS
    );
  }

  private buildTabGroups(surveySection: SurveySection): any[] {
    let tabGroups: TabGroup[] = [];

    // validate that all groups are either custom tab names or not
    let groupsWithCustomTabText: QuestionGroup[] = surveySection.questionGroups.filter(
      (questionGroup: QuestionGroup) =>
        questionGroup.tabText && questionGroup.tabText.length > 0
    );
    if (
      groupsWithCustomTabText.length != 0 &&
      groupsWithCustomTabText.length != surveySection.questionGroups.length
    ) {
      this._store.dispatch(
        new ThrowException(
          {
            error:
              'Attempting to use Custom tab text but not all question groups have tab text defined.',
            status: GlobalExceptionStatus.InvalidTabDefinition,
          },
          'Building survey tabs'
        )
      );
    }

    // build tabs with custom text
    if (groupsWithCustomTabText.length > 0) {
      surveySection.questionGroups.forEach(
        (questionGroup: QuestionGroup, index: number) => {
          let tab: TabGroup = tabGroups.find(
            (tab: TabGroup) => tab.tabText === questionGroup.tabText
          );
          if (tab) {
            tab.questionGroupIndexes.push(index);
          } else {
            tabGroups.push({
              tabText: questionGroup.tabText,
              questionGroupIndexes: [index],
              allAnswered: true,
            });
          }
        }
      );
    } else {
      //build tabs out of question groups
      const groupsPerTab = this.groupsPerTab(surveySection);

      if (groupsPerTab === 1) {
        // if here there are 1-5 question groups so the final tab array will have 1-5 tabs
        surveySection.questionGroups.forEach(
          (questionGroup: QuestionGroup, index: number) => {
            tabGroups.push({
              tabText: null,
              questionGroupIndexes: [index],
              allAnswered: true,
            });
          }
        );
      } else {
        // things get a little crazy with groups being spread out evenly over tabs
        let start: number = 0;
        let end: number = groupsPerTab - 1;

        for (let i = 0; i < TabbedSectionState.NUMBER_OF_TABS; i++) {
          let indexes: number[] = [];
          for (let j = start; start < end; j++) {
            indexes.push(j);
            start += 1;
          }

          if (
            i <
              surveySection.questionGroups.length %
                TabbedSectionState.NUMBER_OF_TABS ||
            surveySection.questionGroups.length %
              TabbedSectionState.NUMBER_OF_TABS ===
              0
          ) {
            indexes.push(start);
            start += 1;
          }

          end = start + (groupsPerTab - 1);

          tabGroups.push({
            tabText: null,
            questionGroupIndexes: [...indexes],
            allAnswered: true,
          });
        }
      }
    }

    return tabGroups;
  }
}
