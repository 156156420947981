<div style="padding: 5px 10px;" [ngClass]="{'stripe': index % 2 !== 0}" [id]="question.formItemId" fxLayout="column">
  <div fxLayout fxLayoutGap="10px" class="mb-1">
    <div>{{index + 1}}.</div>
    <div>
      <div [innerHtml]="question.text" tabindex="0"></div>
      <div *ngIf="question.subtext" [innerHtml]="question.subtext" tabindex="0"></div>
    </div>
  </div>
  <form novalidate [formGroup]="otherFormGroup">
    <mat-radio-group fxLayout="column" fxLayoutGap="7px" formControlName="option">
      <div *ngFor="let option of question.options">
        <div fxLayout fxLayoutAlign="start" style="padding: 10px;">
          <div fxFlex="0 0 30px">
            <mat-radio-button [value]="option.value" (change)="onOptionChanged($event)" aria-label="RadioButton"></mat-radio-button>
          </div>
          <div [innerHtml]="option.text"></div>
        </div>
        <div *ngIf="option.dependentOption" style="padding-top: 5px; padding-left: 10px">
          <mat-form-field *ngIf="showOther && option.dependentOption.multiline === false" class="full-width">
            <input matInput #message [attr.id]="'other' + option.formItemSequenceNumber" formControlName="other"
              [maxLength]="option.dependentOption.maxLength" autocomplete="nope"
              [placeholder]="option.dependentOption.placeholder">
            <mat-hint align="end">{{message.value?.length || 0}} /
              {{ option.dependentOption.maxLength }}</mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="showOther && option.dependentOption.multiline === true" class="full-width">
            <textarea matInput #message [maxLength]="option.dependentOption.maxLength"
              [placeholder]="option.dependentOption.placeholder" matTextareaAutosize matAutosizeMinRows="2"
              matAutosizeMaxRows="7" [formControl]="otherTextbox"></textarea>
            <mat-hint align="end">{{message.value?.length || 0}} /
              {{ option.dependentOption.maxLength }}</mat-hint>
          </mat-form-field>

        </div>
      </div>
    </mat-radio-group>
  </form>
</div>