<div style="padding: 5px 10px;" [ngClass]="{'stripe': index % 2 !== 0}" [id]="question.formItemId" fxLayout="column">
  <div fxLayout fxLayoutGap="10px" class="mb-1">
    <div>{{index + 1}}.</div>
    <div>
      <div [innerHtml]="question.text" tabindex="0"></div>
      <div *ngIf="question.subtext" [innerHtml]="question.subtext" tabindex="0"></div>
    </div>
  </div>
  <assess-multi-option *ngFor="let option of question.options" [option]="option"
    (questionAnswered)="onQuestionAnswered($event)" (otherAnswered)="onOtherAnswered($event)"></assess-multi-option>
</div>