<div [ngStyle]="{ 'height.px': 64 }" [ngStyle.xs]="{ 'height.px': 56 }">
  <mat-toolbar *ngIf="(user$ | async) as user" fxLayout fxLayoutAlign="space-between center" class="mat-elevation-z3">
    <span fxFlex="0 0 25">
      <button mat-menu-item (click)="onLogoClick()">
        <img fxShow fxHide.lt-md="true" src="https://public-assets.ccl.org/images/logos/ccl/2-line/ccl-logo-color.svg"
        alt="Center for Creative Leadership Logo" width="175px" style="vertical-align: middle;" />
      </button>
      <img fxShow fxHide.gt-sm="true" src="../../../assets/images/CCL_sig_TMR_grad_3C_RGB.png"
      alt="Center for Creative Leadership Logo" width="75px" style="vertical-align: middle;" />
    </span>
    <span fxFlex="50" class="text-center" tabindex="0">Assessments</span>
    <span fxFlex="25" style="text-align: right;">
      <a href="https://support.ccl.org/" mat-mini-fab target="_blank"
        style="color:white;text-decoration: none;margin-left: 5px; font-size:small;font-weight: bold;float: right; background-color: #003da7;">HELP</a>
      <span [matMenuTriggerFor]="appMenu" style="cursor: pointer;">
        <button mat-mini-fab aria-label="User profile" tabindex="0">{{ userInitials }}</button> &nbsp;<i
          class="fa fa-angle-down" aria-hidden="true"></i>
        <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item (click)="onMyProfile()">
            {{ 'appMenu.myProfile' | translate }}
          </button>
          <button mat-menu-item (click)="onContactUs()">
            {{ 'appMenu.contactUs' | translate }}
          </button>
          <button mat-menu-item (click)="onFaq()">
            {{ 'appMenu.faq' | translate }}
          </button>
          <button mat-menu-item (click)="onPrivacyPolicy()" (keypress)="onPrivacyPolicy()">
            {{ 'appMenu.privacyPolicy' | translate }}
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="onLogout()" (keypress)="onLogout()">
            <i class="fa fa-sign-out fa-fw text-warning"></i>
            <span class="menu icon-item text-warning">{{
              'appMenu.logout' | translate
              }}</span>
          </button>
        </mat-menu>
      </span>
    </span>
  </mat-toolbar>
</div>