<div style="padding: 5px 10px;" [ngClass]="{'stripe': index % 2 !== 0}" fxLayout="column">
  <div fxLayout fxLayoutGap="10px" class="mb-1">
    <div>{{index + 1}}.</div>
    <div>
      <div [innerHtml]="question.text" tabindex="0"></div>
      <div *ngIf="question.subtext" [innerHtml]="question.subtext" tabindex="0"></div>
    </div>
  </div>
  <div>
    <mat-form-field *ngIf="question.multiline === true else singleLine" class="full-width">
      <textarea #message matInput [attr.id]="question.formItemId" [maxLength]="question.maxLength" matTextareaAutosize
        matAutosizeMinRows="2" matAutosizeMaxRows="7" [formControl]="response"
        [placeholder]="question.placeholder" aria-label="Please enter your response"></textarea>
      <mat-error *ngIf="response.invalid">{{getErrorMessage()}}</mat-error>
      <mat-hint align="end">{{message.value?.length || 0}} /
        {{ question.maxLength }}</mat-hint>
    </mat-form-field>
    <ng-template #singleLine>
      <mat-form-field class="full-width">
        <input matInput [attr.id]="question.formItemId" [formControl]="response" autocomplete="nope"
          [placeholder]="question.placeholder">
        <mat-error *ngIf="response.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </ng-template>
  </div>
</div>