import { Component, Input } from '@angular/core';

import { RaterStatus } from '../../store/rater-management.models';

@Component({
  selector: 'assess-rater-type-label',
  templateUrl: './rater-type-label.component.html',
  styleUrls: ['./rater-type-label.component.scss']
})
export class RaterTypeLabelComponent {
  @Input() raterStatus: RaterStatus;
}
