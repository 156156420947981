<form novalidate [formGroup]="schoolFormGroup" style="margin-left: 10px;">
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
    <mat-form-field fxFlex.xs="40%" fxFlex="45%" class="full-width">
      <input matInput [formControl]="schoolName" autocomplete="nope" [placeholder]="schoolNamePlaceHolder">
    </mat-form-field>
    <mat-form-field fxFlex.xs="40%" fxFlex="45%" class="full-width">
      <input matInput [formControl]="majorStudy" autocomplete="nope" [placeholder]="majorStudyPlaceHolder">
    </mat-form-field>
    <button mat-icon-button (click)="onRemoveSchoolClick()">
      <i class="fa fa-remove"></i>
    </button>
  </div>
</form>