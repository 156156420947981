<div fxShow fxHide.lt-sm="true">
    <mat-card>
        <!-- <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="20px">
                <div fxLayout="column">
                    <div class="text-bold text-large" tabindex="0">{{program.name}}</div>
                    <div
                        *ngIf="program.assessmentDueDate"
                        class="text-muted text-small"
                        tabindex="0"
                        [translate]="'participant.header.completeBy'"
                        [translateParams]="{completeBy: program.assessmentDueDate | localizedDate:'fullDate'}"
                    ></div>
                </div>
            </div>
            <div class="mt-1">
                <div *ngFor="let instrumentStatus of program.instrumentStatuses; let i = index">
                    <div *ngIf="instrumentStatus.instrumentTypeId!==1003">
                        <assess-instrument-status
                            *ngIf="!instrumentStatus.documentInfo"
                            [instrumentStatus]="instrumentStatus"
                            [highlight]="i % 2 === 0"
                            (ratersClick)="onRatersClick($event)"
                            (selfClick)="onSelfClick($event)"
                            (viewAssetClick)="onViewAssetClick($event)"
                        ></assess-instrument-status>
                        <assess-document-status
                            *ngIf="instrumentStatus.documentInfo"
                            [instrumentStatus]="instrumentStatus"
                            [highlight]="i % 2 === 0"
                            (viewDocumentClick)="onViewDocumentClick($event)"
                        ></assess-document-status>
                    </div>
                </div>
            </div>
        </mat-card-content> -->
    </mat-card>
</div>

<div fxHide fxShow.lt-sm="true" fxLayout="column" fxLayoutGap="10px">
    <mat-card>
       <!-- <mat-card-content>
            <div fxLayout="column">
                <div class="text-bold text-large">{{program.name}}</div>
                <div
                    class="text-muted text-small"
                    [translate]="'participant.header.completeBy'"
                    [translateParams]="{completeBy: program.assessmentDueDate | localizedDate}"
                ></div>
            </div>
            <div class="mt-1">
                <div *ngFor="let instrumentStatus of program.instrumentStatuses; let i = index">
                    <div *ngIf="instrumentStatus.instrumentTypeId!==1003">
                        <assess-instrument-status
                            *ngIf="!instrumentStatus.documentInfo"
                            [instrumentStatus]="instrumentStatus"
                            (ratersClick)="onRatersClick($event)"
                            (selfClick)="onSelfClick($event)"
                            (viewDocumentClick)="onViewDocumentClick($event)"
                        >
                        </assess-instrument-status>
                        <assess-document-status
                            *ngIf="instrumentStatus.documentInfo"
                            [instrumentStatus]="instrumentStatus"
                            [highlight]="i % 2 === 0"
                            (viewDocumentClick)="onViewDocumentClick($event)"
                        ></assess-document-status>
                    </div>
                </div>
            </div>
        </mat-card-content> -->
    </mat-card>
</div>
