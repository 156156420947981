<div style="padding: 5px 10px;" [ngClass]="{'stripe': index % 2 !== 0}" fxLayout="column">
  <div>
    <span>{{index + 1}}. </span><span [innerHtml]="question.text"></span>
  </div>
  <div *ngIf="question.subtext" [innerHtml]="question.subtext"></div>
  <mat-radio-group fxLayout="column" fxLayoutGap="7px" [ngModel]="(questionListItem$ | async).answer">
    <div *ngFor="let optionGroup of question.optionGroups">
      <div class="question-group header">{{optionGroup.name}}</div>
      <div fxLayout="column" fxLayoutGap="5px" style="margin-top: 5px;">
        <mat-radio-button *ngFor="let option of optionGroup.options" [value]="option.value"
          (change)="onOptionChanged($event)">{{option.text}}</mat-radio-button>
      </div>
    </div>
  </mat-radio-group>
</div>