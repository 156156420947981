import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  CanActivateChild
} from '@angular/router';

import { Store } from '@ngxs/store';
import { ApplicationState } from '../../store/application.state';
import { User } from '../../core/auth/user.model';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  public constructor(private _store: Store) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let url: string = state.url;
    return this.checkAuthentication(url);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // This will be useful once the app gets more
    // complicated routes that are guarded by roles.
    return this.canActivate(route, state);
  }

  public checkAuthentication(url: string): boolean {
    // if user logged in then allow routing to continue
    const user: User = this._store.selectSnapshot(ApplicationState.getUser);
    return user !== null;
  }
}
