import { Location } from '@angular/common';

import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import {
  RaterStatus,
  Language,
  AddRaterRequest,
  Individual,
} from '../../../store/rater-management.models';

import { ThrowException } from '../../../../../store/application.actions';
import {
  AddRaters,
  LoadLanguages,
  LoadLanguagesSuccess,
  LoadMruRaters,
  LoadMruRatersSuccess,
  InviteRaters,
  InviteRatersSuccess,
  InviteRatersFailed,
  SearchIndividual,
  SearchIndividualSuccess,
} from './add-rater.actions';

import { RatersService } from '../services/raters.service';
import { User } from '../../../../../core/auth/user.model';
import { ApplicationState } from '../../../../../store/application.state';
import { Injectable } from '@angular/core';

export class AddRaterStateModel {
  raterStatus: RaterStatus;
  mruRaters: Individual[];
  languages: Language[];
}

@State<AddRaterStateModel>({
  name: 'addRater',
  defaults: {
    raterStatus: null,
    mruRaters: [],
    languages: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class AddRaterState {
  constructor(
    private _ratersService: RatersService,
    private _location: Location,
    private _store: Store
  ) {}

  @Selector()
  static getRaterStatus(state: AddRaterStateModel): RaterStatus {
    return state.raterStatus;
  }

  @Selector()
  static getMruRaters(state: AddRaterStateModel): Individual[] {
    return state.mruRaters;
  }

  @Selector()
  static getLanguages(state: AddRaterStateModel): Language[] {
    return state.languages;
  }

  // actions
  @Action(AddRaters)
  addRaters(ctx: StateContext<AddRaterStateModel>, action: AddRaters) {
    ctx.patchState({
      raterStatus: action.raterStatus,
    });
    ctx.dispatch(new LoadLanguages());
  }

  @Action(LoadLanguages)
  loadLanguages(ctx: StateContext<AddRaterStateModel>) {
    const state = ctx.getState();
    const user: User = this._store.selectSnapshot(ApplicationState.getUser);

    this._ratersService
      .getLanguages(state.raterStatus.instrument.id, user.preferredLanguageId)
      .subscribe(
        (languages: Language[]) =>
          ctx.dispatch(new LoadLanguagesSuccess(languages)),
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'loadLanguages',
              }
            )
          )
      );
  }

  @Action(LoadMruRaters)
  loadMruRaters(ctx: StateContext<AddRaterStateModel>) {
    const state = ctx.getState();
    const user: User = this._store.selectSnapshot(ApplicationState.getUser);

    this._ratersService
      .getMruRaters(state.raterStatus.participantId, user.preferredLanguageId)
      .subscribe(
        (individuals: Individual[]) =>
          ctx.dispatch(new LoadMruRatersSuccess(individuals)),
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'loadMruRaters',
                data: `participantId: ${state.raterStatus.participantId}`,
              }
            )
          )
      );
  }

  @Action(InviteRaters)
  saveRaters(ctx: StateContext<AddRaterStateModel>, action: InviteRaters) {
    const raterStatus = ctx.getState().raterStatus;

    let requests: AddRaterRequest[] = action.individuals.map(
      (individual: Individual) => {
        return {
          participantId: raterStatus.participantId,
          instrumentId: raterStatus.instrument.id,
          firstName: individual.firstName.trim(),
          lastName: individual.lastName.trim(),
          email: individual.email.trim(),
          raterTypeId: raterStatus.raterType.id,
          languageId: individual.language.id,
        };
      }
    );
    this._ratersService.saveRaters(requests).subscribe(
      (result: any) => {
        if (result.status === 0) {
          ctx.dispatch(new InviteRatersSuccess());
        } else {
          ctx.dispatch(new InviteRatersFailed(result.status, result.email));
        }
      },
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'saveRaters',
              data: requests,
            }
          )
        )
    );
  }

  @Action(SearchIndividual)
  searchIndividual(
    ctx: StateContext<AddRaterStateModel>,
    action: SearchIndividual
  ) {
    this._ratersService.search(action.email).subscribe(
      (individual: Individual) => {
        ctx.dispatch(new SearchIndividualSuccess(individual, action.source));
      },
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'searchIndividual',
              data: `Searching for email ${action.email}`,
            }
          )
        )
    );
  }

  // events
  @Action(LoadLanguagesSuccess)
  loadLanguagesSuccess(
    ctx: StateContext<AddRaterStateModel>,
    action: LoadLanguagesSuccess
  ) {
    ctx.patchState({
      languages: [...action.languages],
    });
    ctx.dispatch(new LoadMruRaters());
  }

  @Action(LoadMruRatersSuccess)
  loadMruRatersSuccess(
    ctx: StateContext<AddRaterStateModel>,
    action: LoadMruRatersSuccess
  ) {
    ctx.patchState({
      mruRaters: [...action.individuals],
    });
    ctx.dispatch(new Navigate(['/add-raters']));
  }

  @Action(InviteRatersSuccess)
  saveRatersSuccess(ctx: StateContext<AddRaterStateModel>) {
    // tried several different variations of using Navigate action
    // but couldn't get the browser history to work out properly
    // injecting Location and using back() appears to be the only work around

    // this commented code did not work.
    // ctx.dispatch(
    //   new Navigate(['../'], null, { relativeTo: this._activatedRoute })
    // );

    // just use location.back() and move on
    this._location.back();
  }
}
