import {
  BackgroundLanding,
  BackgroundResponseDocument
} from './background-landing.models';

export class LoadBackground {
  static readonly type = '[Background Landing] Load Background';
  constructor(public individualId: number, public languageId: number) {}
}

export class LoadBackgroundSuccess {
  static readonly type = '[Background Landing] Load Background Success';
  constructor(public backgroundLanding: BackgroundLanding) {}
}

export class SaveBackground {
  static readonly type = '[Background Landing] Save Background';
  constructor(public backgroundResponseDocument: BackgroundResponseDocument) {}
}

export class SaveBackgroundSuccess {
  static readonly type = '[Background Landing] Save Background Success';
  constructor(public backgroundResponseDocument: BackgroundResponseDocument) {}
}
