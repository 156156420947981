import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxsModule } from '@ngxs/store';

import * as Registry from './type-registry';

import { BackgroundLandingRoutingModule } from '../background-landing/background-landing-routing.module';

import { BackgroundLandingComponent } from './background-landing.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { SingleSelectComponent } from './components/single-select/single-select.component';
import { TextComponent } from './components/text/text.component';
import { SchoolComponent } from './components/school/school.component';
import { SchoolsComponent } from './components/school/schools.component';
import { MultiSelectOptionComponent } from './components/multi-select/multi-select-option.component';
import { BackgroundItemCategoryComponent } from './containers/background-item-category/background-item-category.component';
import { BackgroundItemHostDirective } from './directives/background-item-host.directive';
import { SchoolHostDirective } from './directives/school-host.directive';

import { BackgroundLandingState } from './store/background-landing.state';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    NgxLoadingModule,
    NgxsModule.forFeature([BackgroundLandingState]),
    TranslateModule,
    FlexLayoutModule,
    BackgroundLandingRoutingModule,
    SharedModule,
  ],
  declarations: [
    BackgroundLandingComponent,
    MultiSelectComponent,
    SingleSelectComponent,
    TextComponent,
    SchoolComponent,
    SchoolsComponent,
    MultiSelectOptionComponent,
    BackgroundItemCategoryComponent,
    BackgroundItemHostDirective,
    SchoolHostDirective,
  ],
  exports: [BackgroundLandingComponent],
  entryComponents: [
    MultiSelectComponent,
    SingleSelectComponent,
    TextComponent,
    SchoolsComponent,
    SchoolComponent,
  ],
})
export class BackgroundLandingModule {
  constructor() {
    // register the component for the form
    Registry.setTypeFor('MultiSelect', MultiSelectComponent);
    Registry.setTypeFor('Schools', SchoolsComponent);
    Registry.setTypeFor('School', SchoolComponent);
    Registry.setTypeFor('SingleSelect', SingleSelectComponent);
    Registry.setTypeFor('Text', TextComponent);
  }
}
