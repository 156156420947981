import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { MatCheckboxChange } from '@angular/material/checkbox';

import { Question, QuestionAnswer } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-welcome-question',
  templateUrl: './survey-welcome-question.component.html',
})
export class SurveyWelcomeQuestionComponent implements OnInit {
  @Input()
  question: Question;

  @Input()
  disabled: boolean;

  @Output()
  welcomeQuestionChange: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  isChecked: boolean = false;

  ngOnInit(): void {
    this.isChecked = this.disabled;
  }

  onQuestionCheckboxChange(value: MatCheckboxChange): void {
    let question: QuestionAnswer = Object.assign(
      {},
      {
        ...this.question,
        previousAnswer: this.question.answer,
        answer: value.checked ? '1' : null,
      }
    );
    this.welcomeQuestionChange.emit(question);
  }
}
