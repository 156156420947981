import {
  Directive,
  AfterViewInit,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
} from '@angular/core';

@Directive({
  selector: '[apFullHeight]',
})
export class FullHeightDirective implements AfterViewInit {
  @Input()
  footer: HTMLElement = null;
  @Input()
  header: HTMLElement = null;
  @Input()
  window: HTMLElement = null;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.resize();
  }

  ngAfterViewInit() {
    // save off the original size so it can be set back if sizing warrants
    this.resize();
  }

  resize(): void {
    // figure out the size using new window size and the top offset
    // this assumes there is some toolbar or something on top of the element
    // const paddingTop =
    //   this.element.nativeElement.style.paddingTop.length === 0
    //     ? 0
    //     : parseInt(this.element.nativeElement.style.paddingTop);

    // const paddingBottom =
    //   this.element.nativeElement.style.paddingTop.length === 0
    //     ? 0
    //     : parseInt(this.element.nativeElement.style.paddingBottom);

    const header = this.header ? this.header.getBoundingClientRect().height : 0;
    const footer = this.footer ? this.footer.getBoundingClientRect().height : 0;
    const windowHeight = this.window
      ? this.window.getBoundingClientRect().height
      : window.innerHeight;

    const innerHeight =
      windowHeight - //this.element.nativeElement.offsetTop +
      // paddingTop +
      // paddingBottom +
      (header + footer);

    this.renderer.setStyle(
      this.element.nativeElement,
      'height',
      `${innerHeight}px`
    );
  }
}
