import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { MatSelectChange } from '@angular/material/select';

import { BackgroundComponent } from '../../background-component';
import {
  BackgroundItem,
  BackgroundResponse,
} from '../../store/background-landing.models';

@Component({
  selector: 'assess-single-select',
  templateUrl: './single-select.component.html',
})
export class SingleSelectComponent implements OnInit, BackgroundComponent {
  @Input()
  backgroundItem: BackgroundItem;
  @Input()
  backgroundResponses: BackgroundResponse[];

  selectedValue: string = null;

  otherFormGroup: FormGroup;
  showOther: boolean = false;

  constructor() {}

  ngOnInit() {
    const response: BackgroundResponse = this.backgroundResponses.find(
      (response: BackgroundResponse) =>
        response.backgroundItemId === this.backgroundItem.backgroundItemId
    );

    let otherResponse: BackgroundResponse;
    if (this.backgroundItem.dependentBackgroundItems.length > 0) {
      otherResponse = this.backgroundResponses.find(
        (response: BackgroundResponse) =>
          response.backgroundItemId ===
          this.backgroundItem.dependentBackgroundItems[0].backgroundItemId
      );
    }

    let other: string = null;

    if (response) {
      this.selectedValue = response.response;
      other =
        response.response === '99999' && otherResponse
          ? otherResponse.response
          : null;
      this.showOther = response.response === '99999';
    }
    this.otherFormGroup = new FormGroup({});
    this.otherFormGroup.addControl('other', new FormControl(other));
  }

  getResponse(): any {
    let response = <BackgroundResponse>{
      backgroundItemId: this.backgroundItem.backgroundItemId,
      response: this.selectedValue,
      dependentResponses:
        this.backgroundItem.dependentBackgroundItems.length > 0
          ? [
              [
                {
                  // add the other no matter what
                  backgroundItemId: this.backgroundItem
                    .dependentBackgroundItems[0].backgroundItemId,
                  response: null,
                  dependentResponses: [],
                },
              ],
            ]
          : [],
    };

    // if other selected then set the value
    if (this.selectedValue === '99999') {
      response.dependentResponses[0][0].response = this.otherTextbox.value;
    }

    return response;
  }

  onItemChange(selection: MatSelectChange): void {
    this.showOther = selection.value === '99999';
    if (selection.value !== '99999') {
      this.otherTextbox.setValue(null);
    }
  }

  get otherTextbox() {
    return this.otherFormGroup.get('other');
  }
}
