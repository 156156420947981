import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HypermediaService } from './hypermedia.service';

@Injectable({
  providedIn: 'root'
})
export class HypermediaFactoryService {
  public constructor(private _httpClient: HttpClient) {}

  public create(apiUrl: string, apiIndex: string): HypermediaService {
    let service: HypermediaService = new HypermediaService(this._httpClient);

    service.apiUrl = apiUrl;
    service.apiIndex = apiIndex;

    return service;
  }
}
