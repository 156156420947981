import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AppVersion } from '../../app-version';

@Injectable()
export class ApiVersionRequestInterceptor implements HttpInterceptor {
  public constructor(private _apiVersion: AppVersion) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf('localhost') > -1 ||
      req.url.indexOf('assessment') > -1
    ) {
      req = req.clone({
        headers: req.headers.set('X-CCL-Caller', this._apiVersion.name)
      });
      req = req.clone({
        headers: req.headers.set(
          'X-CCL-ExpectedApiVersion',
          this._apiVersion.expectedApiVersion
        )
      });
    }
    return next.handle(req);
  }
}
