<div *ngIf="(raterManagement$ | async) as raterManagement" style="height: 100%">
  <div fxLayout fxLayoutAlign="center" style="height: 100%">
    <div fxFlex.lt-md="100" fxFlex="0 0 900px" style="background-color: white; overflow-y:auto; height: 100%;">
      <ngx-loading [show]="showLoading"></ngx-loading>
      <!-- <div style="padding: 20px;">
        <assess-warning-bar *ngIf="warnMoreRaters(raterManagement)" [warning]="'raterManagement.warning' | translate" tabindex="0">  
        </assess-warning-bar>
        <div fxLayout fxLayoutAlign="start center" fxLayoutGap="5px">
          <button mat-icon-button color="primary" (click)="onBack()"><i
              class="fa fa-arrow-circle-left fa-2x" aria-label="Back to main page"></i></button>
          <span class="mat-title compressed" [translate]="'raterManagement.header.title'" tabindex="0"
            [translateParams]="{ name: raterManagement.instrument.name }"></span>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxHide.lt-md="true" class="text-body mt-1" tabindex="0">{{'raterManagement.header.instructions.addRaters' |
            translate}}</div>
          <a [routerLink]="" class="mat-body mt-1" (click)="onLearnMoreClick()">{{'raterManagement.header.instructions.link'
            | translate}}</a>
          <div fxLayout fxLayoutAlign="space-between start" class="text-body mt-1">
            <div>
              <div (click)="toggle()" class="expansion" tabindex="0" (keypress)="toggle()">
                {{'raterManagement.header.reminderSchedule.title' | translate}}
                <i class="fa fa-angle-down" [@disclosureState]='state' aria-hidden="true"></i>
              </div>
              <div [@collapsedState]='state' style="margin-left: 10px;">
                <div
                  *ngIf="raterManagement.program.reminderSchedule && raterManagement.program.reminderSchedule.length > 0 else noSchedule">
                  <div *ngFor="let reminderSchedule of raterManagement.program.reminderSchedule">
                    {{reminderSchedule.reminderDate | localizedDate:'fullDate'}}
                  </div>
                </div>
                <ng-template #noSchedule tabindex="0">
                  {{'raterManagement.header.reminderSchedule.noSchedule' | translate}}
                </ng-template>
              </div>
            </div>
            <div>
              <button mat-raised-button color="primary"
                *ngIf="!raterManagement.allSurveysReceived && raterManagement.totalRaterInvitedCount > 0"
                (click)="onRemindAllRatersClick()" name="Remind all raters">
                {{'raterManagement.buttons.remindAll' | translate}}
              </button>
            </div>
          </div>
        </div>
        <assess-rater-management-rater-card *ngFor="let raterStatus of raterManagement.raterStatuses"
          [raterStatus]="raterStatus" (addRatersClick)="onAddRatersClick($event)"
          (deleteRaterClick)="onDeleteRaterClick($event)" (remindRatersClick)="onRemindRatersClick($event)"
          (resendInvitationClick)="onResendInvitationClick($event)"></assess-rater-management-rater-card>
      </div> -->
    </div>
  </div>
</div>