import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';

import { Observable, Subscription } from 'rxjs';

import { Select, Store, Actions, ofActionSuccessful } from '@ngxs/store';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';

import { SurveyDocumentState } from '../../store/survey.state';
import { TabbedSectionState } from '../../store/tabbed-section.state';
import { TabGroup } from '../../store/tabbed-section.model';
import { AnswerQuestions } from '../../store/survey.actions';

import {
  SurveySection,
  QuestionGroup,
  QuestionAnswer,
} from '../../store/survey.model';

import {
  SetTabIndex,
  SetQuestionGroups,
  SetQuestionGroupsSuccess,
  UpdateTabbedSection,
  BuildTabbedSection,
  ChangeTab,
  NextGroup,
  PreviousGroup,
} from '../../store/tabbed-section.actions';

@Component({
  selector: 'assess-survey-a-or-b-questions',
  templateUrl: './survey-a-or-b-questions.component.html',
})
export class SurveyAOrBQuestionsComponent implements OnInit, OnDestroy {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  @Select(TabbedSectionState.getTabs)
  tabs$: Observable<TabGroup[]>;

  @Select(TabbedSectionState.getTabIndex)
  selectedIndex$: Observable<number>;

  @Select(TabbedSectionState.getQuestionGroups)
  currentQuestionGroups$: Observable<QuestionGroup[]>;

  @Select(TabbedSectionState.getFirstGroup)
  firstGroup$: Observable<boolean>;

  @Select(TabbedSectionState.getLastGroup)
  lastGroup$: Observable<boolean>;

  private _currentTabIndex: number = -1;
  private _setQuestionGroupsSuccessSubscription: Subscription;
  private _answerQuestionsSubscription: Subscription;
  private _setTabIndexSubscription: Subscription;

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _scrollToService: ScrollToService
  ) {}

  ngOnInit(): void {
    this._setTabIndexSubscription = this._actions$
      .pipe(ofActionSuccessful(SetTabIndex))
      .subscribe((setTabIndex: SetTabIndex) => {
        this._currentTabIndex = setTabIndex.tabIndex;
        this._store.dispatch(new SetQuestionGroups());
      });

    this._setQuestionGroupsSuccessSubscription = this._actions$
      .pipe(ofActionSuccessful(SetQuestionGroupsSuccess))
      .subscribe(() => {
        const scrollTarget: number = this._store.selectSnapshot(
          SurveyDocumentState.getScrollTarget
        );
        setTimeout(() => {
          const config: ScrollToConfigOptions = {
            target: scrollTarget,
          };
          this._scrollToService.scrollTo(config);
        }, 250);
      });

    this._answerQuestionsSubscription = this._actions$
      .pipe(ofActionSuccessful(AnswerQuestions))
      .subscribe(() => {
        this._store.dispatch(new UpdateTabbedSection());
      });

    this._store.dispatch(new BuildTabbedSection());
  }

  ngOnDestroy(): void {
    this._setQuestionGroupsSuccessSubscription.unsubscribe();
    this._answerQuestionsSubscription.unsubscribe();
    this._setTabIndexSubscription.unsubscribe();
  }

  onSelectedTabChange(value: MatTabChangeEvent): void {
    if (this._currentTabIndex !== value.index) {
      this._currentTabIndex = value.index;
      this._store.dispatch(new ChangeTab(value.index));
    }
  }

  nextGroup(): void {
    this._store.dispatch(new NextGroup());
  }

  previousGroup(): void {
    this._store.dispatch(new PreviousGroup());
  }

  onQuestionAnswered(answers: QuestionAnswer[]): void {
    const questionAnswers: QuestionAnswer[] = [];

    answers.forEach((answer: QuestionAnswer) => {
      questionAnswers.push({
        formItemId: answer.formItemId,
        previousAnswer: answer.previousAnswer,
        answer: answer.answer,
        optional: answer.optional,
        formItemSequenceNumber: answer.formItemSequenceNumber,
        answeredTimeMs: new Date().getTime(),
      });
    });
    this._store.dispatch(new AnswerQuestions(questionAnswers));
  }
}
