<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column">
      <assess-survey-section-title>
        <!-- tabs -->
        <div fxFlexAlign="center">
          <mat-tab-group class="narrow" (selectedTabChange)="onSelectedTabChange($event)"
            [selectedIndex]="selectedIndex$ | async">
            <mat-tab *ngFor="let tab of (tabs$ | async)">
              <ng-template mat-tab-label>
                <span [ngClass]="{'all-answered': tab.allAnswered}">{{tab.tabText}}</span>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <!-- end tabs -->
      </assess-survey-section-title>
      <div fxFlex style="background-color: white; overflow-y: auto;">
        <assess-survey-a-and-b-questions-group *ngFor="let questionGroup of (currentQuestionGroups$ | async)"
          [questionGroup]="questionGroup" (questionAnswered)="onQuestionAnswered($event)">
        </assess-survey-a-and-b-questions-group>
      </div>
      <div class="survey button-panel" fxLayout="row" fxLayoutAlign="space-between center">
        <button *ngIf="!(firstGroup$ | async) else previousSection" mat-raised-button apNoDblClickMat enableAfter="500"
          color="primary" (click)="previousGroup()">
          <span fxLayout fxLayoutAlign="space-between center">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span style="padding-left: 10px;">{{surveySection.previousGroupButtonText}}</span>
          </span>
        </button>
        <button *ngIf="!(lastGroup$ | async) else nextSection" mat-raised-button apNoDblClickMat enableAfter="500"
          color="primary" (click)="nextGroup()">
          <span fxLayout fxLayoutAlign="space-between center">
            <span style="padding-right: 10px;">{{surveySection.nextGroupButtonText}}</span>
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </span>
        </button>
        <ng-template #previousSection>
          <assess-survey-previous-step-button [surveySection]="surveySection"></assess-survey-previous-step-button>
        </ng-template>
        <ng-template #nextSection>
          <assess-survey-next-step-button [surveySection]="surveySection"></assess-survey-next-step-button>
        </ng-template>
      </div>
    </div>
  </div>
</div>