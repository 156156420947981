import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

import { MatRadioChange } from '@angular/material/radio';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  QuestionAnswer,
  QuestionListItem,
} from '../../store/survey.model';
import { SurveyDocumentState } from '../../store/survey.state';

@Component({
  selector: 'assess-survey-a-or-b-question',
  templateUrl: './survey-a-or-b-question.component.html',
})
export class SurveyAOrBQuestionComponent implements OnInit, OnDestroy {
  @Input()
  question: Question;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer[]> = new EventEmitter<
    QuestionAnswer[]
  >();

  questionListItem$: Observable<QuestionListItem>;

  answer: string = null;
  isAnswered: boolean = false;

  private _questionListItemSubscription: Subscription;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    // MBTI-Q type
    if (this.question.formItemId > 0) {
      this.questionListItem$ = this._store
        .select(SurveyDocumentState.getQuestionAnswer)
        .pipe(map((filterFn) => filterFn(this.question.formItemId)));

      this._questionListItemSubscription = this.questionListItem$.subscribe(
        (questionListItem: QuestionListItem) => {
          this.answer = questionListItem.answer;
          this.isAnswered =
            questionListItem.answer && questionListItem.answer !== null;
        }
      );
    } else {
      // ISI type
      // go get the answer for the part A
      // since there is an answer for part A & B we only need Part A to drive the overall answer
      this.questionListItem$ = this._store
        .select(SurveyDocumentState.getQuestionAnswer)
        .pipe(map((filterFn) => filterFn(this.question.options[0].formItemId)));

      this._questionListItemSubscription = this.questionListItem$.subscribe(
        (questionListItem: QuestionListItem) => {
          this.answer = !questionListItem.answer
            ? null
            : questionListItem.answer === '1'
            ? '0'
            : '1';
          this.isAnswered =
            questionListItem.answer && questionListItem.answer !== null;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this._questionListItemSubscription.unsubscribe();
  }

  onOptionChanged(event: MatRadioChange): void {
    this.isAnswered = event.value !== null;

    // MBTI-Q type
    if (this.question.formItemId > 0) {
      let surveyQuestion: QuestionAnswer = Object.assign(
        {},
        {
          ...this.question,
          previousAnswer: this.question.answer,
          answer: event.value,
        }
      );
      this.questionAnswered.emit([surveyQuestion]);
    } else {
      // ISI type
      let surveyQuestionA: QuestionAnswer = {
        formItemId: this.question.options[0].formItemId,
        formItemSequenceNumber: this.question.options[0].formItemSequenceNumber,
        optional: this.question.optional,
        previousAnswer: this.answer,
        answer: event.value === '0' ? '1' : '0',
      };
      let surveyQuestionB: QuestionAnswer = {
        formItemId: this.question.options[1].formItemId,
        formItemSequenceNumber: this.question.options[1].formItemSequenceNumber,
        optional: this.question.optional,
        previousAnswer: this.answer,
        answer: event.value === '1' ? '1' : '0',
      };

      // save the current value
      this.answer = event.value;

      this.questionAnswered.emit([surveyQuestionA, surveyQuestionB]);
    }
  }
}
