import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogPublisher } from '../../log-publisher';
import { LogEntriesConfig } from './log-entries-log.model';
import { LogEntry } from '../../log.model';

export class LogEntriesLogPublisher extends LogPublisher {
  constructor(
    private _config: LogEntriesConfig,
    private _httpClient: HttpClient
  ) {
    super();
    this.location = _config.url;
  }

  log(entry: LogEntry): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this._httpClient
      .post<any>(
        `${this.location}/${this._config.token}`,
        entry.toString(),
        httpOptions
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Log Entries returned code ${error.status}, ` +
          `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Insert into Log entries failed.');
  }
}
