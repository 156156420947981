import { Injectable } from '@angular/core';

import * as Auth0 from 'auth0-js';

@Injectable()
export class AuthService {
  constructor() {}

  private _auth0: Auth0.WebAuth;

  public login(
    grandCentralUrl: string,
    grandCentralLoginApplicationId: string,
    grandCentralReturnUrl: string
  ): void {
    window.location.href = `${grandCentralUrl}/client-login/?loginApplicationId=${grandCentralLoginApplicationId}&returnURL=${grandCentralReturnUrl}`;
  }

  public logout(
    authClientId: string,
    authDomain: string,
    grandCentralLoginApplicationId: string,
    grandCentralReturnUrl: string
  ): void {
    // Remove token from localStorage
    localStorage.removeItem('token');

    // setup Auth0
    this._auth0 = new Auth0.WebAuth({
      domain: authDomain,
      clientID: authClientId,
      scope: `openid profile loginApplicationId-${grandCentralLoginApplicationId}`,
      responseType: 'token id_token',
    });

    this._auth0.logout({
      returnTo: grandCentralReturnUrl,
    });
  }
}
