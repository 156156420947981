import {
  SurveyDocument,
  ComponentDescriptor,
  SurveySection,
  QuestionAnswer,
  Participant,
  RaterType,
  NavigationEvent,
  SurveyAnswer,
  QuestionListItem,
} from './survey.model';

import { AssessmentLinkInfo } from '../../participant-landing/models/participant.model';

// actions
export class StartSurvey {
  static readonly type = '[Survey] Start Survey';
  constructor(
    public instrumentId: number,
    public surveyTypeId: number,
    public languageId: number,
    public participantId: number,
    public individualId: number,
    public instrumentTypeId?: number,
    public assessmentLinkInfo?: AssessmentLinkInfo
  ) {}
}

export class BuildComponentDescriptors {
  static readonly type = '[Component Descriptors] Build Component Descriptors';
}

export class BuildQuestionList {
  static readonly type = '[Question List] Build Question List';
}

export class BuildSurveySections {
  static readonly type = '[Survey Sections] Build Survey Sections';
}

export class Navigation {
  static readonly type = '[Survey Sections] Navigation';
  constructor(public navigationEvent: NavigationEvent) {}
}

export class SetScrollTarget {
  static readonly type = '[Survey Sections] Set Scroll Target';
  constructor(public scrollTarget: number) {}
}

export class JumpTo {
  static readonly type = '[Survey Menu] Jump To';
  constructor(public sectionId: string) {}
}

export class ReviewSection {
  static readonly type = '[Survey Menu] Jump To';
  constructor(public sectionId: string) {}
}

export class ReviewQuestion {
  static readonly type = '[Survey Review] Jump To Question';
  constructor(public sectionId: string, public formItemId: number) {}
}

export class AnswerQuestions {
  static readonly type = '[Survey] Answer Questions';
  constructor(public answers: QuestionAnswer[]) {}
}

export class ExitSurvey {
  static readonly type = '[Survey Sections] Exit Survey';
}

export class SubmitSurvey {
  static readonly type = '[Survey] Submit Survey';
}

export class UserNotFound {
  static readonly type = '[Survey] User Not Found';
}

export class RefreshAnswers {
  static readonly type = '[Survey] Refresh Answers';
}

export class RefreshAnswersSuccess {
  static readonly type = '[Survey] Refresh Answers Success';
  constructor(public surveyAnswers: SurveyAnswer[]) {}
}

// events
export class StartSurveySuccess {
  static readonly type = '[Survey] Start Survey Success';
  constructor(
    public surveyDocument: SurveyDocument,
    public surveyAnswers: SurveyAnswer[],
    public participant: Participant,
    public raterType: RaterType
  ) {}
}

export class BuildComponentDescriptorsSuccess {
  static readonly type =
    '[Component Descriptors] Build Component Descriptors Success';
  constructor(public componentDescriptors: ComponentDescriptor[]) {}
}

export class BuildQuestionListSuccess {
  static readonly type = '[Question List] Build Question List Success';
  constructor(public questionList: QuestionListItem[]) {}
}

export class BuildSurveySectionsSuccess {
  static readonly type = '[Survey Sections] Build Survey Sections Success';
  constructor(public surveySections: SurveySection[]) {}
}

export class AnswerQuestionsSuccess {
  static readonly type = '[Survey] Answer Questions Success';
  constructor(public answers: QuestionAnswer[]) {}
}

export class SubmitSurveySuccess {
  static readonly type = '[Survey] Submit Survey Success';
}

export class ChangeLanguage {
  static readonly type = '[Survey] Change Language';
  constructor(public newLanguage: number) {}
}

export class ChangeLanguageSuccess {
  static readonly type = '[Survey] Change Language Success';
  constructor(
    public surveyDocument: SurveyDocument,
    public participant: Participant,
    public raterType: RaterType
  ) {}
}
