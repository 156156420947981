import { Component, Inject, OnInit, OnDestroy } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { Idle } from '@ng-idle/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'assess-session-ending-dialog',
  templateUrl: './session-ending-dialog.component.html',
})
export class SessionEndingDialogComponent implements OnInit, OnDestroy {
  timeLeft: string;

  timerSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SessionEndingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _idle: Idle,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.timeLeft = this._translateService.instant(
      'dialog.sessionTimeout.warning',
      {
        timeLeft: this.fmtMSS(this._idle.getTimeout()),
      }
    );
    this.timerSubscription = this._idle.onTimeoutWarning.subscribe(
      (count: number) => {
        this.timeLeft = this._translateService.instant(
          'dialog.sessionTimeout.warning',
          {
            timeLeft: this.fmtMSS(count),
          }
        );
        if (count === 1) {
          this.dialogRef.close();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  private fmtMSS(s): string {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  }
}
