import { Component, Input } from '@angular/core';

@Component({
  selector: 'assess-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: ['./warning-bar.component.scss']
})
export class WarningBarComponent {
  @Input() warning: string;
}
