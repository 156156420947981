import { Type } from '@angular/core';

const Registry = new Map<string, Type<any>>();

export function getTypeFor(name: string): Type<any> {
  return Registry.get(name);
}

export function setTypeFor(name: string, type: Type<any>): void {
  Registry.set(name, type);
}
