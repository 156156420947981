import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HypermediaService } from '../../core/hypermedia/hypermedia.service';
import { HypermediaFactoryService } from '../../core/hypermedia/hypermedia-factory.service';

import { Store } from '@ngxs/store';
import {
  Config,
  Language,
  Application,
  ApplicationMessage
} from '../../store/application.models';

interface ApplicationResponse {
  languages: Language[];
  applicationMessage: ApplicationMessage;
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private _hypermediaService: HypermediaService = null;

  public constructor(
    private _store: Store,
    private _hypermediaFactoryService: HypermediaFactoryService
  ) {}

  initializeService(): void {
    if (this._hypermediaService === null) {
      const config: Config = this._store.selectSnapshot(
        state => state.application.config
      );

      this._hypermediaService = this._hypermediaFactoryService.create(
        config.assessmentWebApi,
        'application'
      );
    }
  }

  getApplication(preferredLanguageId: number): Observable<Application> {
    this.initializeService();
    return this._hypermediaService
      .get('get', { preferredLanguageId: preferredLanguageId })
      .pipe(map((r: ApplicationResponse) => Object.assign({}, r)));
  }
}
