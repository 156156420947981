import { Injectable } from '@angular/core';

import { Question, QuestionAnswer } from '../store/survey.model';

@Injectable({
  providedIn: 'root'
})
export class DirectFeedbackAnswerService {
  private _answers: QuestionAnswer[] = [];

  get answers(): QuestionAnswer[] {
    return this._answers.filter(
      (questionAnswer: QuestionAnswer) =>
        questionAnswer.answer !== questionAnswer.previousAnswer
    );
  }

  clear(): void {
    this._answers = [];
  }

  upsertAnswer(newAnswer: QuestionAnswer): void {
    const index: number = this._answers.findIndex(
      (answer: Question) => answer.formItemId === newAnswer.formItemId
    );

    if (index === -1) {
      this._answers.push(newAnswer);
    } else {
      this._answers.splice(index, 1, newAnswer);
    }
  }
}
