<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column" style="height: 100%;">
      <assess-survey-section-title></assess-survey-section-title>
      <assess-survey-additional-instructions [additionalInstructions]="surveySection.additionalInstructions">
      </assess-survey-additional-instructions>
      <div fxFlex style="background-color: white; overflow-y: auto;">
        <assess-survey-direct-feedback-question *ngFor="let question of surveySection.questionGroups[0].questions"
          [question]="question" (directFeedbackChanged)="onDirectFeedbackChanged($event)">
        </assess-survey-direct-feedback-question>
      </div>
      <div class="survey button-panel" fxLayout="row" fxLayoutAlign="space-between center">
        <assess-survey-previous-step-button [surveySection]="surveySection"></assess-survey-previous-step-button>
        <assess-survey-next-step-button [surveySection]="surveySection"></assess-survey-next-step-button>
      </div>
    </div>
  </div>
</div>