import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  QuestionAnswer,
  QuestionListItem,
  DependentOption,
  Option,
} from '../../../../store/survey.model';
import { SurveyDemographicsService } from '../../services/survey-demographics.service';
import { SurveyDocumentState } from '../../../../store/survey.state';

@Component({
  selector: 'assess-single-option',
  templateUrl: './single-option.component.html',
})
export class SingleOptionComponent implements OnInit, OnDestroy {
  @Input() question: Question;
  @Input() index: number;

  questionListItem$: Observable<QuestionListItem>;
  questionListItemOther$: Observable<QuestionListItem>;

  otherFormGroup: FormGroup;
  showOther: boolean = false;

  private _dependentOption: DependentOption;

  private _lastValue: string;
  private _lastOtherValue: string;

  private _questionListItemCheckboxSubscription: Subscription;
  private _questionListItemOtherSubscription: Subscription;

  constructor(
    private _store: Store,
    private _surveyDemographicsService: SurveyDemographicsService
  ) {}

  ngOnInit(): void {
    if (this.question.options) {
      let option: Option = this.question.options.find(
        (option: Option) => option.dependentOption !== null
      );
      this._dependentOption = option ? option.dependentOption : null;
    }

    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.question.formItemId)));

    this.questionListItemOther$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(
        map((filterFn) =>
          filterFn(this._dependentOption ? this._dependentOption.formItemId : 0)
        )
      );

    this.otherFormGroup = new FormGroup({});
    this.otherFormGroup.addControl('option', new FormControl(null));
    this.otherFormGroup.addControl(
      'other',
      new FormControl(null, { updateOn: 'blur' })
    );

    this._questionListItemCheckboxSubscription = this.questionListItem$.subscribe(
      (questionListItem: QuestionListItem) => {
        this.optionRadioGroup.setValue(questionListItem.answer, {
          emitEvent: false,
        });
        this.showOther =
          this._dependentOption &&
          questionListItem.answer === this._dependentOption.value;
      }
    );

    this._questionListItemOtherSubscription = this.questionListItemOther$.subscribe(
      (questionListItem: QuestionListItem) => {
        const other = this._dependentOption ? questionListItem.answer : null;

        this.otherTextbox.setValue(other, { emitEvent: false });
      }
    );

    this.otherTextbox.valueChanges
      .pipe(
        map((value: any) => {
          const questionAnswer: QuestionAnswer = {
            formItemId: this._dependentOption.formItemId,
            optional: true,
            formItemSequenceNumber: this._dependentOption
              .formItemSequenceNumber,
            answeredTimeMs: new Date().getTime(),
            answer: value,
            previousAnswer: this._lastOtherValue,
          };
          this._lastOtherValue = value;

          this._surveyDemographicsService.answerQuestion(questionAnswer);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._questionListItemCheckboxSubscription.unsubscribe();
    this._questionListItemOtherSubscription.unsubscribe();
  }

  onOptionChanged(event: MatRadioChange): void {
    this.showOther =
      this._dependentOption && this._dependentOption.value === event.value;

    let answer: QuestionAnswer = Object.assign(
      {},
      {
        ...this.question,
        answer: event.value,
        previousAnswer: this._lastValue,
      }
    );
    this._lastValue = event.value;
    this._surveyDemographicsService.answerQuestion(answer);

    if (
      this._dependentOption &&
      this._dependentOption.value !== event.value &&
      this.otherTextbox.value !== null
    ) {
      this.otherTextbox.setValue(null);
    }
  }

  get optionRadioGroup() {
    return this.otherFormGroup.get('option');
  }

  get otherTextbox() {
    return this.otherFormGroup.get('other');
  }
}
