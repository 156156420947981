import { Component, Input, Output, EventEmitter } from '@angular/core';

import { QuestionGroup, QuestionAnswer } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-a-or-b-questions-group',
  templateUrl: './survey-a-or-b-questions-group.component.html'
})
export class SurveyAOrBQuestionsGroupComponent {
  @Input()
  questionGroup: QuestionGroup;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer[]> = new EventEmitter<
    QuestionAnswer[]
  >();

  onQuestionAnswered(questions: QuestionAnswer[]): void {
    this.questionAnswered.emit(questions);
  }
}
