import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  SurveySection,
  QuestionAnswer,
  Extension,
} from '../../store/survey.model';
import { AnswerQuestions } from '../../store/survey.actions';
import { SurveyDocumentState } from '../../store/survey.state';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'assess-survey-competencies-questions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-competencies-questions.component.html',
})
export class SurveyCompetenciesQuestionsComponent implements OnInit {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  @Select(SurveyDocumentState.getSurveySectionQuestionsAnswered)
  questionsSelected$: Observable<number>;

  @Select(SurveyDocumentState.getNumberAnswersRequired)
  numberRequired$: Observable<number>;

  complete$: Observable<any>;

  extensions: Extension[];

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.complete$ = this._store
      .select(SurveyDocumentState.sectionComplete)
      .pipe(switchMap((complete) => complete));

    this.extensions = this._store.selectSnapshot(
      SurveyDocumentState.getSurveySectionExtensions
    );
  }

  onCompetencyQuestionChange(answer: QuestionAnswer): void {
    const questionAnswer: QuestionAnswer = {
      formItemId: answer.formItemId,
      previousAnswer: answer.previousAnswer,
      answer: answer.answer,
      optional: answer.optional,
      formItemSequenceNumber: answer.formItemSequenceNumber,
      answeredTimeMs: new Date().getTime(),
    };
    this._store.dispatch(new AnswerQuestions([questionAnswer]));
  }

  getExtensionValue(name: string): string {
    const extension: Extension = this.extensions.find(
      (extension: Extension) => extension.name === name
    );

    return extension ? extension.value : null;
  }
}
