import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  SurveySection,
  Survey,
  Participant,
  RaterType,
  Extension,
} from '../../store/survey.model';

import { SurveyDocumentState } from '../../store/survey.state';
import { ApplicationState } from '../../../../store/application.state';
import { User } from '../../../../core/auth/user.model';

@Component({
  selector: 'assess-survey-section-info-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-section-info-dialog.component.html',
})
export class SurveySectionInfoDialogComponent implements OnInit {
  @Select(ApplicationState.getUser)
  user$: Observable<User>;

  @Select(SurveyDocumentState.getSurvey)
  survey$: Observable<Survey>;

  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  extensions: Extension[];

  ratingAs: string;

  constructor(
    public dialogRef: MatDialogRef<SurveySectionInfoDialogComponent>,
    private _store: Store
  ) {}

  ngOnInit(): void {
    const raterType: RaterType = this._store.selectSnapshot(
      SurveyDocumentState.getRaterType
    );

    if (raterType) {
      const extensions: Extension[] = this._store.selectSnapshot(
        SurveyDocumentState.getSurveyExtensions
      );
      const participant: Participant = this._store.selectSnapshot(
        SurveyDocumentState.getParticipant
      );

      const parameters: any = {
        participantFirstName: participant.firstName,
        participantLastName: participant.lastName,
        raterType: raterType.name,
      };

      const ratingAsExtension = extensions.find(
        (extension: Extension) => extension.name === 'ratingAs'
      );

      this.ratingAs = this.resolveTemplate(
        ratingAsExtension ? ratingAsExtension.value : null,
        parameters
      );
    }
  }

  private resolveTemplate(template: string, values: any): string {
    if (template === null) {
      return null;
    }

    let resolved: string = template;

    Object.keys(values).forEach((key: string) => {
      resolved = resolved.replace(`<<${key}>>`, values[key]);
    });

    return resolved;
  }
}
