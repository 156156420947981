import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Subscription, Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../core/auth/auth.service';
import { Config } from '../../store/application.models';
import { ApplicationState } from '../../store/application.state';
import { User } from '../../core/auth/user.model';
import { InformationDialogComponent } from '../../shared/dialogs/information-dialog/information-dialog.component';
import { FaqDialogComponent } from '../../shared/dialogs/faq-dialog/faq-dialog.component';

@Component({
  selector: 'assess-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(ApplicationState.getUser)
  user$: Observable<User>;

  userInitials: string;

  private _config: Config;
  private _user: User;

  private _configSubscription: Subscription = null;
  private _userSubscription: Subscription = null;

  constructor(
    private _dialog: MatDialog,
    private _store: Store,
    private _authService: AuthService,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._configSubscription = this._store
      .select(ApplicationState.getConfig)
      .subscribe((config: Config) => (this._config = config));

    this._userSubscription = this._store
      .select(ApplicationState.getUser)
      .subscribe((user: User) => {
        this._user = user;
        if (this._user) {
          const first = this._user.firstName
            ? this._user.firstName.substring(0, 1)
            : '';
          const last = this._user.lastName
            ? this._user.lastName.substring(0, 1)
            : '';
          this.userInitials = `${first}${last}`;
        }
      });
  }

  ngOnDestroy(): void {
    if (this._configSubscription !== null) {
      this._configSubscription.unsubscribe();
    }
    if (this._userSubscription !== null) {
      this._userSubscription.unsubscribe();
    }
  }

  onContactUs(): void {
    this._dialog.open(InformationDialogComponent, {
      width: '700px',
      data: {
        title: this._translateService.instant('dialog.contactUs.title'),
        question: this._translateService.instant('dialog.contactUs.text'),
        okButton: this._translateService.instant('dialog.ok'),
      },
    });
  }

  onFaq(): void {
    const questionsText: string = this._translateService.instant(
      'dialog.faq.text'
    );
    const questions: any = JSON.parse(questionsText);

    this._dialog.open(FaqDialogComponent, {
      width: '700px',
      data: {
        title: this._translateService.instant('dialog.faq.title'),
        questions: questions,
        okButton: this._translateService.instant('dialog.ok'),
      },
    });
  }

  onMyProfile(): void {
    window.location.href = `${this._config.assessments2Uri}/profile`;
   // this._store.dispatch(new Navigate(['/my-profile']));
  }

  onLogoClick(): void {
    window.location.href = `${this._config.assessments2Uri}`;
  }

  onPrivacyPolicy(): void {
    if (this._user.preferredLanguageCultureName === 'en-US') {
      window.open('https://www.ccl.org/ccl-privacy-policy', '_blank');
    } else {
      window.open(
        `https://www.ccl.org/ccl-privacy-policy/${this._user.preferredLanguageCultureName}/`,
        '_blank'
      );
    }
  }

  onLogout(): void {
    let instArray = JSON.parse(localStorage.getItem("instrumentArray")) || [];
    if (instArray.length > 0) {
      localStorage.setItem("instrumentArray", JSON.stringify([]));
      this._authService.logout(
        this._config.assessments2ClientId,
        this._config.authDomain,
        this._config.assessments2ApplicationId,
        this._config.assessments2Uri
      );
    }
    else {
      this._authService.logout(
        this._config.authClientId,
        this._config.authDomain,
        this._config.grandCentralLoginApplicationId,
        this._config.grandCentralReturnUrl
      );
    }   
  }
}
