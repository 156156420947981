<div fxLayout fxLayoutAlign="center" style="height: 100%;">
  <div fxFlex.lt-md="100" fxFlex="0 0 900px" style="background-color: white; overflow-y:auto; height: 100%;">
    <ngx-loading [show]="showLoading"></ngx-loading>
    <div style="padding: 20px;">
      <div fxLayout fxLayoutAlign="start center" fxLayoutGap="5px" class="mb-2">
        <button mat-icon-button color="primary" (click)="onBack()"><i
            class="fa fa-arrow-circle-left fa-2x" aria-label="Back to main page"></i></button>
        <span class="mat-title compressed" [translate]="'appMenu.myProfile'" tabindex="0"></span>
      </div>
      <form novalidate [formGroup]="myProfileGroup">
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.prefix' | translate"
            formControlName="prefix">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.firstName' | translate"
            formControlName="firstName">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.middleInitials' | translate"
            formControlName="middleInitials">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.lastName' | translate"
            formControlName="lastName">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.preferredName' | translate"
            formControlName="preferredName">
        </mat-form-field>
        <!-- E-mail (not editable - for now) -->
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.businessPhone' | translate"
            formControlName="businessPhone">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.mobilePhone' | translate"
            formControlName="mobilePhone">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput autocomplete="nope" [placeholder]="'myProfile.placeholder.jobTitle' | translate"
            formControlName="jobTitle">
        </mat-form-field>
        <mat-form-field>
          <mat-select [placeholder]="'myProfile.placeholder.preferredApplicationLanguage' | translate"
            formControlName="preferredApplicationLanguage">
            <mat-option *ngFor="let language of languages" [value]="language">{{language.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div fxLayout fxLayoutAlign="end" style="margin-right: 20px;margin-bottom: 20px;">
        <button mat-raised-button color="primary" (click)="save()" [disabled]="saving || myProfileGroup.invalid">
          <span *ngIf="!saving">{{'myProfile.button.save' | translate}}</span>
          <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
        </button>
      </div>
    </div>
  </div>
</div>