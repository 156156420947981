import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

import { Engine } from 'json-rules-engine';

import {
  SectionReviewCard,
  Extension,
  Question,
} from '../../store/survey.model';

@Component({
  selector: 'assess-survey-review-question',
  templateUrl: './survey-review-question.component.html',
})
export class SurveyReviewQuestionComponent implements OnInit {
  @Input()
  sectionReviewCard: SectionReviewCard;
  @Input()
  extensions: Extension[];

  @Output()
  sectionReviewCardClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  questionNumberClicked: EventEmitter<any> = new EventEmitter<any>();

  completed: boolean = true;
  itemsToComplete: number;
  notObserved: boolean = false;

  constructor(private _changeDectectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.sectionReviewCard.optional) {
      let engine = new Engine();

      engine.on('notComplete', (event, almanac, ruleResult) => {
        let conditions = ruleResult.conditions.any
          ? ruleResult.conditions.any
          : ruleResult.conditions.all;

        // This code is running in a promise that is outside
        // angular's change detection so it needs to be called
        // manually after the update.
        conditions
          .filter((condition) => condition.result)
          .map((condition) => {
            switch (condition.fact) {
              case 'numberAnswered':
                {
                  this.itemsToComplete =
                    condition.value - this.sectionReviewCard.numberAnswered;
                }
                break;
              case 'notObservedExceeded':
                {
                  this.notObserved = true;
                }
                break;
            }
          });
        this.completed = false;
        this._changeDectectorRef.detectChanges();
      });

      engine.addRule(this.sectionReviewCard.validationRule);
      engine.run({
        numberAnswered: this.sectionReviewCard.numberAnswered,
        notObservedExceeded: this.sectionReviewCard.notObservedAnswered,
      });
    }
  }

  getExtensionValue(name: string): string {
    const extension: Extension = this.extensions.find(
      (extension: Extension) => extension.name === name
    );

    return extension ? extension.value : null;
  }

  onQuestionNumberClicked(question: Question): void {
    this.questionNumberClicked.emit({
      sectionId: this.sectionReviewCard.sectionId,
      formItemId: question.formItemId,
    });
  }

  onSectionReviewCardClicked(): void {
    this.sectionReviewCardClicked.emit(this.sectionReviewCard.sectionId);
  }
}
