import { Component, Input, QueryList, ViewChildren } from '@angular/core';

import { BackgroundComponent } from '../../background-component';
import {
  BackgroundItem,
  BackgroundResponse,
} from '../../store/background-landing.models';
import { MultiSelectOptionComponent } from './multi-select-option.component';

@Component({
  selector: 'assess-multi-select',
  templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent implements BackgroundComponent {
  @ViewChildren(MultiSelectOptionComponent) optionComponents: QueryList<
    MultiSelectOptionComponent
  >;

  @Input()
  backgroundItem: BackgroundItem;
  @Input()
  backgroundResponses: BackgroundResponse[];

  questionNumber: number;

  constructor() {}

  getResponse(): any {
    let responses: string[] = [];
    let dependentResponses: BackgroundResponse[][] =
      this.backgroundItem.dependentBackgroundItems.length > 0
        ? [
            [
              {
                // add the other no matter what
                backgroundItemId: this.backgroundItem
                  .dependentBackgroundItems[0].backgroundItemId,
                response: null,
                dependentResponses: [],
              },
            ],
          ]
        : [];

    this.optionComponents.forEach(
      (optionComponent: MultiSelectOptionComponent) => {
        let response = optionComponent.getResponse();
        if (response) {
          responses.push(response.value);
          if (response.value === '99999') {
            dependentResponses[0][0].response = response.other;
          }
        }
      }
    );

    console.log('responses: ', responses);

    if (responses.length > 0) {
      return responses.map((response: string, index: number) => {
        return {
          backgroundItemId: this.backgroundItem.backgroundItemId,
          response: response,
          dependentResponses:
            // if response is Other then add the Other answer to this background item
            response === '99999'
              ? dependentResponses
              : index === responses.length - 1 // if Other never encountered and we have gone through all the responses then add a Blank other to last response so the report will be happy.
              ? dependentResponses
              : [],
        };
      });
    } else {
      return {
        backgroundItemId: this.backgroundItem.backgroundItemId,
        response: null,
        dependentResponses: dependentResponses,
      };
    }
  }
}
