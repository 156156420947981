<div *ngIf="(currentSurveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column">
      <assess-survey-section-title></assess-survey-section-title>
      <div fxFlex style="background-color: white; overflow-y: auto;">
        <assess-survey-demographics-questions-container [questionGroups]="surveySection.questionGroups"></assess-survey-demographics-questions-container>
      </div>
      <div class="survey button-panel" fxLayout="row" fxLayoutAlign="space-between center" #buttonPanel>
        <assess-survey-previous-step-button [surveySection]="surveySection"></assess-survey-previous-step-button>
        <assess-survey-next-step-button [surveySection]="surveySection"></assess-survey-next-step-button>
      </div>
    </div>
  </div>