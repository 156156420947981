// actions
export class BuildTabbedSection {
  static readonly type: string = '[Tabbed Section] Build Tabbed Section';
}

export class SetQuestionGroups {
  static readonly type: string = '[Tabbed Section] Set Question Groups';
}

export class SetTabIndex {
  static readonly type: string = '[Tabbed Section] Set Tab Index';

  constructor(public tabIndex: number) {}
}

export class SetStartTab {
  static readonly type: string = '[Tabbed Section] Set Start Tab';
}

export class ChangeTab {
  static readonly type: string = '[Tabbed Section] Change Tab';

  constructor(public tabIndex: number) {}
}

export class PreviousGroup {
  static readonly type: string = '[Tabbed Section] Previous Group';
}

export class NextGroup {
  static readonly type: string = '[Tabbed Section] Next Group';
}

export class UpdateTabbedSection {
  static readonly type: string = '[Tabbed Section] Update Tabbed Section';
}

// events
export class SetQuestionGroupsSuccess {
  static readonly type: string = '[Tabbed Section] Set Question Groups Success';
}
