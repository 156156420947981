import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import {
  Question,
  QuestionAnswer,
  AnswerGroupItem,
  QuestionListItem,
} from '../../store/survey.model';
import { SurveyDocumentState } from '../../store/survey.state';
import { map } from 'rxjs/operators';

@Component({
  selector: 'assess-survey-a-and-b-question',
  templateUrl: './survey-a-and-b-question.component.html',
})
export class SurveyAAndBQuestionComponent implements OnInit, OnDestroy {
  @Input()
  question: Question;

  @Input()
  answerGroup: AnswerGroupItem[];

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer[]> = new EventEmitter<
    QuestionAnswer[]
  >();

  answerA: string;
  answerB: string;

  isAnswered: boolean = false;

  questionListItemA$: Observable<QuestionListItem>;
  questionListItemB$: Observable<QuestionListItem>;

  private _questionListItemASubscription: Subscription;
  private _questionListItemBSubscription: Subscription;

  constructor(private _store: Store) {}

  ngOnInit() {
    this.questionListItemA$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.question.options[0].formItemId)));

    this._questionListItemASubscription = this.questionListItemA$.subscribe(
      (questionListItem: QuestionListItem) => {
        this.answerA = questionListItem.answer;
        this.isAnswered =
          questionListItem.answer && questionListItem.answer !== null;
      }
    );

    this.questionListItemB$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.question.options[1].formItemId)));

    this._questionListItemBSubscription = this.questionListItemB$.subscribe(
      (questionListItem: QuestionListItem) => {
        this.answerB = questionListItem.answer;
        this.isAnswered =
          questionListItem.answer && questionListItem.answer !== null;
      }
    );
  }

  ngOnDestroy(): void {
    this._questionListItemASubscription.unsubscribe();
    this._questionListItemBSubscription.unsubscribe();
  }

  onSelectionChanged(event: MatSelectChange, index: number): void {
    const previousAnswerA: string = this.answerA;
    const previousAnswerB: string = this.answerB;

    const answerGroupItemIndex: number = this.answerGroup.findIndex(
      (answerGroupItem: AnswerGroupItem) =>
        answerGroupItem.value === event.value
    );

    if (index === 0) {
      this.answerA = event.value;
      this.answerB = this.answerGroup[
        this.answerGroup.length - 1 - answerGroupItemIndex
      ].value;
    } else if (index === 1) {
      this.answerB = event.value;
      this.answerA = this.answerGroup[
        this.answerGroup.length - 1 - answerGroupItemIndex
      ].value;
    }

    this.isAnswered = true;

    // emit the answers
    let surveyQuestionA: QuestionAnswer = Object.assign(
      {},
      {
        formItemId: this.question.options[0].formItemId,
        formItemSequenceNumber: this.question.options[0].formItemSequenceNumber,
        optional: this.question.optional,
        previousAnswer: previousAnswerA,
        answer: this.answerA,
      }
    );

    let surveyQuestionB: QuestionAnswer = Object.assign(
      {},
      {
        formItemId: this.question.options[1].formItemId,
        formItemSequenceNumber: this.question.options[1].formItemSequenceNumber,
        optional: this.question.optional,
        previousAnswer: previousAnswerB,
        answer: this.answerB,
      }
    );

    this.questionAnswered.emit([surveyQuestionA, surveyQuestionB]);
  }

  getAnswer(index: number): string {
    return index === 0 ? this.answerA : this.answerB;
  }
}
