import { State, Action, StateContext, Selector } from '@ngxs/store';

import { Navigate } from '@ngxs/router-plugin';

import { saveAs } from 'file-saver';

import {
  LoadAsset,
  LoadAssetSuccess,
  LoadDocument,
  LoadDocumentSuccess,
} from './report-viewer.actions';
import { ReportViewerService } from '../services/report-viewer.service';
import { InstrumentStatus } from '../../participant-landing/models/participant.model';
import { Injectable } from '@angular/core';

export class ReportViewerStateModel {
  report: Blob;
  instrumentStatus: InstrumentStatus;
}

@State<ReportViewerStateModel>({
  name: 'reportViewer',
  defaults: {
    report: null,
    instrumentStatus: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ReportViewerState {
  constructor(private _reportViewerService: ReportViewerService) {}

  @Selector()
  static getReport(state: ReportViewerStateModel): Blob {
    return state.report;
  }

  @Selector()
  static getInstrumentStatus(state: ReportViewerStateModel): InstrumentStatus {
    return state.instrumentStatus;
  }

  // actions
  @Action(LoadAsset)
  loadAsset(ctx: StateContext<ReportViewerStateModel>, action: LoadAsset) {
    if (
      action.instrumentStatus.instrumentTypeId &&
      action.instrumentStatus.instrumentTypeId === 6
    ) {
      window.open(
        action.instrumentStatus.assessmentLinkInfo.reportLink,
        '_blank'
      );
    } else {
      this._reportViewerService
        .getParticipantAsset(
          action.user.individualId,
          action.instrumentStatus.assetInfo.assetInfoId,
          action.instrumentStatus.assetInfo.latestVersion
        )
        .subscribe((report: Blob) => {
          ctx.dispatch(new LoadAssetSuccess(report, action.instrumentStatus));
        });
    }
  }

  @Action(LoadDocument)
  loadDocument(
    ctx: StateContext<ReportViewerStateModel>,
    action: LoadDocument
  ) {
    this._reportViewerService
      .getParticipantDocument(
        action.instrumentStatus.participantId,
        action.instrumentStatus.documentInfo.documentId
      )
      .subscribe((report: Blob) => {
        ctx.dispatch(new LoadDocumentSuccess(report, action.instrumentStatus));
      });
  }

  // events
  @Action(LoadAssetSuccess)
  loadAssetSuccess(
    ctx: StateContext<ReportViewerStateModel>,
    action: LoadAssetSuccess
  ) {
    ctx.patchState({
      report: action.report,
      instrumentStatus: action.instrumentStatus,
    });

    if (action.instrumentStatus.assetInfo.contentType === 'application/pdf') {
      ctx.dispatch(new Navigate(['/report']));
    } else {
      saveAs(action.report, action.instrumentStatus.assetInfo.name);
    }
  }

  @Action(LoadDocumentSuccess)
  loadDocumentSuccess(
    ctx: StateContext<ReportViewerStateModel>,
    action: LoadDocumentSuccess
  ) {
    ctx.patchState({
      report: action.report,
      instrumentStatus: action.instrumentStatus,
    });

    ctx.dispatch(new Navigate(['/report']));
  }
}
