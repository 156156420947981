import { Component, OnInit } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';

import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';

import { ApplicationState } from '../../store/application.state';
import { SetLandingTabIndex } from '../../store/application.actions';
import { User } from 'src/app/core/auth/user.model';

@Component({
  selector: 'assess-tabbed-landing',
  templateUrl: './tabbed-landing.component.html',
})
export class TabbedLandingComponent implements OnInit {
  @Select(ApplicationState.getLandingTabIndex)
  initialTab$: Observable<number>;

  user: User;

  showLoading: boolean = false;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.user = this._store.selectSnapshot(ApplicationState.getUser);
  }

  onLoading(loading: boolean) {
    this.showLoading = loading;
  }

  onSelectedTabChange(value: MatTabChangeEvent): void {
    this._store.dispatch(new SetLandingTabIndex(value.index));
  }
}
