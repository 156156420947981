import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SurveySection, Extension } from '../../store/survey.model';
import { SurveyDocumentState } from '../../store/survey.state';
import { ExitSurvey } from '../../store/survey.actions';
import { Config } from 'src/app/store/application.models';
import { ApplicationState } from 'src/app/store/application.state';

@Component({
  selector: 'assess-survey-complete',
  templateUrl: './survey-complete.component.html',
})
export class SurveyCompleteComponent implements OnInit {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  marketingImageSource: string;
  public assessments2Uri;

  constructor(private _store: Store) {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );
    this.assessments2Uri = config.assessments2Uri;
  }

  ngOnInit(): void {
    const extensions: Extension[] = this._store.selectSnapshot(
      SurveyDocumentState.getSurveySectionExtensions
    );
    if (extensions) {
      const extension: Extension = extensions.find(
        (extension: Extension) => extension.name === 'marketingImage'
      );
      if (extension) {
        this.marketingImageSource = extension.value;
      }
    }
  }

  onExitClick(): void {
    localStorage.setItem("instrumentArray", JSON.stringify([]));
    window.location.href = this.assessments2Uri;
    this._store.dispatch(new ExitSurvey());
  }
}
