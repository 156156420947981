import { Directive, AfterViewInit, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { ApplicationState } from './store/application.state';

@Directive({
  selector: '[apDirection]'
})
export class DirectionDirective implements OnInit, AfterViewInit {
  constructor(private _store: Store) {}

  ngOnInit() {
    this._store
      .select(state => state.application.languageDirection)
      .subscribe((dir: string) => {
        document.querySelector('body').setAttribute('dir', dir);
      });
  }

  ngAfterViewInit() {
    document
      .querySelector('body')
      .setAttribute(
        'dir',
        this._store.selectSnapshot(ApplicationState.getLanguageDirection)
      );
  }
}
