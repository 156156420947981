import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { NoWorkLandingComponent } from './no-work-landing.component';
import { NoWorkLandingRoutingModule } from './no-work-landing-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    NoWorkLandingRoutingModule
  ],
  declarations: [NoWorkLandingComponent]
})
export class NoWorkLandingModule {}
