<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column" style="height: 100%;">
      <ngx-loading [show]="loading"></ngx-loading>
      <assess-survey-section-title></assess-survey-section-title>
      <div style="background-color: white; overflow-y:auto; padding: 20px; height: 100%;">
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="30px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <img *ngIf="getExtensionValue('imageTreatment')" [src]="getExtensionValue('imageTreatment')" width="60px"
                height="75px" alt="Survey Logo">
            </div>
            <div *ngIf="(availableLanguages$ | async) as availableLanguages" >
              <mat-form-field *ngIf="availableLanguages.length > 1">
                <mat-select [(value)]="selectedLanguage" (selectionChange)="onLanguageChange($event)" aria-label="Survey language DropDown" aria-expanded="false" aria-haspopup="true">
                  <mat-option *ngFor="let language of availableLanguages" [value]="language.languageId">
                    {{ language.translatedLanguageName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div>
            <assess-survey-instruction [surveySection]="surveySection"></assess-survey-instruction>
          </div>
          <div fxFlexAlign="center" *ngIf="surveySection.questionGroups && surveySection.questionGroups.length > 0">
            <assess-survey-welcome-question [question]="surveySection.questionGroups[0].questions[0]"
              [disabled]="canStart" (welcomeQuestionChange)="onWelcomeQuestionChange($event)">
            </assess-survey-welcome-question>
          </div>
          <div fxFlexAlign="center">
            <assess-survey-next-step-button [surveySection]="surveySection" [disabled]="!canStart">
            </assess-survey-next-step-button>
          </div>
          <div *ngIf="getExtensionValue('copyright')" class="text-center text-small"
            [innerHtml]="getExtensionValue('copyright')" tabindex="0"></div>
        </div>
      </div>
    </div>
  </div>
</div>