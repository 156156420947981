<div fxLayout="column" fxLayoutGap="20px" [id]="question.formItemId" style="padding: 10px;">
  <div fxLayout="row" fxLayoutAlign="start start" [ngClass]="{ 'not-answered': !answered() }">
    <span fxFlex="0 0 20px">
      <i class="fa fa-star text-success" *ngIf="answered()"></i>
    </span>
    <p style="margin: 0;" fxLayout="row" fxLayoutGap="5px">
      <span>{{ question.number }}.</span>
      <span [innerHtml]="question.text"></span>
    </p>
  </div>
  <div>
    <div *ngFor="let option of question.options; let i = index;">
      <div fxLayout="column" fxLayoutAlign="start start" fxShow.gt-xs="true" fxLayoutGap="20px">
        <div>{{ option.text }}</div>
        <div fxFlexAlign="center">
          <assess-survey-scale (scaleSelected)="onScaleSelected($event, i)" [surveyScale]="surveyScale"
            [question]="option" [questionListItem]="getQuestionListItem(i) | async"></assess-survey-scale>
        </div>
      </div>
    </div>
  </div>
</div>