import {
  Component,
  OnInit,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import { FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { debounceTime, startWith, map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  Extension,
  QuestionAnswer,
  QuestionListItem
} from '../../store/survey.model';
import { SurveyDocumentState } from '../../store/survey.state';

@Component({
  selector: 'assess-survey-direct-feedback-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-direct-feedback-question.component.html'
})
export class SurveyDirectFeedbackQuestionComponent
  implements OnInit, OnDestroy {
  @Input()
  question: Question;

  @Output()
  directFeedbackChanged: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  directFeedbackControl: FormControl;

  questionListItem$: Observable<QuestionListItem>;

  private _questionListItemSubscription: Subscription;
  private _previousAnswer: string;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map(filterFn => filterFn(this.question.formItemId)));

    this._questionListItemSubscription = this.questionListItem$.subscribe(
      (questionListItem: QuestionListItem) => {
        this._previousAnswer = questionListItem.answer;

        this.directFeedbackControl = new FormControl(null);

        this.directFeedbackControl.valueChanges
          .pipe(
            startWith(questionListItem.answer),
            debounceTime(500),
            map((value: any) => {
              let question: QuestionAnswer = Object.assign(
                {},
                {
                  ...this.question,
                  previousAnswer: this._previousAnswer,
                  answer: value
                }
              );

              this._previousAnswer = value;

              this.directFeedbackChanged.emit(question);
            })
          )
          .subscribe();

        this.directFeedbackControl.setValue(questionListItem.answer, {
          emitEvent: false
        });
      }
    );
  }

  ngOnDestroy(): void {
    this._questionListItemSubscription.unsubscribe();
  }

  getExtensionValue(name: string): string {
    const extension: Extension = this.question.extensions.find(
      (extension: Extension) => extension.name === name
    );

    return extension ? extension.value : null;
  }

  getPlaceholder(): string {
    return `${this.question.placeholder} ${this.getExtensionValue('optional')}`;
  }
}
