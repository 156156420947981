import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  AbstractControl,
} from '@angular/forms';

import { Store, Actions, ofActionSuccessful } from '@ngxs/store';

import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

import { RaterStatus, Individual } from '../../store/rater-management.models';
import { AddRaterState } from './store/add-rater.state';
import { RaterFormComponent } from './components/rater-form/rater-form.component';
import { RaterValidator } from './validators/rater.validator';
import { InviteRaters, InviteRatersFailed } from './store/add-rater.actions';
import { Navigate } from '@ngxs/router-plugin';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'assess-add-rater',
  templateUrl: './add-rater.component.html',
  styleUrls: ['./add-rater.component.scss'],
})
export class AddRaterComponent implements OnInit, OnDestroy {
  raterStatus: RaterStatus;

  ratersFormGroup: FormGroup;

  error: string = null;

  showLoading: boolean = false;

  private _addRatersFailedSubscription: Subscription;

  constructor(
    private _store: Store,
    private _translateService: TranslateService,
    private _actions$: Actions,
    private _formBuilder: FormBuilder,
    private _media: MediaObserver
  ) {
    if (this._media.isActive('xs') || this._media.isActive('sm')) {
      this.ratersFormGroup = this.loadForm(1);
    } else {
      this.ratersFormGroup = this.loadForm(5);
    }
  }

  ngOnInit() {
    this._addRatersFailedSubscription = this._actions$
      .pipe(ofActionSuccessful(InviteRatersFailed))
      .subscribe((inviteRatersFailed: InviteRatersFailed) => {
        this.showLoading = false;
        if (inviteRatersFailed.status === -1) {
          this.error = this._translateService.instant(
            'raterManagement.addRater.error.self'
          );
        } else if (inviteRatersFailed.status === -2) {
          this.error = this._translateService.instant(
            'raterManagement.addRater.error.duplicate',
            { email: inviteRatersFailed.email }
          );
        }
      });

    this.raterStatus = this._store.selectSnapshot(AddRaterState.getRaterStatus);

    this._media.asObservable().subscribe((change: MediaChange[]) => {
      if (change[0].mqAlias === 'xs' || change[0].mqAlias === 'sm') {
        this.ratersFormGroup = this.loadForm(1);
      } else {
        this.ratersFormGroup = this.loadForm(5);
      }
    });
  }

  ngOnDestroy(): void {
    this._addRatersFailedSubscription.unsubscribe();
  }

  get controls(): AbstractControl[] {
    return (this.ratersFormGroup.get('individuals') as FormArray).controls;
  }

  onAddRaterClick(): void {
    (this.ratersFormGroup.get('individuals') as FormArray).push(
      RaterFormComponent.buildRaterForm()
    );
  }

  onBack(): void {
    this._store.dispatch(new Navigate(['/rater-management']));
  }

  onClearRaterClick(): void {
    this.ratersFormGroup.reset();
    this.ratersFormGroup.enable();
  }

  onRemoveRaterClick(index: number): void {
    (this.ratersFormGroup.get('individuals') as FormArray).removeAt(index);
    if ((this.ratersFormGroup.get('individuals') as FormArray).length < 5) {
      this.onAddRaterClick();
    }
  }

  submit() {
    this.showLoading = true;
    const individuals: Individual[] = this.ratersFormGroup
      .getRawValue()
      .individuals.filter(
        (individual: Individual) =>
          individual.email !== null && individual.email.trim() !== ''
      );
    this._store.dispatch(new InviteRaters(individuals));
  }

  private loadForm(numberOfForms: number): FormGroup {
    let formGroups: FormGroup[] = [];

    for (let i: number = 0; i < numberOfForms; i++) {
      formGroups.push(RaterFormComponent.buildRaterForm());
    }

    return this._formBuilder.group({
      individuals: this._formBuilder.array(formGroups, RaterValidator.required),
    });
  }
}
