import { Injectable, Inject, Optional } from '@angular/core';
import { LogLevel, LogEntry, LogConfigService, LogConfig } from './log.model';
import { LogPublishersService } from './log-publishers.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  level: LogLevel = LogLevel.All;

  constructor(
    private _logPublishersService: LogPublishersService,
    @Inject(LogConfigService)
    @Optional()
    private _logConfig: LogConfig
  ) {
    if (this._logConfig) {
      this.level = this._logConfig.logLevel;
    }
  }

  log(level: LogLevel, msg: any): void {
    if (this.shouldLog(level)) {
      const logEntry: LogEntry = new LogEntry(LogLevel[level], msg);
      for (let logger of this._logPublishersService.publishers) {
        logger.log(logEntry).subscribe();
      }
    }
  }

  debug(msg: any): void {
    this.log(LogLevel.Debug, msg);
  }

  error(msg: any): void {
    this.log(LogLevel.Error, msg);
  }

  fatal(msg: any): void {
    this.log(LogLevel.Fatal, msg);
  }

  info(msg: any): void {
    this.log(LogLevel.Info, msg);
  }

  warn(msg: any): void {
    this.log(LogLevel.Warn, msg);
  }

  private shouldLog(level: LogLevel): boolean {
    return (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    );
  }
}
