import { Observable } from 'rxjs';
import { LogEntry } from './log.model';
import { of } from 'rxjs';
import { LogPublisher } from './log-publisher';

export class ConsoleLogPublisher extends LogPublisher {
  constructor() {
    super();
    this.location = 'console';
  }

  log(entry: LogEntry): Observable<boolean> {
    console.log('log', entry);
    return of(true);
  }
}
