import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

// components
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from './dialogs/information-dialog/information-dialog.component';
import { FaqDialogComponent } from './dialogs/faq-dialog/faq-dialog.component';
import { SessionEndingDialogComponent } from './dialogs/session-ending-dialog/session-ending-dialog.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { WarningBarComponent } from './warning-bar/warning-bar.component';

// directives
import { BackgroundDirective } from './directives/background.directive';
import { FullHeightDirective } from './directives/full-height.directive';
import { NoDblClickMatDirective } from './directives/no-dbl-click-mat.directive';

// guards
import { AuthGuard } from './guards/auth.guard';

// pipes
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { NoInternetDialogComponent } from './dialogs/no-internet-dialog/no-internet-dialog.component';
import { CapitalStartPipe } from './pipes/capital-start.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [
    ConfirmationDialogComponent,
    FaqDialogComponent,
    InformationDialogComponent,
    SessionEndingDialogComponent,
    StatusChipComponent,
    WarningBarComponent,
    BackgroundDirective,
    FullHeightDirective,
    NoDblClickMatDirective,
    LocalizedDatePipe,
    CapitalStartPipe,
    NoInternetDialogComponent,
  ],
  exports: [
    StatusChipComponent,
    WarningBarComponent,
    BackgroundDirective,
    FullHeightDirective,
    NoDblClickMatDirective,
    LocalizedDatePipe,
    CapitalStartPipe,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    FaqDialogComponent,
    InformationDialogComponent,
    SessionEndingDialogComponent,
    NoInternetDialogComponent,
  ],
  providers: [AuthGuard],
})
export class SharedModule {}
