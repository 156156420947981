import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { JwtModule } from '@auth0/angular-jwt';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import { DeviceDetectorModule } from 'ngx-device-detector';

import { FlexLayoutModule } from '@angular/flex-layout';

import { NgIdleModule } from '@ng-idle/core';

import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';

import { AppVersion } from './app-version';

import { DirectionDirective } from './direction.directive';

import { GlobalExceptionModule } from './global-exception/global-exception.module';
import { ApplicationState } from './store/application.state';
import { AppConfigService } from './app-config.service';
import { CoreModule } from './core/core.module';
import { HeaderModule } from './features/header/header.module';
import { ParticipantLandingModule } from './features/participant-landing/participant-landing.module';
import { RaterLandingModule } from './features/rater-landing/rater-landing.module';
import { TabbedLandingModule } from './features/tabbed-landing/tabbed-landing.module';
import { RaterManagementModule } from './features/rater-management/rater-management.module';
import { SurveyModule } from './features/survey/survey.module';
import { ReportViewerModule } from './features/report-viewer/report-viewer.module';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LogModule } from './core/log/log.module';
import { LOG_ENTRIES_CONFIG_FACTORY } from './core/log/plugins/log-entries-plugin/log-entries-log.model';
import { LogEntriesConfigService } from './log-entries-config.service';
import { LogEntriesLogPluginModule } from './core/log/plugins/log-entries-plugin/log-entries-log-plugin.module';
import { NoWorkLandingModule } from './features/no-work-landing/no-work-landing.module';
import { BackgroundLandingModule } from './features/background-landing/background-landing.module';
import { MyProfileModule } from './features/my-profile/my-profile.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function JwtTokenGetter(): string {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [AppComponent, DirectionDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot([ApplicationState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: JwtTokenGetter,
        allowedDomains: [
          'localhost:5438',
          'api-dev.ccl.org',
          'api-qa.ccl.org',
          'api-uat.ccl.org',
          'api.ccl.org',
        ],
      },
    }),
    DeviceDetectorModule.forRoot(),
    LogModule.forRoot(),
    LogEntriesLogPluginModule.forRoot(),
    ScrollToModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      primaryColour: '#003da7',
    }),
    FlexLayoutModule,
    NgIdleModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    GlobalExceptionModule,
    HeaderModule,
    ParticipantLandingModule,
    RaterLandingModule,
    TabbedLandingModule,
    NoWorkLandingModule,
    BackgroundLandingModule,
    RaterManagementModule,
    SurveyModule,
    MyProfileModule,
    ReportViewerModule,
  ],
  providers: [
    AppVersion,
    AppConfigService,
    {
      provide: LOG_ENTRIES_CONFIG_FACTORY,
      useClass: LogEntriesConfigService,
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
