<div fxShow fxHide.lt-sm="true">
  <mat-card>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="30px">
          <div fxFlex="30%" fxLayout="column">
            <div>
              <i class="fa fa-user text-large"></i>&nbsp;
              <span class="text-bold" tabindex="0">{{surveyStatus.participant.firstName}} {{surveyStatus.participant.lastName}}</span>
            </div>
            <div class="text-muted text-small" [translate]="'raterLanding.card.raterType'" [translateParams]="{raterType: surveyStatus.rater.raterType.name}" tabindex="0"></div>
          </div>
          <div fxFlex="30%" fxLayout="column" fxLayoutGap="10px">
            <div class="text-bold" tabindex="0">{{surveyStatus.instrument.name}}</div>
            <mat-progress-bar mode="determinate" [value]="surveyStatus.percentCompleted" tabindex="0" aria-label="Survey progress bar"></mat-progress-bar>
          </div>
          <div fxFlex="40%" fxLayout="row" fxLayoutAlign="center">
            <div fxLayout="column" *ngIf="surveyStatus.assessmentDueDate">
              <div class="text-bold" tabindex="0">{{'raterLanding.card.header.assessmentDueDate' | translate}}</div>
              <div tabindex="0">{{surveyStatus.assessmentDueDate | localizedDate}}</div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
          <div (click)="toggle()" class="expansion" tabindex="0" (keypress)="toggle()">
            {{'raterLanding.card.expansion.title' | translate}}
            <i class="fa fa-angle-down" [@disclosureState]='state' aria-hidden="true"></i>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" [@collapsedState]='state'>
            <div>
              <i class="fa fa-history"></i>&nbsp;
              <span class="text-muted" [translate]="'raterLanding.card.estimatedTimeToComplete'" [translateParams]="{estimatedTimeToComplete: surveyStatus.estimatedTimeToComplete}" tabindex="0"></span>
            </div>
            <div [translate]="'raterLanding.card.instructions'" [translateParams]="{participantFirstName: surveyStatus.participant.firstName, raterType: surveyStatus.rater.raterType.name}" tabindex="0"></div>
          </div>
        </div>
        <div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
          <div *ngIf="surveyStatus.surveyStatusId !== 3; else completed">
            <button mat-raised-button color="primary" (click)="onSurveyClick()">
              <span fxLayout fxLayoutAlign="space-between center">
                <span style="padding-right: 10px;">{{'raterLanding.card.button.survey' | translate}}</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <ng-template #completed>
            <button mat-raised-button disabled>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{'raterLanding.card.button.survey' | translate}}</span>
                <i class="fa fa-check text-success"></i>
              </div>
            </button>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div fxHide fxShow.lt-sm="true" fxLayout="column" fxLayoutGap="10px">
  <mat-card>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxFlex="100%" class="text-bold">{{surveyStatus.instrument.name}}</div>
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="15px">
          <div fxFlex="70%" fxLayout="column">
            <div>
              <i class="fa fa-user text-large"></i>&nbsp;
              <span class="text-bold">{{surveyStatus.participant.firstName}} {{surveyStatus.participant.lastName}}</span>
            </div>
            <div class="text-muted text-small" [translate]="'raterLanding.card.raterType'" [translateParams]="{raterType: surveyStatus.rater.raterType.name}"></div>
          </div>
          <div fxFlex="30%" fxLayout="row" fxLayoutAlign="end">
            <div fxLayout="column" *ngIf="surveyStatus.assessmentDueDate">
              <div class="text-bold">{{'raterLanding.card.header.assessmentDueDate' | translate}}</div>
              <div>{{surveyStatus.assessmentDueDate | localizedDate}}</div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
          <div (click)="toggle()" class="expansion" (keypress)="toggle()" tabindex="0 ">
            {{'raterLanding.card.expansion.title' | translate}}
            <i class="fa fa-angle-down" [@disclosureState]='state'></i>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" [@collapsedState]='state'>
            <div>
              <i class="fa fa-history"></i>&nbsp;
              <span class="text-muted" [translate]="'raterLanding.card.estimatedTimeToComplete'" [translateParams]="{estimatedTimeToComplete: surveyStatus.estimatedTimeToComplete}"></span>
            </div>
            <div [translate]="'raterLanding.card.instructions'" [translateParams]="{participantFirstName: surveyStatus.participant.firstName, raterType: surveyStatus.rater.raterType.name}"></div>
          </div>
        </div>
        <div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
          <div *ngIf="surveyStatus.surveyStatusId !== 3; else completedMobile">
            <button mat-raised-button color="primary" (click)="onSurveyClick()">
              <span fxLayout fxLayoutAlign="space-between center">
                <span style="padding-right: 10px;">{{'raterLanding.card.button.survey' | translate}}</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <ng-template #completedMobile>
            <button mat-raised-button disabled>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{'raterLanding.card.button.survey' | translate}}</span>
                <i class="fa fa-check text-success"></i>
              </div>
            </button>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>