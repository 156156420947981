<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <ngx-loading [show]="loading"></ngx-loading>
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column">
      <assess-survey-section-title></assess-survey-section-title>
      <div fxFlex style="background-color: white; overflow-y:auto;">
        <assess-survey-review-question *ngFor="let sectionReviewCard of (sectionReviewCards$ | async)"
          [sectionReviewCard]="sectionReviewCard" [extensions]="surveySection.extensions"
          (sectionReviewCardClicked)="onSectionReviewCardClicked($event)"
          (questionNumberClicked)="onQuestionNumberClicked($event)"></assess-survey-review-question>
        <div *ngIf="(canSubmit$ | async) as canSubmit">
          <div *ngIf="surveySection.partialSubmit && canSubmit.enabled" class="mt-1">
            <p *ngFor="let instruction of surveySection.partialSubmit">
              <span [innerHtml]="instruction"></span>
            </p>
            <div class="button-panel" fxLayout="column" fxLayoutAlign="start center">
              <button mat-raised-button color="primary" apSurveyStepperNext>
                <span fxLayout fxLayoutAlign="space-between center">
                  <span style="padding-right: 10px;">{{surveySection.buttonText}}</span>
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="button-panel" fxLayout="column" fxLayoutAlign="start center">
            <button mat-raised-button apNoDblClickMat color="primary" (click)="onSubmitSurvey()"
              [disabled]="!canSubmit.enabled">{{surveySection.submitButtonText}}</button>
            <div class="text-warn" *ngIf="!canSubmit.enabled">
              <div *ngFor="let warning of surveySection.warnings">{{warning}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>