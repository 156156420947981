import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';

import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxLoadingModule } from 'ngx-loading';

import { SharedModule } from '../../../../shared/shared.module';
import { AddRaterRoutingModule } from './add-rater-routing.module';

import { AddRaterState } from './store/add-rater.state';
import { AddRaterComponent } from './add-rater.component';
import { RaterFormComponent } from './components/rater-form/rater-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDividerModule,
    NgxsModule.forFeature([AddRaterState]),
    NgxLoadingModule,
    SharedModule,
    AddRaterRoutingModule,
  ],
  declarations: [AddRaterComponent, RaterFormComponent],
})
export class AddRaterModule {}
