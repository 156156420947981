<form novalidate [formGroup]="raterFormGroup">
  <div fxShow fxHide.lt-md="true" fxLayout="row" fxLayoutGap="10px" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <mat-form-field fxFlex="25%">
      <input matInput [attr.id]="'email' + index" [placeholder]="'raterManagement.addRater.placeholder.email' | translate"
        [formControl]="emailControl" formControlName="email" autocomplete="nope" [matAutocomplete]="autoMedium"
        [matAutocompleteConnectedTo]="origin" (blur)="onBlur()">
      <mat-autocomplete #autoMedium="matAutocomplete" (optionSelected)="onRaterSelected($event)">
        <div class="mat-body text-small text-muted" style="padding: 5px 15px;">{{'raterManagement.addRater.mru' |
          translate}}</div>
        <mat-divider></mat-divider>
        <mat-option *ngFor="let rater of filteredRaters$ | async" [value]="rater.email">
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="40%">{{rater.email}}</div>
            <div fxFlex="40%">{{rater.firstName}} {{rater.lastName}}</div>
            <div fxFlex="20%">{{rater.language.name}}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field fxFlex="25%">
      <input matInput [attr.id]="'firstName' + index" [placeholder]="'raterManagement.addRater.placeholder.firstName' | translate"
        formControlName="firstName" autocomplete="nope">
    </mat-form-field>
    <mat-form-field fxFlex="25%">
      <input matInput [attr.id]="'lastName' + index" [placeholder]="'raterManagement.addRater.placeholder.lastName' | translate"
        formControlName="lastName" autocomplete="nope">
    </mat-form-field>
    <mat-form-field fxFlex="20%">
      <mat-select [attr.id]="'language' + index" [placeholder]="'raterManagement.addRater.placeholder.language' | translate"
        formControlName="language">
        <mat-option *ngFor="let language of languages" [value]="language">{{ language.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-icon-button (click)="onRemoveRaterClick(index)">
        <i class="fa fa-remove" aria-label="RemoveRater"></i>
      </button>
    </div>
  </div>

  <div fxHide fxShow.lt-md="true" fxLayout="column" fxLayoutGap="10px">
    <mat-form-field>
      <input matInput [attr.id]="'email' + index" [placeholder]="'raterManagement.addRater.placeholder.email' | translate"
        [formControl]="emailControl" formControlName="email" autocomplete="nope" [matAutocomplete]="autoSmall" (blur)="onBlur()">
      <mat-autocomplete #autoSmall="matAutocomplete" (optionSelected)="onRaterSelected($event)">
        <div class="mat-body text-small text-muted" style="padding: 5px 15px;">Recently Added Raters</div>
        <mat-divider></mat-divider>
        <mat-option *ngFor="let rater of filteredRaters$ | async" [value]="rater.email">
          <div>{{rater.email}}</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <input matInput [attr.id]="'firstName' + index" [placeholder]="'raterManagement.addRater.placeholder.firstName' | translate"
        formControlName="firstName" autocomplete="nope">
    </mat-form-field>
    <mat-form-field>
      <input matInput [attr.id]="'lastName' + index" [placeholder]="'raterManagement.addRater.placeholder.lastName' | translate"
        formControlName="lastName" autocomplete="nope">
    </mat-form-field>
    <mat-form-field>
      <mat-select [attr.id]="'language' + index" [placeholder]="'raterManagement.addRater.placeholder.language' | translate"
        formControlName="language">
        <mat-option *ngFor="let language of languages" [value]="language">{{ language.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>