<!-- need to add other edit box and figure out how to save the answer -->
<div fxLayout="column" fxLayoutGap="5px">
    <form novalidate [formGroup]="otherFormGroup">
        <div fxLayout fxLayoutAlign="start" style="padding: 10px;">
            <div fxFlex="0 0 30px">
                <mat-checkbox (change)="onOptionChanged($event)" formControlName="option" tabindex="0" aria-label="Checkbox"></mat-checkbox>
            </div>
            <div [innerHtml]="option.text" tabindex="0"></div>
        </div>
        <div *ngIf="option.dependentOption" style="padding-left: 10px;">
            <mat-form-field *ngIf="showOther && option.dependentOption.multiline === false" class="full-width">
                <input matInput #message [attr.id]="'other' + option.formItemSequenceNumber" formControlName="other"
                    autocomplete="nope" [placeholder]="option.text" [maxLength]="option.dependentOption.maxLength">
                <mat-hint align="end">{{message.value?.length || 0}} /
                    {{ option.dependentOption.maxLength }}</mat-hint>
            </mat-form-field>
            <mat-form-field *ngIf="showOther && option.dependentOption.multiline === true" class="full-width">
                <textarea matInput #message [maxLength]="option.dependentOption.maxLength"
                    [placeholder]="option.dependentOption.placeholder" matTextareaAutosize matAutosizeMinRows="2"
                    matAutosizeMaxRows="7" [formControl]="otherTextbox"></textarea>
                <mat-hint align="end">{{message.value?.length || 0}} /
                    {{ option.dependentOption.maxLength }}</mat-hint>
            </mat-form-field>
        </div>
    </form>
</div>