import { InjectionToken } from '@angular/core';

import { LogEntriesConfigFactory } from './log-entries-config.factory';

export interface LogEntriesConfig {
  url: string;
  token: string;
}

export const LOG_ENTRIES_CONFIG_FACTORY = new InjectionToken<
  LogEntriesConfigFactory
>('LogEntriesConfigFactory');
