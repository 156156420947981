<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column">
      <assess-survey-section-title></assess-survey-section-title>
      <div fxFlex style="background-color: white; overflow-y:auto; padding: 10px;">
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px">
          <assess-survey-information [surveySection]="surveySection"></assess-survey-information>
          <div *ngIf="(competenciesReviews$ | async) as competenciesReviews">
            <mat-accordion [multi]="true" *ngIf="competenciesReviews.length > 0 else noReviews">
              <mat-expansion-panel *ngFor="let competencyReview of competenciesReviews">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{competencyReview.name}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="haveQuestionsForAllGroups(competencyReview) else noQuestions">
                  <div *ngFor="let group of competencyReview.groups">
                    <div *ngIf="group.questions.length > 0">
                      <div class="competency-review header">{{group.name}}</div>
                      <ul *ngFor="let question of group.questions">
                        <li>{{question.text}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <ng-template #noQuestions>
                  <span class="text-warning">{{getExtensionValue('noQuestions')}}</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
            <ng-template #noReviews>
              <span class="text-warning">{{getExtensionValue('noReviews')}}</span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="survey button-panel" fxLayout="row" fxLayoutAlign="space-between center" #buttonPanel>
        <assess-survey-previous-step-button [surveySection]="surveySection"></assess-survey-previous-step-button>
        <assess-survey-next-step-button [surveySection]="surveySection"></assess-survey-next-step-button>
      </div>
    </div>
  </div>
</div>