import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Idle } from '@ng-idle/core';

import { QuestionGroup, QuestionAnswer } from '../../store/survey.model';
import { DirectFeedbackAnswerService } from '../../services/direct-feedback-answer.service';
import { AnswerQuestions } from '../../store/survey.actions';

@Component({
  selector: 'assess-survey-direct-feedback-questions-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-direct-feedback-questions-group.component.html'
})
export class SurveyDirectFeedbackQuestionsGroupComponent
  implements OnInit, OnDestroy {
  @Input() questionGroup: QuestionGroup;

  @Output()
  directFeedbackChanged: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  constructor(
    private _store: Store,
    private _directFeedbackAnswerService: DirectFeedbackAnswerService,
    private _idle: Idle
  ) {}

  ngOnInit(): void {
    this._directFeedbackAnswerService.clear();

    this._idle.onTimeoutWarning.subscribe((count: number) => {
      if (count === this._idle.getTimeout()) {
        this._store.dispatch(
          new AnswerQuestions(this._directFeedbackAnswerService.answers)
        );
      }
    });
  }

  ngOnDestroy(): void {
    this._store.dispatch(
      new AnswerQuestions(this._directFeedbackAnswerService.answers)
    );
  }

  onDirectFeedbackChanged(answer: QuestionAnswer): void {
    const questionAnswer: QuestionAnswer = {
      formItemId: answer.formItemId,
      previousAnswer: answer.previousAnswer,
      answer: answer.answer,
      optional: answer.optional,
      formItemSequenceNumber: answer.formItemSequenceNumber,
      answeredTimeMs: new Date().getTime()
    };
    this._directFeedbackAnswerService.upsertAnswer(questionAnswer);
  }
}
