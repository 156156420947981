<div fxShow fxHide.lt-sm="true" fxLayout="row" fxLayoutAlign="space-between center" class="striped"
  [ngClass]="{'highlight': highlight}">
  <div>{{instrumentStatus.assessmentItemName}}</div>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
    <button mat-raised-button color="primary" (click)="onViewDocumentClick()">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <span>{{'participant.button.view' | translate}}</span>
        <i class="fa fa-eye fa-fw"></i>
      </div>
    </button>
  </div>
</div>
<div fxHide fxShow.lt-sm="true" fxLayout="column" fxLayoutGap="10px">
  <div class="text-bold">{{instrumentStatus.assessmentItemName}}</div>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
    <button mat-raised-button color="primary" (click)="onViewDocumentClick()">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <span>{{'participant.button.view' | translate}}</span>
        <i class="fa fa-eye fa-fw"></i>
      </div>
    </button>
  </div>
</div>