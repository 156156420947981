import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';

import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';

import {
  RaterStatus,
  RaterManagement,
  Rater,
} from './store/rater-management.models';

import { AddRaters } from './modules/add-rater/store/add-rater.actions';

import {
  LoadRaterManagement,
  DeleteRater,
  RemindRaters,
  RemindRatersSuccess,
  ResendInvitation,
  ResendInvitationSuccess,
  RemindAllRaters,
} from './store/rater-management.actions';

import { RaterManagementState } from './store/rater-management.state';
import { InformationDialogComponent } from '../../shared/dialogs/information-dialog/information-dialog.component';
import { NavigateHome } from '../../store/application.actions';
import { FaqDialogComponent } from '../../shared/dialogs/faq-dialog/faq-dialog.component';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'assess-rater-management',
  templateUrl: './rater-management.component.html',
  styleUrls: ['./rater-management.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('disclosureState', [
      state('closed', style({ transform: 'rotate(0)' })),
      state('opened', style({ transform: 'rotate(-180deg)' })),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in')),
    ]),
    trigger('collapsedState', [
      state(
        'closed',
        style({ height: '0', 'overflow-y': 'hidden', padding: '0' })
      ),
      state(
        'opened',
        style({ height: '*', 'overflow-y': 'hidden', padding: '0' })
      ),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in')),
    ]),
  ],
})
export class RaterManagementComponent implements OnInit, OnDestroy {
  @Select(RaterManagementState.getRaterManagement)
  raterManagement$: Observable<RaterManagement>;

  showLoading: boolean = false;

  state: string = 'closed';

  private _remindRatersSubscription: Subscription = null;
  private _resendInvitationSubscription: Subscription = null;
  private _loadRaterManagementSubscription: Subscription = null;

  constructor(
    private _dialog: MatDialog,
    private _store: Store,
    private _actions$: Actions,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this._remindRatersSubscription = this._actions$
      .pipe(ofActionSuccessful(RemindRatersSuccess))
      .subscribe(() => {
        this.showLoading = false;
        this._dialog.open(InformationDialogComponent, {
          width: '450px',
          disableClose: true,
          data: {
            title: this._translateService.instant(
              'raterManagement.remindRatersSuccess.title'
            ),
            question: this._translateService.instant(
              'raterManagement.remindRatersSuccess.confirmation'
            ),
            okButton: this._translateService.instant('dialog.ok'),
          },
        });
      });

    this._resendInvitationSubscription = this._actions$
      .pipe(ofActionSuccessful(ResendInvitationSuccess))
      .subscribe(() => {
        this.showLoading = false;
        this._dialog.open(InformationDialogComponent, {
          width: '450px',
          disableClose: true,
          data: {
            title: this._translateService.instant(
              'raterManagement.resendInvitationSuccess.title'
            ),
            question: this._translateService.instant(
              'raterManagement.resendInvitationSuccess.confirmation'
            ),
            okButton: this._translateService.instant('dialog.ok'),
          },
        });
      });

    this._loadRaterManagementSubscription = this._actions$
      .pipe(ofActionSuccessful(LoadRaterManagement))
      .subscribe(() => {
        this.showLoading = false;
      });

    this._store.dispatch(new LoadRaterManagement());
  }

  ngOnDestroy(): void {
    if (this._remindRatersSubscription !== null) {
      this._remindRatersSubscription.unsubscribe();
    }
    if (this._resendInvitationSubscription !== null) {
      this._resendInvitationSubscription.unsubscribe();
    }
    if (this._loadRaterManagementSubscription !== null) {
      this._loadRaterManagementSubscription.unsubscribe();
    }
  }

  onAddRatersClick(raterStatus: RaterStatus): void {
    this._store.dispatch(new AddRaters(raterStatus));
  }

  onBack(): void {
    this._store.dispatch(new NavigateHome());
  }

  onDeleteRaterClick(rater: Rater): void {
    // confirm delete
    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this._translateService.instant(
          'raterManagement.deleteRater.title'
        ),
        question: this._translateService.instant(
          'raterManagement.deleteRater.confirmation',
          {
            name: `${rater.firstName} ${rater.lastName}`,
            contactNumbers: this._translateService.instant(
              'dialog.contactUs.text'
            ),
          }
        ),
        yesButton: this._translateService.instant('dialog.yes'),
        noButton: this._translateService.instant('dialog.no'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showLoading = true;
        this._store.dispatch(new DeleteRater(rater));
      }
    });
  }

  onLearnMoreClick(): void {
    const questionsText: string = this._translateService.instant(
      'dialog.faq.text'
    );
    const questions: any = JSON.parse(questionsText);

    this._dialog.open(FaqDialogComponent, {
      width: '700px',
      data: {
        title: this._translateService.instant('dialog.faq.title'),
        questions: questions,
        okButton: this._translateService.instant('dialog.ok'),
        scrollTo: 'faq3',
      },
    });
  }

  onRemindRatersClick(raterStatus: RaterStatus): void {
    // confirm delete
    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this._translateService.instant(
          'raterManagement.remindRaters.title'
        ),
        question: this._translateService.instant(
          'raterManagement.remindRaters.confirmation'
        ),
        yesButton: this._translateService.instant('dialog.yes'),
        noButton: this._translateService.instant('dialog.no'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showLoading = true;
        this._store.dispatch(new RemindRaters(raterStatus));
      }
    });
  }

  onRemindAllRatersClick(): void {
    // confirm delete
    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this._translateService.instant(
          'raterManagement.remindRaters.title'
        ),
        question: this._translateService.instant(
          'raterManagement.remindRaters.confirmation'
        ),
        yesButton: this._translateService.instant('dialog.yes'),
        noButton: this._translateService.instant('dialog.no'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showLoading = true;
        this._store.dispatch(new RemindAllRaters());
      }
    });
  }

  onResendInvitationClick(raterData: any): void {
    // confirm resend
    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this._translateService.instant(
          'raterManagement.resendInvitation.title'
        ),
        question: this._translateService.instant(
          'raterManagement.resendInvitation.confirmation',
          {
            raterName: `${raterData.rater.firstName} ${raterData.rater.lastName}`,
          }
        ),
        yesButton: this._translateService.instant('dialog.yes'),
        noButton: this._translateService.instant('dialog.no'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showLoading = true;
        this._store.dispatch(
          new ResendInvitation(raterData.raterStatus, raterData.rater)
        );
      }
    });
  }

  warnMoreRaters(raterManagement: RaterManagement): boolean {
    return raterManagement.raterStatuses.some(
      (raterStatus: RaterStatus) => !raterStatus.minimumInvitedCountMet
    );
  }

  toggle() {
    this.state = this.state === 'closed' ? 'opened' : 'closed';
  }
}
