import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TabbedLandingComponent } from './tabbed-landing.component';
import { AuthGuard } from '../../shared/guards/auth.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'tabbed',
        component: TabbedLandingComponent,
        canActivate: [AuthGuard]
      }
    ])
  ],
  exports: [RouterModule]
})
export class TabbedLandingRoutingModule {}
