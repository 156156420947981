import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { Store } from '@ngxs/store';

import { MatTableDataSource } from '@angular/material/table';

import { RaterStatus, Rater } from '../../store/rater-management.models';
import { ExpandRaters } from '../../store/rater-management.actions';

@Component({
  selector: 'assess-rater-management-rater-card',
  templateUrl: './rater-management-rater-card.component.html',
  styleUrls: ['./rater-management-rater-card.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('disclosureState', [
      state('closed', style({ transform: 'rotate(0)' })),
      state('opened', style({ transform: 'rotate(-180deg)' })),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in')),
    ]),
    trigger('collapsedState', [
      state(
        'closed',
        style({ height: '0', 'overflow-y': 'hidden', padding: '0' })
      ),
      state(
        'opened',
        style({ height: '*', 'overflow-y': 'hidden', padding: '0' })
      ),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in')),
    ]),
  ],
})
export class RaterManagementRaterCardComponent implements OnInit {
  @Input()
  raterStatus: RaterStatus;

  @Output()
  addRatersClick: EventEmitter<RaterStatus> = new EventEmitter<RaterStatus>();
  @Output()
  remindRatersClick: EventEmitter<RaterStatus> = new EventEmitter<
    RaterStatus
  >();

  @Output()
  deleteRaterClick: EventEmitter<Rater> = new EventEmitter<Rater>();
  @Output()
  resendInvitationClick: EventEmitter<any> = new EventEmitter<any>();

  state: string = 'closed';

  dataSource: MatTableDataSource<Rater> = new MatTableDataSource<Rater>();

  displayedColumns: string[] = ['name', 'email', 'language', 'actions'];

  constructor(private _store: Store) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<Rater>(this.raterStatus.raters);
    this.state = this.raterStatus.isRatersExpanded ? 'opened' : 'closed';
  }

  onAddRatersClick(): void {
    this.addRatersClick.emit(this.raterStatus);
  }

  onDeleteRaterClick(rater: Rater): void {
    this.deleteRaterClick.emit(rater);
  }

  onRemindRaters(): void {
    this.remindRatersClick.emit(this.raterStatus);
  }

  onResendInvitationClick(rater: Rater): void {
    this.resendInvitationClick.emit({
      raterStatus: this.raterStatus,
      rater: rater,
    });
  }

  toggle() {
    this.state = this.state === 'closed' ? 'opened' : 'closed';
    this._store.dispatch(
      new ExpandRaters(this.raterStatus, this.state !== 'closed')
    );
  }
}
