import { Directive } from '@angular/core';

import { SurveyStepperService } from './survey-stepper.service';

@Directive({
  selector: 'button[apSurveyStepperNext]',
  host: { '(click)': '_surveyStepperService.next("next-section")' }
})
export class SurveyStepperNext {
  constructor(private _surveyStepperService: SurveyStepperService) {}
}

@Directive({
  selector: 'button[apSurveyStepperPrevious]',
  host: { '(click)': '_surveyStepperService.previous("previous-section")' }
})
export class SurveyStepperPrevious {
  constructor(private _surveyStepperService: SurveyStepperService) {}
}
