import { Component, Input } from '@angular/core';

@Component({
  selector: 'assess-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent {
  @Input() warn: boolean = false;
  @Input() message: string;
}
