import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { ApplicationState } from '../../store/application.state';
import { User } from '../../core/auth/user.model';

import { Observable, Subscription } from 'rxjs';
import { BackgroundLanding } from './store/background-landing.models';

import { BackgroundResponseDocument } from './store/background-landing.models';
import { BackgroundItemCategoryComponent } from './containers/background-item-category/background-item-category.component';
import { BackgroundLandingState } from './store/background-landing.state';

import {
  SaveBackground,
  SaveBackgroundSuccess,
} from './store/background-landing.actions';

import { NavigateHome } from '../../store/application.actions';

@Component({
  selector: 'assess-background-landing',
  templateUrl: './background-landing.component.html',
  styleUrls: ['./background-landing.component.scss'],
})
export class BackgroundLandingComponent implements OnInit, OnDestroy {
  @ViewChildren(BackgroundItemCategoryComponent) categories: QueryList<
    BackgroundItemCategoryComponent
  >;

  @Output()
  loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Select(BackgroundLandingState.getBackgroundLanding)
  backgroundLanding$: Observable<BackgroundLanding>;

  saving: boolean = false;
  invalid: boolean = false;

  private _user: User;

  private _loadingSubscription: Subscription;

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this._user = this._store.selectSnapshot(ApplicationState.getUser);

    this._loadingSubscription = this._actions$
      .pipe(ofActionSuccessful(SaveBackgroundSuccess))
      .subscribe(() => {
        this.loading.emit(false);
        this.openSnackBar();
      });
  }

  ngOnDestroy(): void {
    if (this._loadingSubscription) {
      this._loadingSubscription.unsubscribe();
    }
  }

  onValidationStatusChanged(status: any): void {
    this.invalid = status === 'INVALID';
  }

  openSnackBar() {
    this._snackBar.open(
      this._translateService.instant('backgroundLanding.snackBar.saved')
    );
  }

  save(): void {
    let backgroundResponseDocument: BackgroundResponseDocument = {
      individualId: this._user.individualId,
      languageId: this._user.preferredLanguageId,
      responses: [],
    };

    // get all the responses and package them up to be saved
    this.categories.forEach((instance) => {
      let responses = instance.getResponses();
      backgroundResponseDocument.responses = backgroundResponseDocument.responses.concat(
        responses
      );
    });

    this.loading.emit(true);
    this._store.dispatch(new SaveBackground(backgroundResponseDocument));
  }

  skip(): void {
    this._store.dispatch(new NavigateHome(true));
  }
}
