import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { SurveySection } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-previous-step-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-previous-step-button.component.html'
})
export class SurveyPreviousStepButtonComponent {
  @Input() surveySection: SurveySection;
}
