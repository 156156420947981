<div fxLayout="column" class="survey header">
  <div fxLayout fxLayoutAlign="start start">
    <div fxFlex="40px" fxFlex.gt-xs="60px" ngStyle.gt-xs="padding-left: 10px;">
      <assess-survey-menu></assess-survey-menu>
    </div>
    <div fxFlex="85%" *ngIf="(surveySection$ | async) as surveySection">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start" style="padding: 10px 0 5px 0;">
        <div class="mat-title compressed">
          <span tabindex="0">{{surveySection.heading}}</span>&nbsp;&nbsp;<i class="fa fa-info-circle survey info-button" (click)="onInfoButtonClick()" (keypress)="onInfoButtonClick()" tabindex="0" aria-label="Survey Additional Information"></i>
        </div>
        <div *ngFor="let subheading of surveySection.subheadings" class="muted text-center" style="width: 100%" fxShow
          fxHide.lt-md="true" [innerHtml]="subheading" tabindex="0"></div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>