import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  QuestionAnswer,
  Option,
  QuestionListItem,
} from '../../../../../survey/store/survey.model';

import { SurveyDocumentState } from '../../../../../survey/store/survey.state';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'assess-multi-edit',
  templateUrl: './multi-edit.component.html',
})
export class MultiEditComponent implements OnInit, OnDestroy {
  @Input()
  option: Option;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  questionListItem$: Observable<QuestionListItem>;

  lastValue: string;

  response: FormControl;

  private _questionListItemSubscription: Subscription;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.option.formItemId)));

    this.response = new FormControl(null, { updateOn: 'blur' });

    this._questionListItemSubscription = this.questionListItem$.subscribe(
      (questionListItem: QuestionListItem) => {
        const optionAnswer = this.option ? questionListItem.answer : null;

        this.response.setValue(optionAnswer, { emitEvent: false });
      }
    );

    this.response.valueChanges
      .pipe(
        map((value: any) => {
          const questionAnswer: QuestionAnswer = {
            formItemId: this.option.formItemId,
            optional: true,
            formItemSequenceNumber: this.option.formItemSequenceNumber,
            answeredTimeMs: new Date().getTime(),
            answer: value,
            previousAnswer: this.lastValue,
          };
          this.lastValue = value;

          this.questionAnswered.emit(questionAnswer);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._questionListItemSubscription.unsubscribe();
  }
}
