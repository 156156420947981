<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div fxLayout fxLayoutAlign="center" style="height: 100%;">
    <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column">
      <div fxFlex style="padding: 20px; background-color: white; overflow-y:auto;">
        <div fxShow fxHide.lt-md="true" fxLayout="column" fxLayoutGap="20px">
          <assess-survey-section-header [surveySection]="surveySection"></assess-survey-section-header>
          <div fxLayout="row" fxLayoutgap="20px">
            <assess-survey-instruction [surveySection]="surveySection"></assess-survey-instruction>
            <div fxFlex="0 0 50%" *ngIf="surveySection.extensions" style="text-align: center;">
              <img [src]="marketingImageSource" class="responsive-image" alt="Survey Completion Confirmation">
            </div>
          </div>
        </div>
        <div fxHide fxShow.lt-md="true" fxLayout="column" fxLayoutGap="20px">
          <assess-survey-section-header [surveySection]="surveySection"></assess-survey-section-header>
          <div fxFlex="0 0 50%" *ngIf="surveySection.extensions" style="text-align: center;">
            <img [src]="marketingImageSource" class="responsive-image" alt="Survey Completion Confirmation ">
          </div>
          <assess-survey-instruction [surveySection]="surveySection"></assess-survey-instruction>
        </div>
        <div class="button-panel" fxLayout="column" fxLayoutAlign="start center">
          <button mat-raised-button apNoDblClickMat color="primary" (click)="onExitClick()">{{surveySection.nextButtonText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>