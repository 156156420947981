import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HypermediaService } from '../../../core/hypermedia/hypermedia.service';
import { HypermediaFactoryService } from '../../../core/hypermedia/hypermedia-factory.service';

import { Config } from '../../../store/application.models';

@Injectable({
  providedIn: 'root',
})
export class ReportViewerService {
  private _hypermediaService: HypermediaService = null;

  public constructor(
    private _store: Store,
    private _hypermediaFactoryService: HypermediaFactoryService
  ) {}

  initializeService(): void {
    if (this._hypermediaService === null) {
      const config: Config = this._store.selectSnapshot(
        (state) => state.application.config
      );

      this._hypermediaService = this._hypermediaFactoryService.create(
        config.assessmentWebApi,
        'participant-landing'
      );
    }
  }

  getParticipantAsset(
    individualId: number,
    assetInfoId: number,
    latestVersion: string
  ): Observable<Blob> {
    this.initializeService();
    return this._hypermediaService
      .get(
        'get-asset',
        {
          individualId: individualId,
          assetInfoId: assetInfoId,
          version: latestVersion,
        },
        {
          responseType: 'blob',
        }
      )
      .pipe(map((r: Blob) => new Blob([r], { type: r.type })));
  }

  getParticipantDocument(
    participantId: number,
    documentId: number
  ): Observable<Blob> {
    this.initializeService();
    return this._hypermediaService
      .get(
        'get-document',
        {
          participantId: participantId,
          documentId: documentId,
        },
        {
          responseType: 'blob',
        }
      )
      .pipe(map((r: Blob) => new Blob([r], { type: r.type })));
  }
}
