import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from "@angular/core";

import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { Store } from "@ngxs/store";

import {
  Question,
  SurveyScale,
  QuestionAnswer,
  QuestionListItem,
  Option
} from "../../store/survey.model";

import { SurveyDocumentState } from "../../store/survey.state";

interface FormItemAnswerMapper {
  formItemId: number;
  answer: string;
  previousAnswer: string;
}

@Component({
  selector: "assess-survey-multi-scales-question",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./survey-multi-scales-question.component.html"
})
export class SurveyMultiScalesQuestionComponent implements OnInit, OnDestroy {
  @Input()
  surveyScale: SurveyScale;
  @Input()
  question: Question;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  questionListItems: Observable<QuestionListItem>[] = [];
  questionListItemsSubscription: Subscription[] = [];

  formItemAnswerMappers: FormItemAnswerMapper[] = [];

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.question.options.forEach((option: Option) => {
      let questionListItemObservable: Observable<QuestionListItem> = this._store
        .select(SurveyDocumentState.getQuestionAnswer)
        .pipe(map(filterFn => filterFn(option.formItemId)));

      this.questionListItems.push(questionListItemObservable);

      this.questionListItemsSubscription.push(
        questionListItemObservable.subscribe(
          (questionListItem: QuestionListItem) => {
            let formItemAnswerMapper: FormItemAnswerMapper = this.formItemAnswerMappers.find(
              (formItemAnswerMapper: FormItemAnswerMapper) =>
                formItemAnswerMapper.formItemId === questionListItem.formItemId
            );

            if (formItemAnswerMapper) {
              formItemAnswerMapper.answer = questionListItem.answer;
            } else {
              this.formItemAnswerMappers.push({
                formItemId: questionListItem.formItemId,
                answer: questionListItem.answer,
                previousAnswer: questionListItem.answer
              });
            }
          }
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.questionListItemsSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  onScaleSelected(value: string, index: number): void {
    let formItemAnswerMapper: FormItemAnswerMapper = this.formItemAnswerMappers[
      index
    ];

    let previousAnswer: string = formItemAnswerMapper.previousAnswer;
    formItemAnswerMapper.answer = value !== "-1" ? value : null;

    let question: QuestionAnswer = Object.assign(
      {},
      {
        formItemId: this.question.options[index].formItemId,
        formItemSequenceNumber: this.question.options[index]
          .formItemSequenceNumber,
        optional: this.question.optional,
        previousAnswer: previousAnswer,
        answer: value
      }
    );
    this.questionAnswered.emit(question);
  }

  answered(): boolean {
    let answers: string[] = this.formItemAnswerMappers
      .filter(
        (formItemAnswerMapper: FormItemAnswerMapper) =>
          formItemAnswerMapper.answer
      )
      .map(
        (formItemAnswerMapper: FormItemAnswerMapper) =>
          formItemAnswerMapper.answer
      );

    return answers.length === this.question.options.length;
  }

  getQuestionListItem(index: number): Observable<QuestionListItem> {
    return this.questionListItems[index];
  }
}
