import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  transition,
  style,
  animate
} from '../../../../../../node_modules/@angular/animations';
import { Store } from '@ngxs/store';

import { SurveyStatus } from '../../models/rater-landing.model';
import { Config } from '../../../../store/application.models';
import { ApplicationState } from '../../../../store/application.state';

@Component({
  selector: 'assess-survey-card',
  templateUrl: './survey-card.component.html',
  styleUrls: ['./survey-card.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('disclosureState', [
      state('closed', style({ transform: 'rotate(0)' })),
      state('opened', style({ transform: 'rotate(-180deg)' })),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in'))
    ]),
    trigger('collapsedState', [
      state(
        'closed',
        style({ height: '0', 'overflow-y': 'hidden', padding: '0' })
      ),
      state(
        'opened',
        style({ height: '*', 'overflow-y': 'hidden', padding: '0' })
      ),
      transition('opened => closed', animate('250ms ease-out')),
      transition('closed => opened', animate('250ms ease-in'))
    ])
  ]
})
export class SurveyCardComponent {
  @Input()
  surveyStatus: SurveyStatus;

  @Output()
  surveyClick: EventEmitter<SurveyStatus> = new EventEmitter<SurveyStatus>();

  state: string = 'closed';

  public assessmentsUri;
  public assessments2Uri;

  constructor(private _store: Store) {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );

    this.assessmentsUri = config.authRedirectUri;
    this.assessments2Uri = config.assessments2Uri;
  }

  onSurveyClick(): void {
    switch (this.surveyStatus?.instrument.instrumentId) {
      case 1913: // Skillscope
      case 1892: // Benchmarks for Managers
      case 1929: // CDP Indivial
      case 689: // CDP
        window.location.href = `${this.assessments2Uri}/survey?id=${this.surveyStatus?.instrument.instrumentId}&pid=${this.surveyStatus?.participant.participantId}&retUrl=${this.assessmentsUri}&surveyType=1`;
        break;

      default:
        this.surveyClick.emit(this.surveyStatus);
        break;
    }
    
  }

  toggle(): void {
    this.state = this.state === 'closed' ? 'opened' : 'closed';
  }
}
