import { Component, Input, Output, EventEmitter } from '@angular/core';

import { QuestionGroup, QuestionAnswer } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-a-and-b-questions-group',
  templateUrl: './survey-a-and-b-questions-group.component.html'
})
export class SurveyAAndBQuestionsGroupComponent {
  @Input()
  questionGroup: QuestionGroup;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  onQuestionAnswered(question: QuestionAnswer): void {
    this.questionAnswered.emit(question);
  }
}
