import { FormArray } from '@angular/forms';

import { Rater } from '../../../store/rater-management.models';

export class RaterValidator {
  static required(c: FormArray) {
    const controls = c.getRawValue();

    let allEmpty = controls
      .map((item: Rater) => {
        return (
          (!item.email || item.email.trim() === '') &&
          (!item.firstName || item.firstName.trim() === '') &&
          (!item.lastName || item.lastName.trim() === '') &&
          (!item.language || item.language === null)
        );
      })
      .reduce((acc, cur) => acc && cur);

    let allValid = controls
      .map((item: Rater) => {
        return (
          ((!item.email || item.email.trim() === '') &&
            (!item.firstName || item.firstName.trim() === '') &&
            (!item.lastName || item.lastName.trim() === '') &&
            (!item.language || item.language === null)) ||
          (item.email &&
            item.email.trim() !== '' &&
            item.firstName &&
            item.firstName.trim() !== '' &&
            item.lastName &&
            item.lastName.trim() !== '' &&
            item.language &&
            item.language !== null)
        );
      })
      .reduce((acc, cur) => acc && cur);

    return !allEmpty && allValid ? null : { required: true };
  }
}
