<div style="padding: 5px 10px;" fxLayout="column">
  <div>{{backgroundItem.questionNumber}}. {{backgroundItem.backgroundItemText.text}}&nbsp;&nbsp;<span
      *ngIf="backgroundItem.important"><i class="fa fa-exclamation-triangle text-warning"></i></span></div>
  <div class="text-muted">{{backgroundItem.backgroundItemText.subtext}}</div>
  <div>
    <mat-form-field class="full-width" [floatLabel]="'never'">
      <textarea matInput [attr.id]="backgroundItem.backgroundItemId" [formControl]="response" autocomplete="nope"
        matTextareaAutosize matAutosizeMinRows="3" matAutosizeMaxRows="7"></textarea>
      <mat-error *ngIf="response.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>
</div>