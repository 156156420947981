import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SectionReviewCard, SurveySection } from '../../store/survey.model';

import { SurveyDocumentState } from '../../store/survey.state';
import {
  ReviewSection,
  SubmitSurvey,
  ReviewQuestion,
  RefreshAnswers,
  RefreshAnswersSuccess,
} from '../../store/survey.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'assess-survey-review-questions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-review-questions.component.html',
  styleUrls: ['./survey-review-questions.component.scss'],
})
export class SurveyReviewQuestionsComponent implements OnInit, OnDestroy {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  @Select(SurveyDocumentState.getSectionReviewCards)
  sectionReviewCards$: Observable<SectionReviewCard[]>;

  @Select(SurveyDocumentState.getAnsweringInProgress)
  answeringInProgress$: Observable<boolean>;

  canSubmit$: Observable<any>;

  loading: boolean = false;

  private _refreshAnswersSuccessSubscription: Subscription;
  private _answeringInProgressSubscription: Subscription;

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _changeDectectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Send the refresh answers here so validation is done with the answers
    // the back end actually knows about. This covers out of sync
    // issues between the UI and the back end
    this._refreshAnswersSuccessSubscription = this._actions$
      .pipe(ofActionSuccessful(RefreshAnswersSuccess))
      .subscribe(() => {
        this.loading = false;
        this._changeDectectorRef.detectChanges();
      });

    this.canSubmit$ = this._store
      .select(SurveyDocumentState.canSubmit)
      .pipe(switchMap((canSubmit) => canSubmit));

    this.loading = true;

    this._answeringInProgressSubscription = this.answeringInProgress$.subscribe(
      (inProgress: boolean) => {
        if (!inProgress) {
          this._store.dispatch(new RefreshAnswers());
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._refreshAnswersSuccessSubscription.unsubscribe();
    this._answeringInProgressSubscription.unsubscribe();
  }

  onQuestionNumberClicked(question: any) {
    this._store.dispatch(
      new ReviewQuestion(question.sectionId, question.formItemId)
    );
  }

  onSectionReviewCardClicked(sectionId: string): void {
    this._store.dispatch(new ReviewSection(sectionId));
  }

  onSubmitSurvey(): void {
    this.loading = true;
    this._store.dispatch(new SubmitSurvey());
  }
}
