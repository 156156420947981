import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { NgxLoadingModule } from 'ngx-loading';

import { SharedModule } from '../../shared/shared.module';
import { ParticipantLandingRoutingModule } from './participant-landing-routing.module';
import { ParticipantLandingComponent } from './participant-landing.component';
import { InstrumentStatusComponent } from './components/instrument-status/instrument-status.component';
import { DocumentStatusComponent } from './components/document-status/document-status.component';
import { ProgramCardComponent } from './components/program-card/program-card.component';
import {AboutmeStatusComponent} from './components/aboutme-status/aboutme-status.component'

@NgModule({
  imports: [
    CommonModule,
    ParticipantLandingRoutingModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    NgxLoadingModule,
    SharedModule,
  ],
  declarations: [
    ParticipantLandingComponent,
    InstrumentStatusComponent,
    DocumentStatusComponent,
    ProgramCardComponent,
    AboutmeStatusComponent,
  ],
  exports: [ParticipantLandingComponent],
})
export class ParticipantLandingModule {}
