import { Component, Input, Output, EventEmitter } from '@angular/core';

import { InstrumentStatus } from '../../models/participant.model';

@Component({
  selector: 'assess-instrument-status',
  templateUrl: './instrument-status.component.html'
})
export class InstrumentStatusComponent {
  @Input() highlight: boolean;
  @Input() instrumentStatus: InstrumentStatus;

  @Output()
  ratersClick: EventEmitter<InstrumentStatus> = new EventEmitter<
    InstrumentStatus
  >();

  @Output()
  selfClick: EventEmitter<InstrumentStatus> = new EventEmitter<
    InstrumentStatus
  >();

  @Output()
  viewAssetClick: EventEmitter<InstrumentStatus> = new EventEmitter<
    InstrumentStatus
  >();

  onRatersClick(): void {
    this.ratersClick.emit(this.instrumentStatus);
  }

  onSelfClick(): void {
    this.selfClick.emit(this.instrumentStatus);
  }

  onViewAssetClick(): void {
    this.viewAssetClick.emit(this.instrumentStatus);
  }
}
