<div *ngIf="(surveySection$ | async) as surveySection" style="height: 100%;">
  <div *ngIf="(complete$ | async) as complete" style="height: 100%;">
    <div fxLayout fxLayoutAlign="center" style="height: 100%;">
      <div fxFlex.lt-md="100" fxFlex="0 0 700px" fxLayout="column">
        <assess-survey-section-title>
          <div class="text-center">
            <span class="text-bold" tabindex="0">{{questionsSelected$ | async}} /
              {{numberRequired$ | async}}
              {{getExtensionValue('selected')}}</span>
          </div>
        </assess-survey-section-title>
        <div fxFlex style="background-color: white; overflow-y: auto;">
          <assess-survey-competencies-question *ngFor="let question of surveySection.questionGroups[0].questions"
            [question]="question" (compatencyQuestionChange)="onCompetencyQuestionChange($event)" [complete]="complete.complete"></assess-survey-competencies-question>
        </div>
        <div class="survey button-panel" fxLayout="row" fxLayoutAlign="space-between center" #buttonPanel>
          <assess-survey-previous-step-button [surveySection]="surveySection"></assess-survey-previous-step-button>
          <assess-survey-next-step-button [surveySection]="surveySection"></assess-survey-next-step-button>
        </div>
      </div>
    </div>
  </div>
</div>