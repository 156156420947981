<div fxLayout="column" fxLayoutGap="20px" [id]="question.formItemId" style="padding: 10px;">
  <div fxLayout="row" fxLayoutAlign="start start" [ngClass]="{'not-answered': !isAnswered}">
    <span fxFlex="0 0 20px">
      <i class="fa fa-star text-success" *ngIf="isAnswered"></i>
    </span>
    <p style="margin: 0;" fxLayout="row" fxLayoutGap="5px">
      <span>{{question.number}}.</span>
      <span [innerHtml]="question.text" tabindex="0"></span>
    </p>
  </div>
  <div fxFlexAlign="center">
    <assess-survey-scale (scaleSelected)="onScaleSelected($event)" [surveyScale]="surveyScale" [question]="question"
      [questionListItem]="questionListItem$ | async"></assess-survey-scale>
  </div>
</div>