import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';

import { Store } from '@ngxs/store';

import {
  Question,
  AnswerGroupItem,
  QuestionAnswer,
  Extension,
  Option,
  QuestionListItem,
} from '../../store/survey.model';
import { Observable, observable, Subscription } from 'rxjs';
import { SurveyDocumentState } from '../../store/survey.state';
import { map } from 'rxjs/operators';

interface FormItemAnswerMapper {
  formItemId: number;
  answer: string;
  previousAnswer: string;
}

@Component({
  selector: 'assess-survey-multi-dropdown-question',
  templateUrl: './survey-multi-dropdown-question.component.html',
})
export class SurveyMultiDropdownQuestionComponent implements OnInit {
  @Input()
  question: Question;

  @Input()
  answerGroup: AnswerGroupItem[];

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer[]> = new EventEmitter<
    QuestionAnswer[]
  >();

  questionListItems: Observable<QuestionListItem>[] = [];
  questionListItemsSubscription: Subscription[] = [];

  formItemAnswerMappers: FormItemAnswerMapper[] = [];

  constructor(private _store: Store) {}

  ngOnInit() {
    this.question.options.forEach((option: Option) => {
      let questionListItemObservable: Observable<QuestionListItem> = this._store
        .select(SurveyDocumentState.getQuestionAnswer)
        .pipe(map((filterFn) => filterFn(option.formItemId)));

      this.questionListItems.push(questionListItemObservable);

      this.questionListItemsSubscription.push(
        questionListItemObservable.subscribe(
          (questionListItem: QuestionListItem) => {
            let formItemAnswerMapper: FormItemAnswerMapper = this.formItemAnswerMappers.find(
              (formItemAnswerMapper: FormItemAnswerMapper) =>
                formItemAnswerMapper.formItemId === questionListItem.formItemId
            );

            if (formItemAnswerMapper) {
              formItemAnswerMapper.answer = questionListItem.answer;
            } else {
              this.formItemAnswerMappers.push({
                formItemId: questionListItem.formItemId,
                answer: questionListItem.answer,
                previousAnswer: questionListItem.answer,
              });
            }
          }
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.questionListItemsSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  onSelectionChanged(event: MatSelectChange, index: number): void {
    this.formItemAnswerMappers[index].answer =
      event.value !== '-1' ? event.value : null;

    let answers = this.formItemAnswerMappers.filter(
      (formItemAnswerMapper: FormItemAnswerMapper) =>
        formItemAnswerMapper.answer && formItemAnswerMapper.answer.length > 0
    );

    // if answers.length = 4
    if (answers.length === 4) {
      // build up all four answers and emit the answers
      let questionAnswers: QuestionAnswer[] = [];
      for (let i = 0; i < this.question.options.length; i++) {
        questionAnswers.push(
          Object.assign(
            {},
            {
              formItemId: this.question.options[i].formItemId,
              formItemSequenceNumber: this.question.options[i]
                .formItemSequenceNumber,
              optional: this.question.optional,
              previousAnswer: this.formItemAnswerMappers[i].previousAnswer,
              answer: this.formItemAnswerMappers[i].answer,
            }
          )
        );
      }
      this.questionAnswered.emit(questionAnswers);
    }
  }

  answered(): boolean {
    let answers: string[] = this.formItemAnswerMappers
      .filter(
        (formItemAnswerMapper: FormItemAnswerMapper) =>
          formItemAnswerMapper.answer && formItemAnswerMapper.answer.length > 0
      )
      .map(
        (formItemAnswerMapper: FormItemAnswerMapper) =>
          formItemAnswerMapper.answer
      );

    return (
      answers.length === 4 && answers.every((e, i, a) => a.indexOf(e) === i)
    );
  }

  valid(): boolean {
    let answers: string[] = this.formItemAnswerMappers
      .filter(
        (formItemAnswerMapper: FormItemAnswerMapper) =>
          formItemAnswerMapper.answer && formItemAnswerMapper.answer.length > 0
      )
      .map(
        (formItemAnswerMapper: FormItemAnswerMapper) =>
          formItemAnswerMapper.answer
      );

    return (
      answers.length < 4 ||
      (answers.length === 4 && answers.every((e, i, a) => a.indexOf(e) === i))
    );
  }

  getAnswer(index: number): string {
    return this.formItemAnswerMappers[index].answer;
  }

  getExtensionValue(name: string): string {
    const extension: Extension = this.question.extensions.find(
      (extension: Extension) => extension.name === name
    );

    return extension ? extension.value : null;
  }
}
