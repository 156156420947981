<div style="padding: 10px;" [id]="question.formItemId">
  <div>
    <span *ngIf="question.number" tabindex="0">{{ question.number }}. </span><span [innerHtml]="question.text" tabindex="0"></span>
  </div>
  <div *ngIf="question.subtext" class="text-muted" [innerHtml]="question.subtext" tabindex="0"></div>
  <mat-form-field class="full-width">
    <textarea matInput #message [maxLength]="question.maxLength" [placeholder]="getPlaceholder()" matTextareaAutosize
      matAutosizeMinRows="2" matAutosizeMaxRows="7" [formControl]="directFeedbackControl" aria-label="Please enter your response"></textarea>
    <mat-hint align="end">{{message.value?.length || 0}} / {{ question.maxLength }}</mat-hint>
  </mat-form-field>
</div>