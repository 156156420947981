import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Config, Environment } from './store/application.models';

@Injectable()
export class AppConfigService {
  constructor(private _httpClient: HttpClient) {}

  public loadEnvironment(): Observable<Environment> {
    return this._httpClient.get('assets/configuration/assess.env.json').pipe(
      map((envResponse: any) => envResponse),
      catchError((): any => {
        console.log('Configuration file "env.json" could not be read');
        return throwError('Configuration file "env.json" could not be read');
      })
    );
  }

  private padStr(i: any) {
    return i < 10 ? '0' + i : '' + i;
  }

  // Gets the date string to the second to prevent caching of settings files
  private getDateString() {
    let timestamp = new Date();
    var dateStr =
      this.padStr(timestamp.getFullYear()) +
      this.padStr(1 + timestamp.getMonth()) +
      this.padStr(timestamp.getDate()) +
      this.padStr(timestamp.getHours()) +
      this.padStr(timestamp.getMinutes()) +
      this.padStr(timestamp.getSeconds());
    return dateStr;
  }

  public loadConfig(environment: string): Observable<Config> {
    var dateStr = this.getDateString();

    let request: string = '';
    switch (environment) {
      case 'prod':
      case 'uat':
      case 'qa':
      case 'dev':
      case 'localhost':
        {
          request = `assets/configuration/assess.config.${environment}.json?ts=${dateStr}`;
          console.log('request', request);
        }
        break;
      case 'default':
        {
          console.error('Environment file is not set or invalid');
        }
        break;
    }

    return this._httpClient.get(request).pipe(
      map((config: any) => config),
      catchError((): any => {
        console.log(`Error reading ${environment} configuration file`);
        return throwError(`Error reading ${environment} configuration file`);
      })
    );
  }
}
