import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';

import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxLoadingModule } from 'ngx-loading';

import { SharedModule } from '../../shared/shared.module';
import { RaterManagementRoutingModule } from './rater-management-routing.module';

import { RaterManagementService } from './services/rater-management.service';

import { RaterManagementState } from './store/rater-management.state';

import { RaterManagementComponent } from './rater-management.component';
import { RaterManagementRaterCardComponent } from './components/rater-management-rater-card/rater-management-rater-card.component';
import { RaterTypeLabelComponent } from './components/rater-type-label/rater-type-label.component';
import { AddRaterModule } from './modules/add-rater/add-rater.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatTableModule,
    NgxsModule.forFeature([RaterManagementState]),
    NgxLoadingModule,
    SharedModule,
    RaterManagementRoutingModule,
    AddRaterModule,
  ],
  declarations: [
    RaterManagementComponent,
    RaterManagementRaterCardComponent,
    RaterTypeLabelComponent,
  ],
  providers: [RaterManagementService],
})
export class RaterManagementModule {}
