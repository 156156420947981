import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  SurveyScale,
  Question,
  QuestionListItem,
} from '../../store/survey.model';

@Component({
  selector: 'assess-survey-scale',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-scale.component.html',
})
export class SurveyScaleComponent implements OnInit {
  @Input()
  surveyScale: SurveyScale;
  @Input()
  question: Question;
  @Input()
  questionListItem: QuestionListItem;

  @Output()
  scaleSelected: EventEmitter<string> = new EventEmitter<string>();

  selected: string = null;

  notApplicableChecked: boolean = false;

  ngOnInit(): void {
    if (this.questionListItem.answer && this.questionListItem.answer) {
      if (
        this.surveyScale.notApplicableButton &&
        this.questionListItem.answer ===
          this.surveyScale.notApplicableButton.buttonValue
      ) {
        this.notApplicableChecked = true;
      }
      this.selected = this.questionListItem.answer;
    }
  }

  onAnswer(value: string): void {
    this.selected =
      this.selected === null ? value : this.selected !== value ? value : null;

    this.notApplicableChecked = false;
    this.scaleSelected.emit(this.selected);
  }

  onNotApplicable(value: MatCheckboxChange): void {
    this.selected = value.checked
      ? this.surveyScale.notApplicableButton.buttonValue
      : null;
    this.notApplicableChecked = value.checked;
    this.scaleSelected.emit(this.selected);
  }
}
