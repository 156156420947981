import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, Validators, ValidatorFn } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  QuestionListItem,
  QuestionAnswer,
  ResponseValidator,
} from '../../../../../survey/store/survey.model';

import { SurveyDemographicsService } from '../../services/survey-demographics.service';
import { SurveyDocumentState } from '../../../../../survey/store/survey.state';

@Component({
  selector: 'assess-single-edit',
  templateUrl: './single-edit.component.html',
})
export class SingleEditComponent implements OnInit, OnDestroy {
  @Input() question: Question;
  @Input() index: number;

  questionListItem$: Observable<QuestionListItem>;

  response: FormControl;

  lastValue: string;

  private _questionListItemSubscription: Subscription;

  constructor(
    private _store: Store,
    private _surveyDemographicsService: SurveyDemographicsService
  ) {}

  ngOnInit(): void {
    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.question.formItemId)));

    this.response = new FormControl('', {
      validators: this.getValidators(),
      updateOn: 'blur',
    });

    this._questionListItemSubscription = this.questionListItem$.subscribe(
      (questionListItem: QuestionListItem) => {
        this.response.setValue(questionListItem.answer, { emitEvent: false });
      }
    );

    this.response.valueChanges
      .pipe(
        map((value: any) => {
          if (this.response.valid) {
            const questionAnswer: QuestionAnswer = {
              formItemId: this.question.formItemId,
              optional: true,
              formItemSequenceNumber: this.question.formItemSequenceNumber,
              answeredTimeMs: new Date().getTime(),
              answer: value,
              previousAnswer: this.lastValue,
            };
            this.lastValue = value;

            this._surveyDemographicsService.answerQuestion(questionAnswer);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._questionListItemSubscription.unsubscribe();
  }

  getErrorMessage(): string {
    let validator: ResponseValidator = null;

    if (this.response.hasError('min')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'min'
      );
    } else if (this.response.hasError('max')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'max'
      );
    } else if (this.response.hasError('required')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'required'
      );
    } else if (this.response.hasError('email')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'email'
      );
    } else if (this.response.hasError('minLength')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'minLength'
      );
    } else if (this.response.hasError('maxLength')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'maxLength'
      );
    } else if (this.response.hasError('pattern')) {
      validator = this.question.validators.find(
        (validator: ResponseValidator) => validator.name === 'pattern'
      );
    }

    return validator !== null ? validator.message : null;
  }

  getValidators(): ValidatorFn[] {
    let validators: ValidatorFn[] = [];

    if (this.question.validators !== null) {
      this.question.validators.forEach((validator: ResponseValidator) => {
        switch (validator.name) {
          case 'min': {
            validators.push(Validators.min(validator.parameters[0]));
            break;
          }
          case 'max': {
            validators.push(Validators.max(validator.parameters[0]));
            break;
          }
          case 'required': {
            validators.push(Validators.required);
            break;
          }
          case 'email': {
            validators.push(Validators.email);
            break;
          }
          case 'minLength': {
            validators.push(Validators.minLength(validator.parameters[0]));
            break;
          }
          case 'maxLength': {
            validators.push(Validators.maxLength(validator.parameters[0]));
            break;
          }
          case 'pattern': {
            validators.push(Validators.pattern(validator.parameters[0]));
            break;
          }
        }
      });
    }

    return validators;
  }
}
