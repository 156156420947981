import {
  Config,
  Environment,
  Exception,
  Application
} from './application.models';
import { User } from '../core/auth/user.model';
import { Profile } from '../features/my-profile/models/my-profile.model';

// actions
export class InitializeTranslateService {
  static readonly type = '[Application Startup] Initialize Translate Service';
}

export class LoadEnvironment {
  static readonly type = '[Application Startup] Load Environment';
}

export class LoadConfig {
  static readonly type = '[Application Startup] Load Config';
}

export class Authenticate {
  static readonly type = '[Grand Central] Authenticate';
}

export class LoadUser {
  static readonly type = '[Application Startup] Load User';
  constructor(public user: User) {}
}

export class LoadApplication {
  static readonly type = '[Assessment Web API] Load Application';
}

export class SetLanguage {
  static readonly type = '[Application Startup] Set Language';
}

export class LogDeviceSettings {
  static readonly type = '[Application Startup] Log Device Settings';
}

export class SetLandingTabIndex {
  static readonly type = '[Application] Set Landing Tab Index';
  constructor(public landingPageIndex: number) {}
}

export class NavigateHome {
  static readonly type = '[Application] Navigate Home';
  constructor(public skip: boolean = false) {}
}

export class AboutMeComplete {
  static readonly type = '[Application Startup] About Me Complete';
  constructor(public complete: boolean) {}
}

export class MyProfileSaved {
  static readonly type = '[Application] My Profile Saved';
  constructor(public profile: Profile) {}
}

// events
export class LoadEnvironmentSuccess {
  static readonly type = '[Application Startup] Load Environment Success';
  constructor(public env: Environment) {}
}

export class LoadConfigSuccess {
  static readonly type = '[Application Startup] Load Config Success';
  constructor(public config: Config) {}
}

export class AuthenticateSuccess {
  static readonly type = '[Grand Central] Authenticate Success';
  constructor(public user: User) {}
}

export class LoadApplicationSuccess {
  static readonly type = '[Assessment Web API] Load Application Success';
  constructor(public application: Application) {}
}

export class SetLanguageSuccess {
  static readonly type = '[Application Startup] Set Language Success';
}

export class ThrowException {
  static readonly type = '[Exception] Throw';
  constructor(public exception: Exception, public context: any) {}
}
