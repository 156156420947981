<div fxLayout="column" fxLayoutGap="5px" style="padding: 10px;">
  <div *ngIf="question.text; else noQuestion" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
    [id]="question.formItemId">
    <div fxLayout fxLayoutAlign="start start" [ngClass]="{'not-answered': !isAnswered}">
      <div fxFlex="0 0 20px">
        <i class="fa fa-star text-success" *ngIf="isAnswered"></i>
      </div>
      <p *ngIf="question.text" style="margin: 0;" fxLayout fxLayoutGap="5px">
        <span>{{question.number}}.</span>
        <span [innerHtml]="question.text" tabindex="0"></span>
      </p>
    </div>
    <div style="padding-left: 40px;">
      <mat-radio-group fxLayout="column" fxLayoutGap="7px" [ngModel]="answer">
        <mat-radio-button *ngFor="let option of question.options" [value]="option.value"
          (change)="onOptionChanged($event)">
          <span [innerHtml]="option.text" tabindex="1"></span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<ng-template #noQuestion>
  <div fxLayout fxLayoutAlign="start start" [id]="question.formItemId">
    <div fxFlex="0 0 20px">
      <i class="fa fa-star text-success" *ngIf="isAnswered"></i>
    </div>
    <div fxLayout fxLayoutGap="5px">
      <div>{{question.number}}.</div>
    </div>
    <div style="padding-left: 10px;">
      <mat-radio-group fxLayout="column" fxLayoutGap="7px" [ngModel]="answer">
        <mat-radio-button *ngFor="let option of question.options" [value]="option.value"
          (change)="onOptionChanged($event)">
          <span [innerHtml]="option.text"></span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</ng-template>