import { Component, Input, Output, EventEmitter } from '@angular/core';

import { InstrumentStatus } from '../../models/participant.model';

@Component({
  selector: 'assess-document-status',
  templateUrl: './document-status.component.html'
})
export class DocumentStatusComponent {
  @Input() highlight: boolean;
  @Input() instrumentStatus: InstrumentStatus;

  @Output()
  viewDocumentClick: EventEmitter<InstrumentStatus> = new EventEmitter<
    InstrumentStatus
  >();

  onViewDocumentClick(): void {
    this.viewDocumentClick.emit(this.instrumentStatus);
  }
}
