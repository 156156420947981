import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

import { Navigate } from '@ngxs/router-plugin';

import {
  ManageRaters,
  LoadRaterManagement,
  LoadRaterManagementSuccess,
  DeleteRater,
  ExpandRaters,
  RemindRaters,
  RemindRatersSuccess,
  ResendInvitation,
  ResendInvitationSuccess,
  RemindAllRaters,
} from './rater-management.actions';

import { InstrumentStatus } from '../../participant-landing/models/participant.model';

import {
  RaterManagement,
  RaterStatus,
  RaterStatusCardUiState,
  EmailRequest,
  Rater,
} from './rater-management.models';

import { ThrowException } from '../../../store/application.actions';

import { RaterManagementService } from '../services/rater-management.service';
import { User } from '../../../core/auth/user.model';
import { ApplicationState } from '../../../store/application.state';
import { Injectable } from '@angular/core';

export class RaterManagementStateModel {
  instrumentStatus: InstrumentStatus;
  raterManagement: RaterManagement;
  raterStatusCardUiState: RaterStatusCardUiState[];
}

@State<RaterManagementStateModel>({
  name: 'raterManagement',
  defaults: {
    instrumentStatus: null,
    raterManagement: null,
    raterStatusCardUiState: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class RaterManagementState {
  constructor(
    private _raterManagementService: RaterManagementService,
    private _store: Store
  ) {}

  @Selector()
  static getInstrumentStatus(
    state: RaterManagementStateModel
  ): InstrumentStatus {
    return state.instrumentStatus;
  }

  @Selector()
  static getRaterManagement(state: RaterManagementStateModel): RaterManagement {
    return state.raterManagement;
  }

  // actions
  @Action(ManageRaters)
  manageRaters(
    ctx: StateContext<RaterManagementStateModel>,
    action: ManageRaters
  ) {
    ctx.patchState({
      instrumentStatus: action.instrumentStatus,
      raterStatusCardUiState: [],
    });
    ctx.dispatch(new Navigate(['/rater-management']));
  }

  @Action(LoadRaterManagement)
  loadRaterManagement(ctx: StateContext<RaterManagementStateModel>) {
    const state = ctx.getState();
    const user: User = this._store.selectSnapshot(ApplicationState.getUser);

    this._raterManagementService
      .getRaterManagement(
        state.instrumentStatus.participantId,
        state.instrumentStatus.instrumentId,
        user.preferredLanguageId
      )
      .subscribe(
        (raterManagement: RaterManagement) =>
          ctx.dispatch(new LoadRaterManagementSuccess(raterManagement)),
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'loadRaterManagement',
                data: state.instrumentStatus,
              }
            )
          )
      );
  }

  @Action(DeleteRater)
  deleteRater(
    ctx: StateContext<RaterManagementStateModel>,
    action: DeleteRater
  ) {
    this._raterManagementService.deleteRater(action.rater).subscribe(
      () => ctx.dispatch(new LoadRaterManagement()),
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'deleteRater',
              data: action.rater,
            }
          )
        )
    );
  }

  @Action(ExpandRaters)
  expandRaters(
    ctx: StateContext<RaterManagementStateModel>,
    action: ExpandRaters
  ) {
    const store = ctx.getState();
    const uiState = store.raterStatusCardUiState.filter(
      (uiState: RaterStatusCardUiState) =>
        uiState.raterTypeId !== action.raterStatus.raterType.id
    );
    ctx.patchState({
      raterStatusCardUiState: [
        ...uiState,
        {
          raterTypeId: action.raterStatus.raterType.id,
          isRatersExpanded: action.isExpanded,
        },
      ],
    });
  }

  @Action(RemindRaters)
  remindRaters(
    ctx: StateContext<RaterManagementStateModel>,
    action: RemindRaters
  ) {
    let sendEmailRequests: EmailRequest[] = action.raterStatus.raters.map(
      (rater: Rater) => {
        return {
          participantId: action.raterStatus.participantId,
          raterId: rater.raterId,
        };
      }
    );

    this._raterManagementService.remindRaters(sendEmailRequests).subscribe(
      () => ctx.dispatch(new RemindRatersSuccess()),
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'remindRaters',
              data: sendEmailRequests,
            }
          )
        )
    );
  }

  @Action(RemindAllRaters)
  remindAllRaters(ctx: StateContext<RaterManagementStateModel>) {
    const raterStatuses: RaterStatus[] = ctx.getState().raterManagement
      .raterStatuses;
    let sendEmailRequests: EmailRequest[] = [];

    raterStatuses.forEach((raterStatus: RaterStatus) => {
      raterStatus.raters.forEach((rater: Rater) => {
        sendEmailRequests.push({
          participantId: raterStatus.participantId,
          raterId: rater.raterId,
        });
      });
    });

    this._raterManagementService.remindRaters(sendEmailRequests).subscribe(
      () => ctx.dispatch(new RemindRatersSuccess()),
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'remindAllRaters',
              data: sendEmailRequests,
            }
          )
        )
    );
  }

  @Action(ResendInvitation)
  resendInvitation(
    ctx: StateContext<RaterManagementStateModel>,
    action: ResendInvitation
  ) {
    this._raterManagementService
      .resendInvitation({
        participantId: action.raterStatus.participantId,
        raterId: action.rater.raterId,
      })
      .subscribe(
        () => {
          ctx.dispatch(new ResendInvitationSuccess());
        },
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'resendInvitation',
                data: {
                  raterStatus: action.raterStatus,
                  rater: action.rater,
                },
              }
            )
          )
      );
  }

  // events
  @Action(LoadRaterManagementSuccess)
  loadRaterManagementSuccess(
    ctx: StateContext<RaterManagementStateModel>,
    action: LoadRaterManagementSuccess
  ) {
    const state = ctx.getState();
    action.raterManagement.raterStatuses.forEach((raterStatus: RaterStatus) => {
      const uiState = state.raterStatusCardUiState.find(
        (uiState: RaterStatusCardUiState) =>
          uiState.raterTypeId === raterStatus.raterType.id
      );
      raterStatus.isRatersExpanded =
        uiState !== undefined ? uiState.isRatersExpanded : false;
    });
    ctx.patchState({
      raterManagement: action.raterManagement,
    });
  }
}
