<div class="mat-typography" apDirection>
    <ngx-loading [show]="showLoading"></ngx-loading>
    <div #header>
        <assess-header></assess-header>
    </div>
    <div apFullHeight [header]="header" [footer]="footer" [ngClass]="{'timeout-backdrop': timeout}">
        <router-outlet></router-outlet>
    </div>
    <div #footer class="footer" [ngClass.xs]="{mobile: true}">
        <p
            style="margin: 0 5px;"
            [translate]="'application.copyright'"
            [translateParams]="getCurrentYear()"
            tabindex="0"
        ></p>
    </div>
</div>
