import { Type } from '@angular/compiler/src/core';
import { InjectionToken } from '@angular/core';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

export class LogEntry {
  timestamp: Date;
  constructor(public level: string, public msg: any) {
    this.timestamp = new Date();
  }

  toString(): string {
    return JSON.stringify({
      timestamp: this.timestamp,
      level: this.level,
      ...this.msg
    });
  }
}

export const LOG_PUBLISHER_FACTORIES = new InjectionToken(
  'LOG_PUBLISHER_FACTORIES'
);

export const LogConfigService = new InjectionToken<LogConfig>('LOG_CONFIG');

export interface PublisherConfig {
  type: Type;
  config?: any;
}

export interface LogConfig {
  logLevel: LogLevel;
}
