import { State, StateContext, Action, Selector } from '@ngxs/store';

import {
  BackgroundLanding,
  SaveBackgroundResponse,
} from './background-landing.models';

import { BackgroundService } from '../services/background.service';

import {
  LoadBackground,
  LoadBackgroundSuccess,
  SaveBackground,
  SaveBackgroundSuccess,
} from './background-landing.actions';

import {
  ThrowException,
  NavigateHome,
  AboutMeComplete,
} from '../../../store/application.actions';
import { Injectable } from '@angular/core';

export class BackgroundLandingStateModel {
  backgroundLanding: BackgroundLanding;
}

@State<BackgroundLandingStateModel>({
  name: 'backgroundLanding',
  defaults: {
    backgroundLanding: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class BackgroundLandingState {
  constructor(private _backgroundService: BackgroundService) {}

  // selectors
  @Selector()
  static getBackgroundLanding(
    state: BackgroundLandingStateModel
  ): BackgroundLanding {
    return state.backgroundLanding;
  }

  // selectors
  @Selector()
  static isComplete(state: BackgroundLandingStateModel): boolean {
    return state.backgroundLanding.backgroundResponseDocument.complete;
  }

  // Actions
  @Action(LoadBackground)
  loadBackground(
    ctx: StateContext<BackgroundLandingStateModel>,
    action: LoadBackground
  ) {
    return this._backgroundService
      .getBackgroundLanding(action.individualId, action.languageId)
      .subscribe(
        (backgroundLanding: BackgroundLanding) =>
          ctx.dispatch(new LoadBackgroundSuccess(backgroundLanding)),
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'loadBackground',
              }
            )
          )
      );
  }

  @Action(SaveBackground)
  saveBackground(
    ctx: StateContext<BackgroundLandingStateModel>,
    action: SaveBackground
  ) {
    return this._backgroundService
      .save(action.backgroundResponseDocument)
      .subscribe(
        (saveBackgroundResponse: SaveBackgroundResponse) =>
          ctx.dispatch(
            new SaveBackgroundSuccess(
              saveBackgroundResponse.backgroundResponseDocument
            )
          ),
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'saveBackground',
              }
            )
          )
      );
  }

  // Events
  @Action(LoadBackgroundSuccess)
  loadBackgroundSuccess(
    ctx: StateContext<BackgroundLandingStateModel>,
    event: LoadBackgroundSuccess
  ) {
    ctx.patchState({
      backgroundLanding: event.backgroundLanding,
    });
    ctx.dispatch(
      new AboutMeComplete(
        event.backgroundLanding.backgroundResponseDocument.complete
      )
    );
    ctx.dispatch(new NavigateHome());
  }

  @Action(SaveBackgroundSuccess)
  saveBackgroundSuccess(
    ctx: StateContext<BackgroundLandingStateModel>,
    event: SaveBackgroundSuccess
  ) {
    let state = ctx.getState();
    let backgroundLanding: BackgroundLanding = state.backgroundLanding;

    backgroundLanding.backgroundResponseDocument =
      event.backgroundResponseDocument;

    ctx.patchState({
      backgroundLanding: backgroundLanding,
    });
    ctx.dispatch(
      new AboutMeComplete(event.backgroundResponseDocument.complete)
    );
    ctx.dispatch(new NavigateHome());
  }
}
