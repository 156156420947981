import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';

import { SurveySection, QuestionAnswer } from '../../store/survey.model';

import { SurveyDemographicsService } from '../../modules/survey-demographics/services/survey-demographics.service';
import { SurveyDocumentState } from '../../store/survey.state';
import { AnswerQuestions } from '../../store/survey.actions';

@Component({
  selector: 'assess-survey-demographics-questions',
  templateUrl: './survey-demographics-questions.component.html',
})
export class SurveyDemographicsQuestionsComponent implements OnInit, OnDestroy {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  currentSurveySection$: Observable<SurveySection>;

  private subscription: Subscription;

  constructor(
    private _store: Store,
    private _surveyDemographicsService: SurveyDemographicsService,
    private _scrollToService: ScrollToService
  ) {}

  ngOnInit(): void {
    const scrollTarget: number = this._store.selectSnapshot(
      SurveyDocumentState.getScrollTarget
    );

    setTimeout(() => {
      const config: ScrollToConfigOptions = {
        target: scrollTarget,
      };
      this._scrollToService.scrollTo(config);
    }, 250);

    this.subscription = this._surveyDemographicsService.questionAnswered$.subscribe(
      (answer: QuestionAnswer) => {
        const questionAnswer: QuestionAnswer = {
          formItemId: answer.formItemId,
          answer: answer.answer,
          optional: answer.optional,
          formItemSequenceNumber: answer.formItemSequenceNumber,
          answeredTimeMs: new Date().getTime(),
        };
        this._store.dispatch(new AnswerQuestions([questionAnswer]));
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
