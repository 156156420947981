<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div *ngIf="option.text">
        <div tabindex="0">{{option.text}}</div>
    </div>
    <mat-form-field *ngIf="option.multiline === true else singleLine" class="full-width">
        <textarea #message matInput [attr.id]="option.formItemId" [maxLength]="option.maxLength" matTextareaAutosize
            matAutosizeMinRows="2" matAutosizeMaxRows="7" [formControl]="response"
            [placeholder]="option.placeholder" aria-label="Please enter your response"></textarea>
        <mat-hint align="end">{{message.value?.length || 0}} /
            {{ option.maxLength }}</mat-hint>
    </mat-form-field>
    <ng-template #singleLine>
        <mat-form-field class="full-width">
            <input #message matInput [attr.id]="option.formItemId" formControlName="response" autocomplete="nope"
                [maxLength]="option.maxLength" [placeholder]="option.placeholder">
            <mat-hint align="end">{{message.value?.length || 0}} /
                {{ option.maxLength }}</mat-hint>
        </mat-form-field>
    </ng-template>
</div>