<div fxLayout="column">
  <div [ngClass]="{ 'warning-label': !raterStatus.minimumInvitedCountMet }" tabindex="0">
    <span *ngIf="!raterStatus.minimumInvitedCountMet"><i
        class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;&nbsp;</span>{{raterStatus.raterType.name}}
  </div>
  <div class="text-muted" fxLayout fxLayout.lt-md="column">
    <span *ngIf="raterStatus.raterRecommendedCount === 1" [translate]="'raterManagement.card.recommended'"
      [translateParams]="{count: raterStatus.raterRecommendedCount}" tabindex="0"></span>
    <span *ngIf="raterStatus.raterRecommendedCount > 1" [translate]="'raterManagement.card.recommendedPlural'"
      [translateParams]="{count: raterStatus.raterRecommendedCount}" tabindex="0"></span>
    <span *ngIf="raterStatus.raterRecommendedCount > 0 && raterStatus.raterRequiredCount > 0"
      fxHide.lt-md="true">,&nbsp;</span>
    <span *ngIf="raterStatus.raterRequiredCount === 1" [translate]="'raterManagement.card.required'"
      [translateParams]="{count: raterStatus.raterRequiredCount}" tabindex="0"></span>
    <span *ngIf="raterStatus.raterRequiredCount > 1" [translate]="'raterManagement.card.requiredPlural'"
      [translateParams]="{count: raterStatus.raterRequiredCount}" tabindex="0"></span>
  </div>
</div>