import { Component, OnInit, OnDestroy } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeZh from '@angular/common/locales/zh';
import localePl from '@angular/common/locales/pl';

import { MatDialog } from '@angular/material/dialog';

import { ConnectionService } from 'ng-connection-service';

import { AuthService } from './core/auth/auth.service';
import { Subscription } from 'rxjs';

import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';

import {
  SetLanguageSuccess,
  ThrowException,
} from './store/application.actions';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ApplicationState } from './store/application.state';
import { Config } from './store/application.models';
import { SessionEndingDialogComponent } from './shared/dialogs/session-ending-dialog/session-ending-dialog.component';
import { NoInternetDialogComponent } from './shared/dialogs/no-internet-dialog/no-internet-dialog.component';

@Component({
  selector: 'assess-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  showLoading: boolean = false;

  timeout: boolean = false;

  private _config: Config;

  private _actionSubscription: Subscription;
  private _configSubscription: Subscription;

  private _noInternetDialogRef: any;

  constructor(
    private _dialog: MatDialog,
    private _actions$: Actions,
    private _idle: Idle,
    private _authService: AuthService,
    private _store: Store,
    private _translateService: TranslateService,
    private _connectionService: ConnectionService
  ) {}

  ngOnInit(): void {
    this.showLoading = true;

    // register angular locales for date pipe
    registerLocaleData(localeDe);
    registerLocaleData(localeEs);
    registerLocaleData(localeFr);
    registerLocaleData(localeIt);
    registerLocaleData(localeNl);
    registerLocaleData(localePt);
    registerLocaleData(localeRu);
    registerLocaleData(localeZh);
    registerLocaleData(localePl);

    if (environment.production) {
      // leave these alone for Prod
      this._idle.setIdle(300);
      this._idle.setTimeout(60);
    } else {
      // adjust these for local development
      this._idle.setIdle(300000);
      this._idle.setTimeout(10);
    }
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this._idle.setIdleName('application');

    this._connectionService.monitor().subscribe((connected) => {
      if (!connected) {
        this._noInternetDialogRef = this._dialog.open(
          NoInternetDialogComponent,
          {
            width: '450px',
            disableClose: true,
          }
        );
      } else {
        this._noInternetDialogRef.close();
      }
    });

    this._idle.onTimeoutWarning.subscribe((count: number) => {
      if (count === this._idle.getTimeout()) {
        this._idle.clearInterrupts();
        let dialogRef = this._dialog.open(SessionEndingDialogComponent, {
          width: '450px',
          disableClose: true,
          data: {
            title: this._translateService.instant(
              'dialog.sessionTimeout.title'
            ),
            yesButton: this._translateService.instant('dialog.yes'),
            noButton: this._translateService.instant('dialog.no'),
          },
        });

        dialogRef.afterClosed().subscribe((response) => {
          if (response === true) {
            this._idle.stop();
            this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            this._idle.watch();
          } else if (response === false) {
            this.logout();
          }
        });
      }
    });

    this._idle.onTimeout.subscribe(() => {
      this.timeout = true;
      this.logout();
    });

    this._actionSubscription = this._actions$
      .pipe(ofActionSuccessful(SetLanguageSuccess, ThrowException))
      .subscribe(() => {
        this.showLoading = false;
      });

    this._configSubscription = this._store
      .select(ApplicationState.getConfig)
      .subscribe((config: Config) => (this._config = config));

    this._idle.watch();
  }

  ngOnDestroy(): void {
    this._actionSubscription.unsubscribe();
    this._configSubscription.unsubscribe();
  }

  getCurrentYear(): any {
    const d = new Date();
    return { currentYear: d.getFullYear() };
  }

  private logout(): void {
    this._authService.logout(
      this._config.authClientId,
      this._config.authDomain,
      this._config.grandCentralLoginApplicationId,
      this._config.grandCentralReturnUrl
    );
  }
}
