import { State, Action, StateContext, Selector } from '@ngxs/store';

import { Navigate } from '@ngxs/router-plugin';
import { Observable, of, forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Engine } from 'json-rules-engine';

import {
  ThrowException,
  NavigateHome,
} from '../../../store/application.actions';

import {
  SurveyDocument,
  ComponentDescriptor,
  SurveySection,
  Survey,
  SectionReviewCard,
  Question,
  QuestionGroup,
  QuestionAnswer,
  CompetencyReview,
  ScaleButton,
  Language,
  Extension,
  JumpMenu,
  StartSurveyResponse,
  Participant,
  RaterType,
  NavigationEvent,
  START,
  SUBMIT,
  REVIEW_QUESTION,
  REVIEW_SECTION,
  MENU,
  UserStatus,
  SurveyAnswer,
  QuestionListItem,
  Option,
} from './survey.model';

import {
  StartSurvey,
  StartSurveySuccess,
  BuildComponentDescriptorsSuccess,
  BuildComponentDescriptors,
  Navigation,
  ExitSurvey,
  JumpTo,
  ReviewQuestion,
  AnswerQuestions,
  AnswerQuestionsSuccess,
  BuildSurveySections,
  BuildSurveySectionsSuccess,
  SubmitSurvey,
  SubmitSurveySuccess,
  ChangeLanguage,
  ChangeLanguageSuccess,
  SetScrollTarget,
  ReviewSection,
  UserNotFound,
  BuildQuestionList,
  BuildQuestionListSuccess,
  RefreshAnswers,
  RefreshAnswersSuccess,
} from './survey.actions';

import { SurveyService } from '../services/survey.service';
import { DirectFeedbackAnswerService } from '../services/direct-feedback-answer.service';
import { Injectable } from '@angular/core';

export class SurveyDocumentStateModel {
  surveyDocument: SurveyDocument;
  surveyAnswers: SurveyAnswer[];
  questionList: QuestionListItem[];
  participant: Participant;
  raterType: RaterType;
  componentDescriptors: ComponentDescriptor[];
  surveySections: SurveySection[];
  lastNavigationEvent: NavigationEvent;
  scrollTarget: number;
  answeringInProcess: boolean;
}

@State<SurveyDocumentStateModel>({
  name: 'surveyDocument',
  defaults: {
    surveyDocument: null,
    surveyAnswers: null,
    questionList: null,
    participant: null,
    raterType: null,
    componentDescriptors: null,
    surveySections: null,
    lastNavigationEvent: {
      source: null,
      sectionIndex: 0,
    },
    scrollTarget: null,
    answeringInProcess: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class SurveyDocumentState {
  public aboutMeUrl;
  constructor(
    private _surveyService: SurveyService,
    
    private _directFeedbackAnswerService: DirectFeedbackAnswerService
  ) {
      
  }


  @Selector()
  static getAnsweringInProgress(state: SurveyDocumentStateModel): boolean {
    return state.answeringInProcess;
  }

  @Selector()
  static getSurvey(state: SurveyDocumentStateModel): Survey {
    return state.surveyDocument.survey;
  }

  @Selector()
  static getParticipant(state: SurveyDocumentStateModel): Participant {
    return state.participant;
  }

  @Selector()
  static getRaterType(state: SurveyDocumentStateModel): RaterType {
    return state.raterType;
  }

  @Selector()
  static getSurveyExtensions(state: SurveyDocumentStateModel): Extension[] {
    return [...state.surveyDocument.survey.extensions];
  }

  @Selector()
  static getComponentDescriptors(
    state: SurveyDocumentStateModel
  ): ComponentDescriptor[] {
    return state.componentDescriptors;
  }

  @Selector()
  static getCurrentSectionIndex(state: SurveyDocumentStateModel): number {
    return state.lastNavigationEvent.sectionIndex;
  }

  @Selector()
  static getQuestionList(state: SurveyDocumentStateModel): QuestionListItem[] {
    return state.questionList;
  }

  @Selector()
  static getLastNavigationEvent(
    state: SurveyDocumentStateModel
  ): NavigationEvent {
    return state.lastNavigationEvent;
  }

  @Selector()
  static getScrollTarget(state: SurveyDocumentStateModel): number {
    return state.scrollTarget;
  }

  @Selector()
  static getCurrentSurveySection(
    state: SurveyDocumentStateModel
  ): SurveySection {
    return state.surveySections[state.lastNavigationEvent.sectionIndex];
  }

  @Selector()
  static getQuestionAnswer(state: SurveyDocumentStateModel) {
    return (formItemId: number) => {
      return state.questionList.find(
        (questionListItem: QuestionListItem) =>
          questionListItem.formItemId === formItemId
      );
    };
  }

  @Selector()
  static getSurveySectionQuestionsAnswered(
    state: SurveyDocumentStateModel
  ): number {
    const currentSurveySection: SurveySection = SurveyDocumentState.getCurrentSurveySection(
      state
    );
    return SurveyDocumentState.getAnswerCount(
      currentSurveySection,
      state.questionList,
      state.surveyDocument.survey.instrumentId,
      state.surveyAnswers
    );
  }

  @Selector()
  static getSurveySectionFirstUnansweredQuestion(
    state: SurveyDocumentStateModel
  ): Question {
    const currentSurveySection: SurveySection = SurveyDocumentState.getCurrentSurveySection(
      state
    );
    const unasweredQuestions: Question[] = SurveyDocumentState.getUnansweredQuestions(
      currentSurveySection,
      state.questionList,
      state.surveyDocument.survey.instrumentId
    );

    if (unasweredQuestions.length > 0) {
      return unasweredQuestions[0];
    }

    // if here then all questions are answered in the section so just return
    // the last answered question
    const questionListItem: QuestionListItem = state.questionList.find(
      (questionListItem: QuestionListItem) =>
        questionListItem.formItemId ===
        state.surveyDocument.survey.lastAnsweredFormItemId
    );
    return questionListItem.question;
  }

  @Selector()
  static getNumberAnswersRequired(state: SurveyDocumentStateModel): number {
    const currentSurveySection: SurveySection = SurveyDocumentState.getCurrentSurveySection(
      state
    );

    const condition: any = currentSurveySection.validationRule.conditions.all.find(
      (condition: any) => condition.fact === 'numberAnswered'
    );

    return condition ? condition.value : 0;
  }

  @Selector()
  static getSectionReviewCards(
    state: SurveyDocumentStateModel
  ): SectionReviewCard[] {
    const surveyDocument: SurveyDocument = state.surveyDocument;
    const reviewableSections: SurveySection[] = state.surveySections.filter(
      (surveySection: SurveySection) => surveySection.reviewable
    );

    return reviewableSections.map((surveySection: SurveySection) => {
      return {
        sectionId: surveySection.sectionId,
        heading: surveySection.heading,
        optional: surveySection.optional,
        unansweredQuestions: SurveyDocumentState.getUnansweredQuestions(
          surveySection,
          state.questionList,
          surveyDocument.survey.instrumentId
        ),
        notObservedQuestions: SurveyDocumentState.getNotObservedQuestions(
          surveyDocument.survey.instrumentId,
          surveySection,
          state.questionList
        ),
        validationRule: surveySection.validationRule,
        numberAnswered: SurveyDocumentState.getAnswerCount(
          surveySection,
          state.questionList,
          surveyDocument.survey.instrumentId,
          state.surveyAnswers
        ),
        notObservedAnswered: state.questionList.filter(
          (questionListItem: QuestionListItem) =>
            questionListItem.sectionId === surveySection.sectionId &&
            questionListItem.answer &&
            questionListItem.answer === '6'
        ).length,
      };
    });
  }

  @Selector()
  static canSubmit(state: SurveyDocumentStateModel): any {
    const surveyDocument: SurveyDocument = state.surveyDocument;

    const currentSurveySection: SurveySection = SurveyDocumentState.getCurrentSurveySection(
      state
    );
    const reviewableSections: SurveySection[] = state.surveySections.filter(
      (surveySection: SurveySection) =>
        surveySection.questionGroups &&
        surveySection.sectionId !== currentSurveySection.sectionId
    );

    let answersObservable = forkJoin(
      reviewableSections.map((surveySection: SurveySection) =>
        SurveyDocumentState.isAnswered(
          surveyDocument.survey.instrumentId,
          surveySection,
          state.questionList,
          state.surveyAnswers
        )
      )
    );

    return answersObservable.pipe(
      map((answers) => {
        return answers.reduce((acc, val) => {
          return acc && val;
        });
      }),
      map((canSubmit) => {
        return { enabled: canSubmit };
      })
    );
  }

  @Selector()
  static canStart(state: SurveyDocumentStateModel): any {
    const welcomeSection: SurveySection = state.surveySections.find(
      (surveySection: SurveySection) =>
        surveySection.component === 'SurveyWelcomeComponent'
    );

    return SurveyDocumentState.isUnderstood(
      welcomeSection,
      state.surveyAnswers
    ).pipe(
      map((canStart) => {
        return { enabled: canStart };
      })
    );
  }

  @Selector()
  static sectionComplete(state: SurveyDocumentStateModel): any {
    const surveyDocument: SurveyDocument = state.surveyDocument;
    const currentSurveySection: SurveySection = SurveyDocumentState.getCurrentSurveySection(
      state
    );

    return SurveyDocumentState.isAnswered(
      surveyDocument.survey.instrumentId,
      currentSurveySection,
      state.questionList,
      state.surveyAnswers
    ).pipe(
      map((complete) => {
        return { complete: complete };
      })
    );
  }

  @Selector()
  static getCompetenciesReviews(
    state: SurveyDocumentStateModel
  ): CompetencyReview[] {
    // get the competency section
    const competencySection: SurveySection = state.surveySections.find(
      (surveySection: SurveySection) =>
        surveySection.component === 'SurveyCompetenciesQuestionsComponent'
    );

    // get the selected competencies
    const selectedQuestions: Question[] = competencySection.questionGroups[0].questions.filter(
      (question: Question) =>
        state.questionList.some(
          (questionListItem: QuestionListItem) =>
            questionListItem.formItemId === question.formItemId &&
            questionListItem.answer === '1'
        )
    );

    // map all the question groups from the survey to make it easier to find the
    // correct question group
    let questionGroups: QuestionGroup[] = [];
    state.surveySections
      .filter(
        (surveySection: SurveySection) => surveySection.questionGroups !== null
      )
      .map((surveySection: SurveySection) => {
        surveySection.questionGroups.map((questionGroup: QuestionGroup) => {
          questionGroups.push(questionGroup);
        });
      });

    // out of the selected competency questions
    return selectedQuestions.map((competencyQuestion: Question) => {
      // find the question group for this selected competency
      const questionGroup: QuestionGroup = questionGroups.find(
        (questionGroup: QuestionGroup) =>
          questionGroup.questions.some(
            (question: Question) =>
              question.parentFormItemId === competencyQuestion.parentFormItemId
          )
      );

      // return the new competency review qroups with question grouped properly
      return {
        name: competencyQuestion.text,
        groups: questionGroup.surveyScale.buttons
          .filter(
            (button: ScaleButton) =>
              button.reviewable && button.reviewable === true
          )
          .map((button: ScaleButton) => {
            return {
              name: button.meaning,
              questions: questionGroup.questions.filter((question: Question) =>
                state.questionList.find(
                  (questionListItem: QuestionListItem) =>
                    questionListItem.formItemId === question.formItemId &&
                    questionListItem.answer === button.buttonValue
                )
              ),
            };
          }),
      };
    });
  }

  @Selector()
  public static getAvailableLanguages(
    state: SurveyDocumentStateModel
  ): Language[] {
    return [...state.surveyDocument.languages];
  }

  @Selector()
  public static getCurrentLanguage(state: SurveyDocumentStateModel): Language {
    return state.surveyDocument.languages.find(
      (language: Language) =>
        language.languageId === state.surveyDocument.survey.languageId
    );
  }

  @Selector()
  public static getSurveySectionExtensions(
    state: SurveyDocumentStateModel
  ): Extension[] {
    const currentSurveySection: SurveySection = SurveyDocumentState.getCurrentSurveySection(
      state
    );

    return [...currentSurveySection.extensions];
  }

  @Selector()
  public static getSurveyJumpMenu(state: SurveyDocumentStateModel): JumpMenu[] {
    return state.surveySections
      .filter((surveySection: SurveySection) => surveySection.jumpMenu)
      .map((surveySection: SurveySection) => {
        return {
          sectionId: surveySection.sectionId,
          jumpMenu: surveySection.jumpMenu,
        };
      });
  }

  private static getUnansweredQuestions(
    surveySection: SurveySection,
    questionList: QuestionListItem[],
    instrumentId: number
  ): Question[] {
    // return empty array for competencies because missed questions make no sense
    if (surveySection.component === 'SurveyCompetenciesQuestionsComponent') {
      return [];
    }

    if (surveySection.component === 'SurveyDemographicsQuestionsComponent') {
      return SurveyDocumentState.getUnansweredDemographicQuestions(
        surveySection,
        questionList
      );
    }

    // AAndB question demand special attention because their answer form ID are in
    // the questions options collection.
    if (surveySection.component === 'SurveyAAndBQuestionsComponent') {
      return SurveyDocumentState.getUnansweredAAndBQuestions(
        surveySection,
        questionList
      );
    }

    // MBTI-Q uses AOrB component but the survey schema structure is different so the
    // ISI (Influence Style Indicator) has to be handled differently.
    if (
      surveySection.component === 'SurveyAOrBQuestionsComponent' &&
      (instrumentId === 1897 || instrumentId === 1923)
    ) {
      return SurveyDocumentState.getUnansweredAOrBQuestions(
        surveySection,
        questionList
      );
    }

    if (surveySection.component === 'SurveyMultiDropdownQuestionsComponent') {
      return SurveyDocumentState.getUnansweredMultiDropdownQuestions(
        surveySection,
        questionList
      );
    }

    if (surveySection.component === 'SurveyScalesQuestionsComponent') {
      return SurveyDocumentState.getUnansweredSurveyScalesQuestions(
        surveySection,
        questionList
      );
    }

    // default processing for all other questions
    return questionList
      .filter(
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          (!questionListItem.answer || questionListItem.answer === null)
      )
      .map((questionListItem: QuestionListItem) => questionListItem.question);
  }

  private static multiOptionQuestionAnswered(
    question: Question,
    questionList: QuestionListItem[]
  ): boolean {
    let answered: boolean = false;

    // if the multi option question is required see if there is at least one checked
    if (question.optional === false) {
      for (let i = 0; i < question.options.length; i++) {
        if (
          questionList.some(
            (questionListItem: QuestionListItem) =>
              questionListItem.formItemId === question.options[i].formItemId &&
              questionListItem.answer === '1'
          )
        ) {
          answered = true;
          break;
        }
      }
    } else {
      // if optional there is no need to check if anything is checked
      answered = true;
    }

    // always check if there is a dependentOption that is required and
    // if parent is checked ensure the dependentOption has an answer
    let option: Option = question.options.find(
      (option: Option) =>
        option.dependentOption && option.dependentOption.optional === false
    );
    if (option) {
      answered =
        answered &&
        (questionList.some(
          (questionListItem: QuestionListItem) =>
            questionListItem.formItemId === option.formItemId &&
            (questionListItem.answer === null ||
              questionListItem.answer === '0')
        ) ||
          (questionList.some(
            (questionListItem: QuestionListItem) =>
              questionListItem.formItemId === option.formItemId &&
              questionListItem.answer === '1'
          ) &&
            questionList.some(
              (questionListItem: QuestionListItem) =>
                questionListItem.formItemId ===
                  option.dependentOption.formItemId &&
                questionListItem.answer !== null
            )));
    }

    return answered;
  }

  private static singleOptionQuestionAnswered(
    question: Question,
    questionList: QuestionListItem[]
  ): boolean {
    let answered: boolean = false;

    // if the multi option question is required see if there is at least one checked
    if (question.optional === false) {
      for (let i = 0; i < question.options.length; i++) {
        if (
          questionList.some(
            (questionListItem: QuestionListItem) =>
              questionListItem.formItemId === question.formItemId &&
              questionListItem.answer !== null
          )
        ) {
          answered = true;
          break;
        }
      }
    } else {
      // if optional there is no need to check if anything is checked
      answered = true;
    }

    return answered;
  }

  private static getUnansweredDemographicQuestions(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): Question[] {
    let questions: Question[] = [];

    surveySection.questionGroups.forEach((questionGroup: QuestionGroup) => {
      questionGroup.questions.forEach((question: Question, index: number) => {
        switch (question.component) {
          case 'MultiOptionsComponent':
            {
              if (
                !SurveyDocumentState.multiOptionQuestionAnswered(
                  question,
                  questionList
                )
              ) {
                questions.push({
                  ...question,
                  number: (index + 1).toString(),
                });
              }
            }
            break;
          case 'SingleOptionComponent':
            {
              if (
                !SurveyDocumentState.singleOptionQuestionAnswered(
                  question,
                  questionList
                )
              ) {
                questions.push({
                  ...question,
                  number: (index + 1).toString(),
                });
              }
            }
            break;
          // TODO - fill in the rest as needed
          // case '': {

          // }
          // break;
        }
      });
    });
    return questions;
  }

  private static getUnansweredAOrBQuestions(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): Question[] {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        const questionA: QuestionListItem = questionList.find(
          (questionAListItem: QuestionListItem) =>
            questionAListItem.formItemId ===
            questionListItem.question.options[0].formItemId
        );
        const questionB: QuestionListItem = questionList.find(
          (questionBListItem: QuestionListItem) =>
            questionBListItem.formItemId ===
            questionListItem.question.options[1].formItemId
        );

        return (
          !questionA.answer ||
          (questionA.answer === null &&
            (!questionB.answer || questionB.answer === null))
        );
      })
      .map((questionListItem: QuestionListItem) => questionListItem.question);
  }

  private static getUnansweredAAndBQuestions(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): Question[] {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        const questionA: QuestionListItem = questionList.find(
          (questionAListItem: QuestionListItem) =>
            questionAListItem.formItemId ===
            questionListItem.question.options[0].formItemId
        );
        const questionB: QuestionListItem = questionList.find(
          (questionBListItem: QuestionListItem) =>
            questionBListItem.formItemId ===
            questionListItem.question.options[1].formItemId
        );

        return (
          !questionA.answer ||
          questionA.answer === null ||
          !questionB.answer ||
          questionB.answer === null
        );
      })
      .map((questionListItem: QuestionListItem) => questionListItem.question);
  }

  private static getUnansweredMultiDropdownQuestions(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): Question[] {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        let answers = [];

        questionListItem.question.options.forEach((option: Option) => {
          const question = questionList.find(
            (questionListItem: QuestionListItem) =>
              questionListItem.formItemId === option.formItemId
          );
          if (question.answer) {
            answers.push(question.answer);
          }
        });

        return !(
          answers.length === 4 && answers.every((e, i, a) => a.indexOf(e) === i)
        );
      })
      .map((questionListItem: QuestionListItem) => questionListItem.question);
  }

  private static getUnansweredSurveyScalesQuestions(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): Question[] {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        if (questionListItem.question.hasOptions) {
          let answers = [];
          questionListItem.question.options.forEach((option: Option) => {
            const question: QuestionListItem = questionList.find(
              (questionListItem: QuestionListItem) =>
                questionListItem.formItemId === option.formItemId
            );
            if (question.answer) {
              answers.push(question.answer);
            }
          });
          return answers.length !== questionListItem.question.options.length;
        } else {
          return !questionListItem.answer || questionListItem.answer === null;
        }
      })
      .map((questionListItem: QuestionListItem) => questionListItem.question);
  }

  private static getNotObservedQuestions(
    instrumentId: number,
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): Question[] {
    // return empty array for competencies because missed questions make no sense
    if (surveySection.component === 'SurveyCompetenciesQuestionsComponent') {
      return [];
    }

    return questionList
      .filter(
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.answer &&
          questionListItem.answer === '6' &&
          instrumentId === 1927
      )
      .map((questionListItem: QuestionListItem) => questionListItem.question);
  }

  private static getAnswerCount(
    surveySection: SurveySection,
    questionList: QuestionListItem[],
    instrumentId: number,
    surveyAnswers: SurveyAnswer[]
  ): number {
    if (surveySection.component === 'SurveyWelcomeComponent') {
      return SurveyDocumentState.getWelcomeAnswerCount(
        surveySection,
        surveyAnswers
      );
    }

    if (surveySection.component === 'SurveyDemographicsQuestionsComponent') {
      return SurveyDocumentState.getDemographicsAnswerCount(
        surveySection,
        questionList
      );
    }

    // AAndB question demand special attention because their answer form ID are in
    // the questions options collection.
    if (surveySection.component === 'SurveyAAndBQuestionsComponent') {
      return SurveyDocumentState.getAAndBQuestionsAnswerCount(
        surveySection,
        questionList
      );
    }

    // MBTI-Q uses AOrB component but the survey schema structure is different so the
    // ISI (Influence Style Indicator) / TKI (Thomas-Kilmann Conflict Mode Instrument) has to be handled differently.
    if (
      surveySection.component === 'SurveyAOrBQuestionsComponent' &&
      (instrumentId === 1897 || instrumentId === 1923)
    ) {
      return SurveyDocumentState.getAOrBQuestionsAnswerCount(
        surveySection,
        questionList
      );
    }

    if (surveySection.component === 'SurveyMultiDropdownQuestionsComponent') {
      return SurveyDocumentState.getMultiDropdownQuestionsAnswerCount(
        surveySection,
        questionList
      );
    }

    if (surveySection.component === 'SurveyScalesQuestionsComponent') {
      return SurveyDocumentState.getSurveyScalesQuestionsAnswerCount(
        surveySection,
        questionList
      );
    }

    // default processing for all other questions
    return questionList.filter(
      (questionListItem: QuestionListItem) =>
        questionListItem.sectionId === surveySection.sectionId &&
        questionListItem.answer &&
        questionListItem.answer !== null
    ).length;
  }

  private static getWelcomeAnswerCount(
    surveySection: SurveySection,
    surveyAnswers: SurveyAnswer[]
  ): number {
    return SurveyDocumentState.isUnderstood(surveySection, surveyAnswers)
      ? 1
      : 0;
  }

  private static getDemographicsAnswerCount(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): number {
    let answered: number = 0;

    surveySection.questionGroups.forEach((questionGroup: QuestionGroup) => {
      questionGroup.questions.forEach((question: Question) => {
        switch (question.component) {
          case 'MultiOptionsComponent':
            {
              if (
                SurveyDocumentState.multiOptionQuestionAnswered(
                  question,
                  questionList
                )
              ) {
                answered++;
              }
            }
            break;
          case 'SingleOptionComponent':
            {
              if (
                SurveyDocumentState.singleOptionQuestionAnswered(
                  question,
                  questionList
                )
              ) {
                answered++;
              }
            }
            break;
          // TODO - fill in the rest as needed
          // case '': {

          // }
          // break;
        }
      });
    });
    return answered;
  }

  private static getAAndBQuestionsAnswerCount(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): number {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        const questionA: QuestionListItem = questionList.find(
          (questionAListItem: QuestionListItem) =>
            questionAListItem.formItemId ===
            questionListItem.question.options[0].formItemId
        );
        const questionB: QuestionListItem = questionList.find(
          (questionBListItem: QuestionListItem) =>
            questionBListItem.formItemId ===
            questionListItem.question.options[1].formItemId
        );

        return (
          questionA.answer &&
          questionA.answer !== null &&
          questionB.answer &&
          questionB.answer !== null
        );
      }).length;
  }

  private static getAOrBQuestionsAnswerCount(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): number {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        const questionA: QuestionListItem = questionList.find(
          (questionAListItem: QuestionListItem) =>
            questionAListItem.formItemId ===
            questionListItem.question.options[0].formItemId
        );
        const questionB: QuestionListItem = questionList.find(
          (questionBListItem: QuestionListItem) =>
            questionBListItem.formItemId ===
            questionListItem.question.options[1].formItemId
        );

        return (
          questionA.answer &&
          questionA.answer !== null &&
          questionB.answer &&
          questionB.answer !== null
        );
      }).length;
  }

  private static getMultiDropdownQuestionsAnswerCount(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): number {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        let answers: string[] = [];

        questionListItem.question.options.forEach((option: Option) => {
          const question = questionList.find(
            (questionListItem: QuestionListItem) =>
              questionListItem.formItemId === option.formItemId
          );
          if (question.answer) {
            answers.push(question.answer);
          }
        });

        return (
          answers.length === 4 && answers.every((e, i, a) => a.indexOf(e) === i)
        );
      }).length;
  }

  private static getSurveyScalesQuestionsAnswerCount(
    surveySection: SurveySection,
    questionList: QuestionListItem[]
  ): number {
    return questionList
      .filter(
        // filter the parent question so we can get to the options
        (questionListItem: QuestionListItem) =>
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.question &&
          questionListItem.question !== null
      )
      .filter((questionListItem: QuestionListItem) => {
        let answers: string[] = [];

        if (questionListItem.question.hasOptions) {
          questionListItem.question.options.forEach((option: Option) => {
            const question = questionList.find(
              (questionListItem: QuestionListItem) =>
                questionListItem.formItemId === option.formItemId
            );
            if (question.answer) {
              answers.push(question.answer);
            }
          });
          return answers.length === questionListItem.question.options.length;
        } else {
          if (questionListItem.answer && questionListItem.answer !== null) {
            answers.push(questionListItem.answer);
            return answers;
          }
        }
      }).length;
  }

  private static isAnswered(
    instrumentId: number,
    surveySection: SurveySection,
    questionList: QuestionListItem[],
    surveyAnswers: SurveyAnswer[]
  ): Observable<boolean> {
    if (surveySection.optional) {
      return of(true);
    }

    const engine = new Engine();

    engine.addRule(surveySection.validationRule);

    const facts = {
      numberAnswered: SurveyDocumentState.getAnswerCount(
        surveySection,
        questionList,
        instrumentId,
        surveyAnswers
      ),
      notObservedExceeded: questionList.filter(
        (questionListItem: QuestionListItem) =>
          instrumentId === 1927 &&
          questionListItem.sectionId === surveySection.sectionId &&
          questionListItem.answer &&
          questionListItem.answer === '6'
      ).length,
    };

    return from<Observable<boolean>>(
      engine.run(facts).then((events) => {
        return events.length === 0;
      })
    );
  }

  private static isUnderstood(
    welcomeSection: SurveySection,
    surveyAnswers: SurveyAnswer[]
  ): Observable<boolean> {
    if (welcomeSection.optional) {
      return of(true);
    }

    if (
      welcomeSection.questionGroups.length === 0 ||
      welcomeSection.questionGroups[0].questions.length === 0
    ) {
      return of(true);
    }

    let iUnderstandQuestion: Question =
      welcomeSection.questionGroups[0].questions[0];

    let iUnderstandAnswers: SurveyAnswer[] = surveyAnswers.filter(
      (surveyAnswer: SurveyAnswer) =>
        surveyAnswer.formItemId === iUnderstandQuestion.formItemId &&
        surveyAnswer.answer &&
        surveyAnswer.answer === '1'
    );

    return of(iUnderstandAnswers.length > 0);
  }

  private static buildQuestionList(
    surveySections: SurveySection[],
    surveyAnswers: SurveyAnswer[]
  ): QuestionListItem[] {
    let questionList: QuestionListItem[] = [];

    surveySections.forEach((surveySection: SurveySection) => {
      surveySection.questionGroups.forEach((questionGroup: QuestionGroup) => {
        questionGroup.questions.forEach((question: Question) => {
          const answers: SurveyAnswer[] = surveyAnswers
            .filter(
              (surveyAnswer: SurveyAnswer) =>
                surveyAnswer.formItemId === question.formItemId
            )
            .sort(
              (a: SurveyAnswer, b: SurveyAnswer) =>
                b.answeredTimeMs - a.answeredTimeMs
            ); // sort in descending order

          questionList.push({
            sectionId: surveySection.sectionId,
            formItemId: question.formItemId,
            formItemSequenceNumber: question.formItemSequenceNumber,
            answer: answers.length > 0 ? answers[0].answer : null,
            question: question,
          });

          if (question.dependentOption) {
            const answers: SurveyAnswer[] = surveyAnswers
              .filter(
                (surveyAnswer: SurveyAnswer) =>
                  surveyAnswer.formItemId ===
                  question.dependentOption.formItemId
              )
              .sort(
                (a: SurveyAnswer, b: SurveyAnswer) =>
                  b.answeredTimeMs - a.answeredTimeMs
              ); // sort in descending order

            questionList.push({
              sectionId: surveySection.sectionId,
              formItemId: question.dependentOption.formItemId,
              formItemSequenceNumber:
                question.dependentOption.formItemSequenceNumber,
              answer: answers.length > 0 ? answers[0].answer : null,
              question: question,
            });
          }

          if (
            question.options &&
            question.options.some((option: Option) => !!option.formItemId)
          ) {
            question.options.forEach((option: Option) => {
              const answers: SurveyAnswer[] = surveyAnswers
                .filter(
                  (surveyAnswer: SurveyAnswer) =>
                    surveyAnswer.formItemId === option.formItemId
                )
                .sort(
                  (a: SurveyAnswer, b: SurveyAnswer) =>
                    b.answeredTimeMs - a.answeredTimeMs
                ); // sort in descending order

              questionList.push({
                sectionId: surveySection.sectionId,
                formItemId: option.formItemId,
                formItemSequenceNumber: option.formItemSequenceNumber,
                answer: answers.length > 0 ? answers[0].answer : null,
              });

              if (option.dependentFormItemSequenceNumber) {
                const answers: SurveyAnswer[] = surveyAnswers
                  .filter(
                    (surveyAnswer: SurveyAnswer) =>
                      surveyAnswer.formItemId ===
                      option.dependentOption.formItemId
                  )
                  .sort(
                    (a: SurveyAnswer, b: SurveyAnswer) =>
                      b.answeredTimeMs - a.answeredTimeMs
                  ); // sort in descending order

                questionList.push({
                  sectionId: surveySection.sectionId,
                  formItemId: option.dependentOption.formItemId,
                  formItemSequenceNumber:
                    option.dependentOption.formItemSequenceNumber,
                  answer: answers.length > 0 ? answers[0].answer : null,
                });
              }
            });
          }
        });
      });
    });

    return questionList;
  }

  // Action handlers
  @Action(StartSurvey)
  startSurvey(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: StartSurvey
  ) {
    if (action.instrumentTypeId && action.instrumentTypeId === 6) {
      window.open(action.assessmentLinkInfo.assessmentLink, '_blank');
    } else {
      this._surveyService
        .startSurvey(
          action.instrumentId,
          action.surveyTypeId,
          action.languageId,
          action.participantId,
          action.individualId
        )
        .subscribe(
          (startSurvey: StartSurveyResponse) => {
            if (startSurvey.userStatus === UserStatus.Found) {
              ctx.dispatch(
                new StartSurveySuccess(
                  startSurvey.surveyDocument,
                  startSurvey.surveyAnswers,
                  startSurvey.participant,
                  startSurvey.raterType
                )
              );
            } else {
              ctx.dispatch(new UserNotFound());
            }
          },
          (error) =>
            ctx.dispatch(
              new ThrowException(
                {
                  error: error,
                  status: error.status,
                },
                {
                  action: 'startSurvey',
                  data: action,
                }
              )
            )
        );
    }
  }



  @Action(BuildComponentDescriptors)
  buildComponentDescriptors(ctx: StateContext<SurveyDocumentStateModel>) {
    const state: SurveyDocumentStateModel = ctx.getState();
    ctx.dispatch(
      new BuildComponentDescriptorsSuccess(
        state.surveyDocument.survey.surveySections.map(
          (surveySection: SurveySection) =>
            <ComponentDescriptor>{ component: surveySection.component }
        )
      )
    );
  }

  @Action(BuildQuestionList)
  buildQuestionList(ctx: StateContext<SurveyDocumentStateModel>) {
    const state: SurveyDocumentStateModel = ctx.getState();
    const surveySections: SurveySection[] = state.surveyDocument.survey.surveySections.filter(
      (surveySection: SurveySection) => surveySection.questionGroups !== null
    );

    let questionList: QuestionListItem[] = SurveyDocumentState.buildQuestionList(
      surveySections,
      state.surveyAnswers
    );

    ctx.dispatch(new BuildQuestionListSuccess(questionList));
  }

  @Action(BuildSurveySections)
  buildSurveySections(ctx: StateContext<SurveyDocumentStateModel>) {
    const state: SurveyDocumentStateModel = ctx.getState();
    ctx.dispatch(
      new BuildSurveySectionsSuccess(
        state.surveyDocument.survey.surveySections.map(
          (surveySection: SurveySection) => surveySection
        )
      )
    );
  }

  @Action(Navigation)
  navigation(ctx: StateContext<SurveyDocumentStateModel>, action: Navigation) {
    ctx.patchState({
      lastNavigationEvent: action.navigationEvent,
    });
  }

  @Action(SetScrollTarget)
  setScrollTarget(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: SetScrollTarget
  ) {
    ctx.patchState({
      scrollTarget: action.scrollTarget,
    });
  }

  @Action(JumpTo)
  jumpTo(ctx: StateContext<SurveyDocumentStateModel>, action: JumpTo) {
    const state: SurveyDocumentStateModel = ctx.getState();
    const index: number = state.surveySections.findIndex(
      (surveySection: SurveySection) =>
        surveySection.sectionId === action.sectionId
    );

    ctx.dispatch(
      new Navigation({
        source: MENU,
        sectionIndex: index > -1 ? index : null,
      })
    );
  }

  @Action(ReviewSection)
  reviewSection(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: ReviewSection
  ) {
    const state: SurveyDocumentStateModel = ctx.getState();
    const index: number = state.surveySections.findIndex(
      (surveySection: SurveySection) =>
        surveySection.sectionId === action.sectionId
    );

    ctx.dispatch(
      new Navigation({
        source: REVIEW_SECTION,
        sectionIndex: index > -1 ? index : null,
      })
    );
  }

  @Action(ReviewQuestion)
  reviewQuestion(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: ReviewQuestion
  ) {
    const state: SurveyDocumentStateModel = ctx.getState();
    const index: number = state.surveySections.findIndex(
      (surveySection: SurveySection) =>
        surveySection.sectionId === action.sectionId
    );
    ctx.dispatch(new SetScrollTarget(action.formItemId));
    ctx.dispatch(
      new Navigation({
        source: REVIEW_QUESTION,
        sectionIndex: index,
      })
    );
  }

  @Action(AnswerQuestions)
  answerQuestions(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: AnswerQuestions
  ) {
    ctx.patchState({
      answeringInProcess: true,
    });

    const state: SurveyDocumentStateModel = ctx.getState();

    // deep copy the question list
    let questionList: QuestionListItem[] = JSON.parse(
      JSON.stringify(state.questionList)
    );

    // update the answers for the UI
    action.answers.forEach((questionAnswer: QuestionAnswer) => {
      let questionListItem: QuestionListItem = questionList.find(
        (questionListItem: QuestionListItem) =>
          questionListItem.formItemId === questionAnswer.formItemId
      );
      questionListItem.answer = questionAnswer.answer;
    });

    ctx.patchState({
      questionList: JSON.parse(JSON.stringify(questionList)),
    });

    ctx.dispatch(new AnswerQuestionsSuccess(action.answers));
  }

  @Action(ExitSurvey)
  exitSurvey(ctx: StateContext<SurveyDocumentStateModel>) {
    ctx.dispatch(new NavigateHome());
  }

  @Action(SubmitSurvey)
  submitSurvey(ctx: StateContext<SurveyDocumentStateModel>) {
    const state: SurveyDocumentStateModel = ctx.getState();

    this._surveyService.submitSurvey(state.surveyDocument.id).subscribe(
      () => ctx.dispatch(new SubmitSurveySuccess()),
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'submitSurvey',
              data: `Submitting surveyDocumentId ${state.surveyDocument.id}`,
            }
          )
        )
    );
  }

  @Action(ChangeLanguage)
  changeLanguage(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: ChangeLanguage
  ) {
    const state: SurveyDocumentStateModel = ctx.getState();

    this._surveyService
      .changeLanguage(
        state.surveyDocument.participantId,
        state.surveyDocument.individualId,
        state.surveyDocument.survey.instrumentId,
        state.surveyDocument.survey.surveyTypeId,
        state.surveyDocument.survey.languageId,
        action.newLanguage
      )
      .subscribe(
        (startSurvey: StartSurveyResponse) =>
          ctx.dispatch(
            new ChangeLanguageSuccess(
              startSurvey.surveyDocument,
              startSurvey.participant,
              startSurvey.raterType
            )
          ),
        (error) =>
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'changeLanguage',
                data: {
                  participantId: state.surveyDocument.participantId,
                  individualId: state.surveyDocument.individualId,
                  instrumentId: state.surveyDocument.survey.instrumentId,
                  surveyTypeId: state.surveyDocument.survey.surveyTypeId,
                  languageId: state.surveyDocument.survey.languageId,
                  newLangauge: action.newLanguage,
                },
              }
            )
          )
      );
  }

  @Action(RefreshAnswers)
  refreshAnswers(ctx: StateContext<SurveyDocumentStateModel>) {
    let surveyId: string = ctx.getState().surveyDocument.id;

    this._surveyService.refreshAnswers(surveyId).subscribe(
      (surveyAnswers: SurveyAnswer[]) => {
        ctx.dispatch(new RefreshAnswersSuccess(surveyAnswers));
      },
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'refreshAnswers',
              data: surveyId,
            }
          )
        )
    );
  }

  // Event handlers
  @Action(StartSurveySuccess)
  StartSurveySuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: StartSurveySuccess
  ) {
    this._directFeedbackAnswerService.clear();
    ctx.patchState({
      surveyDocument: action.surveyDocument,
      surveyAnswers: action.surveyAnswers,
      participant: action.participant,
      raterType: action.raterType,
    });
    ctx.dispatch(new BuildComponentDescriptors());
  }

  @Action(BuildComponentDescriptorsSuccess)
  buildComponentDescriptorsSuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: BuildComponentDescriptorsSuccess
  ) {
    ctx.patchState({
      componentDescriptors: action.componentDescriptors,
    });
    ctx.dispatch(new BuildQuestionList());
  }

  @Action(BuildQuestionListSuccess)
  buildQuestionListSuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: BuildQuestionListSuccess
  ) {
    ctx.patchState({
      questionList: action.questionList,
    });
    ctx.dispatch(new BuildSurveySections());
  }

  @Action(BuildSurveySectionsSuccess)
  buildSurveySectionSuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: BuildSurveySectionsSuccess
  ) {
    const state: SurveyDocumentStateModel = ctx.getState();

    // determine where to start the survey base on firstUnansweredFormItemId
    let currentSectionIndex: number = 0;

    if (state.surveyDocument.survey.lastAnsweredFormItemId !== 0) {
      const lastAnswered: QuestionListItem = state.questionList.find(
        (questionListItem: QuestionListItem) =>
          questionListItem.formItemId ===
          state.surveyDocument.survey.lastAnsweredFormItemId
      );
      if (lastAnswered) {
        const sectionQuestionList: QuestionListItem[] = state.questionList.filter(
          (questionListItem: QuestionListItem) =>
            questionListItem.sectionId == lastAnswered.sectionId
        );
        currentSectionIndex = state.surveyDocument.survey.surveySections.findIndex(
          (surveySection: SurveySection) =>
            surveySection.sectionId === lastAnswered.sectionId
        );

        if (
          sectionQuestionList[sectionQuestionList.length - 1].formItemId ===
          lastAnswered.formItemId
        ) {
          currentSectionIndex++;
        }
      }
    }

    ctx.patchState({
      surveySections: action.surveySections,
      lastNavigationEvent: {
        source: START,
        sectionIndex: currentSectionIndex,
      },
    });

    ctx.dispatch(new Navigate(['/survey']));
  }

  @Action(AnswerQuestionsSuccess)
  answerQuestionsSuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: AnswerQuestionsSuccess
  ) {
    const state: SurveyDocumentStateModel = ctx.getState();

    this._surveyService
      .answerQuestion(state.surveyDocument.id, action.answers)
      .subscribe(
        () => {
          // if successful add them to the survey answers list
          const state: SurveyDocumentStateModel = ctx.getState();

          // deep copy the question list
          let surveyAnswers: SurveyAnswer[] = JSON.parse(
            JSON.stringify(state.surveyAnswers)
          );

          action.answers.forEach((answer: QuestionAnswer) => {
            surveyAnswers.push(<SurveyAnswer>{
              surveyDocumentId: state.surveyDocument.id,
              formItemId: answer.formItemId,
              formItemSequenceNumber: answer.formItemSequenceNumber,
              answer: answer.answer,
              optional: answer.optional,
              answeredTimeMs: answer.answeredTimeMs,
            });
          });

          ctx.patchState({
            answeringInProcess: false,
            surveyAnswers: JSON.parse(JSON.stringify(surveyAnswers)),
          });
        },
        (error) => {
          // undo all the answers
          const state: SurveyDocumentStateModel = ctx.getState();

          // deep copy the question list
          let questionList: QuestionListItem[] = JSON.parse(
            JSON.stringify(state.questionList)
          );

          action.answers.forEach((questionAnswer: QuestionAnswer) => {
            let questionListItem: QuestionListItem = questionList.find(
              (questionListItem: QuestionListItem) =>
                questionListItem.formItemId === questionAnswer.formItemId
            );
            questionListItem.answer = questionAnswer.previousAnswer;
          });

          ctx.patchState({
            answeringInProcess: false,
            questionList: JSON.parse(JSON.stringify(questionList)),
          });

          // throw an exception
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'answerQuestionsSuccess',
                data: {
                  surveyId: state.surveyDocument.id,
                  answers: action.answers,
                },
              }
            )
          );
        }
      );
  }

  @Action(SubmitSurveySuccess)
  submitSurveySuccess(ctx: StateContext<SurveyDocumentStateModel>) {
    const state: SurveyDocumentStateModel = ctx.getState();

    ctx.patchState({
      lastNavigationEvent: {
        source: SUBMIT,
        sectionIndex: state.surveySections.length - 1,
      },
    });
  }

  @Action(ChangeLanguageSuccess)
  ChangeLanguageSuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: ChangeLanguageSuccess
  ) {
    this._directFeedbackAnswerService.clear();
    ctx.patchState({
      surveyDocument: action.surveyDocument,
      participant: action.participant,
      raterType: action.raterType,
    });
    ctx.dispatch(new BuildComponentDescriptors());
  }

  @Action(RefreshAnswersSuccess)
  refreshAnswersSuccess(
    ctx: StateContext<SurveyDocumentStateModel>,
    action: RefreshAnswersSuccess
  ) {
    const state: SurveyDocumentStateModel = ctx.getState();
    const surveySections: SurveySection[] = state.surveyDocument.survey.surveySections.filter(
      (surveySection: SurveySection) => surveySection.questionGroups !== null
    );

    let questionList: QuestionListItem[] = SurveyDocumentState.buildQuestionList(
      surveySections,
      action.surveyAnswers
    );

    ctx.patchState({
      surveyAnswers: action.surveyAnswers,
      questionList: questionList,
    });
  }
}
