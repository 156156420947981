<div *ngIf="(raterLanding$ | async) as raterLanding" style="height: 100%">
  <div fxLayout fxLayoutAlign="center" style="height: 100%">
    <div fxFlex.lt-md="100" fxFlex="0 0 900px" style="background-color: white; overflow-y:auto; height: 100%;">
      <ngx-loading [show]="showLoading"></ngx-loading>
      <!-- <div fxLayout="column" fxLayoutGap="15px" style="padding: 20px;">
        <div *ngIf="(applicationMessage$ | async) as applicationMessage">
          <assess-warning-bar *ngIf="applicationMessage.haveMessage" [warning]="applicationMessage.message" tabindex="0"></assess-warning-bar>
        </div>
        <div>
          <span class="mat-title" tabindex="0">{{'raterLanding.header.title' | translate}}</span>
        </div>
        <div call="mat-body" fxShow fxHide.lt-sm="true" tabindex="0">
          {{'raterLanding.header.instructions' | translate}}
        </div>
        <assess-survey-card *ngFor="let surveyStatus of raterLanding.surveyStatuses" [surveyStatus]="surveyStatus"
          (surveyClick)="onSurveyClick($event)"></assess-survey-card>
      </div> -->
    </div>
  </div>
</div>