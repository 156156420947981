import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';

import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';

import { TranslateService } from '@ngx-translate/core';

import { User } from '../../core/auth/user.model';
import { RaterLanding, SurveyStatus } from './models/rater-landing.model';
import { RaterLandingService } from './services/rater-landing.service';
import { ApplicationState } from '../../store/application.state';
import { ApplicationMessage, Config } from '../../store/application.models';
import { StartSurvey, UserNotFound } from '../survey/store/survey.actions';
import { InformationDialogComponent } from '../../shared/dialogs/information-dialog/information-dialog.component';

@Component({
  selector: 'assess-rater-landing',
  templateUrl: './rater-landing.component.html',
  styleUrls: ['./rater-landing.component.scss'],
})
export class RaterLandingComponent implements OnInit, OnDestroy {
  @Select(ApplicationState.getApplicationMessage)
  applicationMessage$: Observable<ApplicationMessage>;

  @Input()
  handleLoading: boolean = true;

  @Output()
  loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  raterLanding$: Observable<RaterLanding>;
  user: User = null;
  showLoading: boolean = false;

  private _userNotFoundSubscription: Subscription;
  public assessmentsUri;
  public assessments2Uri;
  public instrumentId;
  public participantId;
  public retUrl;
  public surveyType;

  constructor(
    private _store: Store,
    private _raterLandingService: RaterLandingService,
    private _actions$: Actions,
    private _dialog: MatDialog,
    private _translateService: TranslateService
  ) {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );
    this.assessmentsUri = config.authRedirectUri;
    this.assessments2Uri = config.assessments2Uri;
    this.instrumentId = this._store.selectSnapshot(ApplicationState.getInstrumentId);
    this.participantId = this._store.selectSnapshot(ApplicationState.getParticipantId);  
    this.retUrl = this._store.selectSnapshot(ApplicationState.getRedirectUrl);
    this.surveyType = this._store.selectSnapshot(ApplicationState.getSurveyType); 
  }

  ngOnInit() {
    this.user = this._store.selectSnapshot(ApplicationState.getUser);
    this.raterLanding$ = this._raterLandingService.getRaterLanding(
      this.user.individualId,
      this.user.preferredLanguageId
    );

    if (this.instrumentId > 0 && this.participantId > 0) {
      let instrumentArray = [];
      instrumentArray.push({
        'id': this.instrumentId,
        'pid': this.participantId,
        'type': this.surveyType,
        'retUrl': this.assessments2Uri
      });

      if (instrumentArray.length > 0) {
        localStorage.setItem("instrumentArray", JSON.stringify([]));
      }
      this.raterLanding$.forEach(x => {
        x.surveyStatuses.forEach(y => {
          if (y.instrument.instrumentId == this.instrumentId && y.participant.participantId == this.participantId) {
            this.onSurveyClick(y);
          }
        });
      });
    }
    else {
      localStorage.setItem("instrumentArray", JSON.stringify([]));
      window.location.href = this.assessments2Uri;
    }

    this._userNotFoundSubscription = this._actions$
      .pipe(ofActionSuccessful(UserNotFound))
      .subscribe(() => {
        if (this.handleLoading) {
          this.showLoading = false;
        } else {
          this.loading.emit(false);
        }
        this._dialog.open(InformationDialogComponent, {
          width: '700px',
          data: {
            title: this._translateService.instant('dialog.userNotFound.title'),
            question: this._translateService.instant(
              'dialog.userNotFound.text'
            ),
            okButton: this._translateService.instant('dialog.ok'),
          },
        });
      });
  }

  ngOnDestroy(): void {
    if (this._userNotFoundSubscription) {
      this._userNotFoundSubscription.unsubscribe();
    }
  }

  onSurveyClick(surveyStatus: SurveyStatus): void {
    if (this.handleLoading) {
      this.showLoading = true;
    } else {
      this.loading.emit(true);
    }
    this._store.dispatch(
      new StartSurvey(
        surveyStatus.instrument.instrumentId,
        1,
        surveyStatus.rater.preferredLanguageId,
        surveyStatus.participant.participantId,
        this.user.individualId
      )
    );
  }
}
