<div fxShow fxHide.lt-sm="true">
  <mat-card>
    <mat-card-content>
      <div fxFlex="89%" tabindex="0"  fxLayoutAlign="space-between center">
        <span style="margin-left: 7px;">{{"participant.aboutMe.assessmentName" | translate}}</span>
      </div>
      <button mat-raised-button color="primary" (click)="aboutMeClick()">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <span>{{ "participant.button.self" | translate }}</span>
          <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
      </div>
      </button>
     </mat-card-content>
     <span style="font-size: 12px; margin-left: 7px;text-align:justify">{{"participant.aboutMe.instructionText" | translate }}</span>
  </mat-card>
</div>

<div fxHide fxShow.lt-sm="true" fxLayout="column" fxLayoutGap="10px">
  <mat-card>
    <mat-card-content>
      <div fxFlex="89%" tabindex="0"  fxLayoutAlign="space-between center">
        <span style="margin-left: 7px;">{{"participant.aboutMe.assessmentName" | translate}}</span>
      </div>
      <button mat-raised-button color="primary" (click)="aboutMeClick()">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <span>{{ "participant.button.self" | translate }}</span>
          <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
      </div>
      </button>
     </mat-card-content>
     <span style="font-size: 12px; margin-left: 7px;text-align: justify;">{{"participant.aboutMe.instructionText" | translate }}</span>
  </mat-card>
</div>
