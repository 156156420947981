import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  LOG_ENTRIES_CONFIG_FACTORY,
  LogEntriesConfig
} from './log-entries-log.model';
import { LogEntriesLogPublisher } from './log-entries-log-publisher';
import { LogPublisherFactory } from '../../log-publisher-factory';
import { LogPublisher } from '../../log-publisher';
import { LogEntriesConfigFactory } from './log-entries-config.factory';

@Injectable({
  providedIn: 'root'
})
export class LogEntriesLogPublisherFactory extends LogPublisherFactory {
  constructor(
    @Inject(LOG_ENTRIES_CONFIG_FACTORY)
    private _configFactory: LogEntriesConfigFactory,
    private _httpClient: HttpClient
  ) {
    super();
  }

  create(): Observable<LogPublisher> {
    return this._configFactory.getConfig().pipe(
      switchMap((config: LogEntriesConfig) => {
        return of(new LogEntriesLogPublisher(config, this._httpClient));
      })
    );
  }
}
