<mat-card class="mt-2">
  <mat-card-content>
    <div fxShow fxHide.lt-md="true" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="30%">
          <assess-rater-type-label [raterStatus]="raterStatus"></assess-rater-type-label>
        </div>
        <div fxFlex="30%" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px"
            [innerHtml]="'raterManagement.card.assigned' | translate:{count: raterStatus.raterInvitedCount}"
            tabindex="0"></div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px"
            [innerHtml]="'raterManagement.card.received' | translate:{count: raterStatus.raterSurveySubmittedCount}"
            tabindex="0">
          </div>
        </div>
        <div fxFlex="40%" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <div *ngIf="raterStatus.allSurveysReceived; else actionPanel" fxLayout="row" fxLayoutAlign="start center"
            fxLayoutGap="5px" class="text-success">
            <i class="fa fa-check-circle-o fa-2x" aria-hidden="true"></i>
            <div tabindex="0">{{'raterManagement.card.status' | translate}}</div>
          </div>
          <ng-template #actionPanel>
            <button mat-raised-button color="primary"
              *ngIf="!raterStatus.allSurveysReceived && raterStatus.raterInvitedCount > 0" (click)="onRemindRaters()" attr.aria-label="{{'raterManagement.card.buttons.remind' | translate }} {{raterStatus.raterType.name}}">
              {{'raterManagement.card.buttons.remind' | translate}}
            </button>
          </ng-template>
          <button mat-raised-button color="primary" (click)="onAddRatersClick()" attr.aria-label="{{'raterManagement.card.buttons.add' | translate}} {{raterStatus.raterType.name}}" 
            >
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <span>{{'raterManagement.card.buttons.add' | translate}}</span>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
      <div>
        <div (click)="toggle()" (keypress)="toggle()" class="expansion" tabindex="0">
          {{'raterManagement.card.expander' | translate}}
          <i class="fa fa-angle-down" [@disclosureState]='state' aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div fxHide fxShow.lt-md="true" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxFlex="75%">
          <assess-rater-type-label [raterStatus]=" raterStatus">
          </assess-rater-type-label>
        </div>
        <div fxFlex="25%" fxLayout="column" fxLayoutGap="3px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px"
            [innerHtml]="'raterManagement.card.assigned' | translate:{count: raterStatus.raterInvitedCount}"></div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px"
            [innerHtml]="'raterManagement.card.received' | translate:{count: raterStatus.raterSurveySubmittedCount}">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div (click)="toggle()" class="expansion">
          {{'raterManagement.card.expander' | translate}}
          <i class="fa fa-angle-down" [@disclosureState]='state' aria-hidden="true"></i>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div *ngIf="raterStatus.allSurveysReceived; else actionPanel" fxLayout="row" fxLayoutAlign="start center"
            fxLayoutGap="5px" class="text-success">
            <i class="fa fa-check-circle-o fa-2x" aria-hidden="true"></i>
            <div>{{'raterManagement.card.status' | translate}}</div>
          </div>
          <ng-template #actionPanel>
            <button mat-raised-button color="primary"
              *ngIf="!raterStatus.allSurveysReceived && raterStatus.raterInvitedCount > 0" (click)="onRemindRaters()"
            >
              {{'raterManagement.card.buttons.remind' | translate}} 
            </button>
          </ng-template>
          <button mat-raised-button color="primary" (click)="onAddRatersClick()"
            attr.aria-label="{{'raterManagement.card.buttons.add'| translate}} {{raterStatus.raterType.name}}">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <span>{{'raterManagement.card.buttons.add' | translate}}</span>
              <i class="fa fa-angle-right fa-fw" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card [@collapsedState]='state'>
  <mat-card-content>
    <div fxShow fxHide.lt-md="true" *ngIf="dataSource.data.length > 0 else noRaters">
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-cell *matCellDef="let element" tabindex="0">{{element.firstName}} {{element.lastName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-cell *matCellDef="let element" tabindex="0">{{element.email}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
          <mat-cell *matCellDef="let element" tabindex="0">{{element.language.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" (click)="onResendInvitationClick(element)"
              [disabled]="!element.canResendInvitation" aria-label="Resend Inviation">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </button>
            <button mat-icon-button color="primary" (click)="onDeleteRaterClick(element)"
              [disabled]="!element.canDelete" aria-label="Delete Rater">
              <i class="fa fa-remove" aria-hidden="true"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <div fxHide fxShow.lt-md="true">
      <div *ngFor="let rater of raterStatus.raters; let i=index" class="striped" [ngClass]="{'highlight': i % 2 === 1}">
        <div fxLayout="row">
          <div fxFlex="100%" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div style="font-weight: bold;">{{rater.firstName}} {{rater.lastName}}</div>
              <div>
                <button mat-icon-button color="primary" (click)="onResendInvitationClick(rater)"
                  [disabled]="!rater.canResendInvitation">
                  <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                </button>
                <button mat-icon-button color="primary" (click)="onDeleteRaterClick(rater)"
                  [disabled]="!rater.canDelete">
                  <i class="fa fa-remove fa-2x" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="text-muted">{{rater.email}}</div>
            <div class="text-muted">{{rater.language.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noRaters>
      <div class="rater-row" ngClass.xs="mobile">{{'raterManagement.card.noneAssigned'
        | translate}}</div>
    </ng-template>
  </mat-card-content>
</mat-card>