import { Component, Input } from '@angular/core';

import { SurveySection } from '../../store/survey.model';

@Component({
  selector: 'assess-survey-instruction',
  templateUrl: './survey-instruction.component.html'
})
export class SurveyInstructionComponent {
  @Input()
  surveySection: SurveySection;
}
