import {
  Component,
  OnInit,
  Input,
  Injector,
  Type,
  ComponentFactory,
  ComponentRef,
  ComponentFactoryResolver,
  ViewChild,
} from '@angular/core';

import * as Registry from '../../type-registry';

import { UUID } from 'angular2-uuid';

import { BackgroundComponent } from '../../background-component';
import {
  BackgroundItem,
  BackgroundResponse,
} from '../../store/background-landing.models';
import { SchoolHostDirective } from '../../directives/school-host.directive';

@Component({
  selector: 'assess-schools',
  templateUrl: './schools.component.html',
})
export class SchoolsComponent implements OnInit, BackgroundComponent {
  @ViewChild(SchoolHostDirective, { static: true })
  schoolHost: SchoolHostDirective;

  @Input()
  backgroundItem: BackgroundItem;
  @Input()
  backgroundResponses: BackgroundResponse[];

  canAddSchool: boolean = true;

  private _backgroundComponents: ComponentRef<any>[] = [];

  constructor(private _resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.backgroundItem.dependentBackgroundItems.forEach(
      (backgroundItem: BackgroundItem) => {
        const responseOne: BackgroundResponse = this.backgroundResponses.find(
          (backgroundResponse: BackgroundResponse) =>
            backgroundResponse.backgroundItemId ===
            backgroundItem.dependentBackgroundItems[0].backgroundItemId
        );
        const responseTwo: BackgroundResponse = this.backgroundResponses.find(
          (backgroundResponse: BackgroundResponse) =>
            backgroundResponse.backgroundItemId ===
            backgroundItem.dependentBackgroundItems[1].backgroundItemId
        );

        if (
          (responseOne && responseOne.response !== null) ||
          (responseTwo && responseTwo.response !== null)
        ) {
          this.createComponent(backgroundItem, this.backgroundResponses);
        }
      }
    );

    if (this._backgroundComponents.length < 5) {
      this.createComponent(
        this.backgroundItem.dependentBackgroundItems[0],
        null
      );
    }

    this.canAddSchool = this._backgroundComponents.length < 5;
  }

  getResponse(): any {
    let response: BackgroundResponse = <BackgroundResponse>{
      backgroundItemId: this.backgroundItem.backgroundItemId,
      response: null,
      dependentResponses: [],
    };

    // fill up the array of dependent responses
    for (let index = 0; index < 5; index++) {
      const dependentBackgroundItem: BackgroundItem = this.backgroundItem
        .dependentBackgroundItems[index];

      const schoolNameBackgroundItem: BackgroundItem = dependentBackgroundItem.dependentBackgroundItems.find(
        (backgroundItem: BackgroundItem) =>
          backgroundItem.backgroundItemId === 13 ||
          backgroundItem.backgroundItemId === 35 ||
          backgroundItem.backgroundItemId === 37 ||
          backgroundItem.backgroundItemId === 39 ||
          backgroundItem.backgroundItemId === 41
      );
      const majorStudyBackgroundItem: BackgroundItem = dependentBackgroundItem.dependentBackgroundItems.find(
        (backgroundItem: BackgroundItem) =>
          backgroundItem.backgroundItemId === 14 ||
          backgroundItem.backgroundItemId === 36 ||
          backgroundItem.backgroundItemId === 38 ||
          backgroundItem.backgroundItemId === 40 ||
          backgroundItem.backgroundItemId === 42
      );

      let schoolName: BackgroundResponse = {
        backgroundItemId: schoolNameBackgroundItem.backgroundItemId,
        dependentResponses: null,
        response: null,
      };

      let majorStudy: BackgroundResponse = {
        backgroundItemId: majorStudyBackgroundItem.backgroundItemId,
        dependentResponses: null,
        response: null,
      };

      response.dependentResponses.push([schoolName, majorStudy]);
    }

    // get all the responses and package them up to be saved
    this._backgroundComponents.forEach(
      (component: ComponentRef<any>, index: number) => {
        const dependentResponse: Map<
          string,
          string
        > = (component.instance as BackgroundComponent).getResponse();

        if (dependentResponse !== null) {
          // set the responses
          response.dependentResponses[
            index
          ][0].response = dependentResponse.get('school-name');
          response.dependentResponses[
            index
          ][1].response = dependentResponse.get('major-study');
        }
      }
    );

    return response;
  }

  onAddSchoolClick(): void {
    this.createComponent(
      this.backgroundItem.dependentBackgroundItems[
        this._backgroundComponents.length
      ],
      null
    );
    this.canAddSchool = this._backgroundComponents.length < 5;
  }

  onSchoolDeleted(id: string): void {
    const component = this._backgroundComponents.find(
      (component) => component.instance.id === id
    );

    const componentIndex = this._backgroundComponents.indexOf(component);

    if (componentIndex !== -1) {
      // Remove component from both view and array
      this.schoolHost.viewContainerRef.remove(
        this.schoolHost.viewContainerRef.indexOf(component.hostView)
      );
      this._backgroundComponents.splice(componentIndex, 1);
    }

    if (this._backgroundComponents.length === 0) {
      this.createComponent(
        this.backgroundItem.dependentBackgroundItems[0],
        null
      );
    }

    this.canAddSchool = this._backgroundComponents.length < 5;
  }

  private createComponent(
    backgroundItem: BackgroundItem,
    backgroundResponses: BackgroundResponse[]
  ): void {
    let componentType = 'School';

    let type = Registry.getTypeFor(componentType);
    if (!type)
      throw `
      ${componentType} not registered.
      Usage:
      The following line must be placed in the module's constructor:
      Registry.setTypeFor('${componentType}', ${componentType});
    `;

    this.renderComponent(
      type,
      this.createInjector(),
      backgroundItem,
      backgroundResponses
    );
  }

  private createInjector(): Injector {
    let injector: Injector = Injector.create(
      [],
      this.schoolHost.viewContainerRef.parentInjector
    );

    return injector;
  }

  private renderComponent(
    type: Type<any>,
    injector: Injector,
    backgroundItem: BackgroundItem,
    backgroundResponses: BackgroundResponse[]
  ): void {
    let factory: ComponentFactory<any> = this._resolver.resolveComponentFactory(
      type
    );

    let component: ComponentRef<any> = factory.create(injector);
    component.instance.backgroundItem = backgroundItem;
    component.instance.backgroundResponses = backgroundResponses;
    component.instance.id = UUID.UUID();
    component.instance.schoolDeleted.subscribe((id: string) =>
      this.onSchoolDeleted(id)
    );

    this.schoolHost.viewContainerRef.insert(component.hostView);
    this._backgroundComponents.push(component);
  }
}
