import { NgModule } from '@angular/core';

import {
  SurveyStepperNext,
  SurveyStepperPrevious,
} from './survey-stepper-button';

import { SurveyStepperService } from './survey-stepper.service';

import { CardHostDirective } from './card-host.directive';
import { SurveyStepperComponent } from './survey-stepper.component';

@NgModule({
  declarations: [
    SurveyStepperComponent,
    SurveyStepperNext,
    SurveyStepperPrevious,
    CardHostDirective,
  ],
  providers: [SurveyStepperService],
  exports: [SurveyStepperComponent, SurveyStepperNext, SurveyStepperPrevious],
})
export class SurveyStepperModule {}
