import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalStart',
})
export class CapitalStartPipe implements PipeTransform {
  constructor() {}

  public transform(value: string): any {
    return value.replace(/^\w/, (c) => c.toUpperCase());
  }
}
