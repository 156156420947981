import { Injectable, Inject, Optional } from '@angular/core';

import { LOG_PUBLISHER_FACTORIES } from './log.model';
import { LogPublisherFactory } from './log-publisher-factory';
import { LogPublisher } from './log-publisher';

@Injectable({
  providedIn: 'root'
})
export class LogPublishersService {
  publishers: LogPublisher[] = [];

  constructor(
    @Inject(LOG_PUBLISHER_FACTORIES)
    @Optional()
    private _logPublisherFactories: LogPublisherFactory[]
  ) {
    this.register();
  }

  private register(): void {
    if (!this._logPublisherFactories) {
      return;
    }

    this._logPublisherFactories.map(
      (logPublisherFactory: LogPublisherFactory) =>
        logPublisherFactory.create().subscribe((logPublisher: LogPublisher) => {
          this.publishers.push(logPublisher);
        })
    );
  }
}
