import { Component, OnInit, Input } from '@angular/core';

import { MatRadioChange } from '@angular/material/radio';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  QuestionListItem,
  QuestionAnswer,
} from '../../../../../survey/store/survey.model';

import { SurveyDemographicsService } from '../../services/survey-demographics.service';
import { SurveyDocumentState } from '../../../../../survey/store/survey.state';

@Component({
  selector: 'assess-grouped-single-option',
  templateUrl: './grouped-single-option.component.html',
})
export class GroupedSingleOptionComponent implements OnInit {
  @Input() question: Question;
  @Input() index: number;

  questionListItem$: Observable<QuestionListItem>;

  constructor(
    private _store: Store,
    private _surveyDemographicsService: SurveyDemographicsService
  ) {}

  ngOnInit(): void {
    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.question.formItemId)));
  }

  onOptionChanged(event: MatRadioChange): void {
    let answer: QuestionAnswer = Object.assign(
      {},
      { ...this.question, answer: event.value }
    );
    this._surveyDemographicsService.answerQuestion(answer);
  }
}
