import { InstrumentStatus } from '../../participant-landing/models/participant.model';
import { User } from '../../../core/auth/user.model';

// actions
export class LoadAsset {
  static readonly type = '[Report Viewer] Load Asset';
  constructor(public user: User, public instrumentStatus: InstrumentStatus) {}
}

export class LoadAssetSuccess {
  static readonly type = '[Report Viewer] Load Asset Success';
  constructor(public report: Blob, public instrumentStatus: InstrumentStatus) {}
}

export class LoadDocument {
  static readonly type = '[Report Viewer] Load Document';
  constructor(public instrumentStatus: InstrumentStatus) {}
}

export class LoadDocumentSuccess {
  static readonly type = '[Report Viewer] Load Document Success';
  constructor(public report: Blob, public instrumentStatus: InstrumentStatus) {}
}
