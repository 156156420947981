import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';

import { NgxsModule } from '@ngxs/store';

import { TranslateModule } from '@ngx-translate/core';

import { NgxLoadingModule } from 'ngx-loading';

import * as Registry from './modules/survey-stepper/type-registry';

import { SharedModule } from '../../shared/shared.module';
import { SurveyRoutingModule } from './survey-routing.module';
import { SurveyStepperModule } from './modules/survey-stepper/survey-stepper.module';
import { SurveyDemographicsModule } from './modules/survey-demographics/survey-demographics.module';

import { SurveyComponent } from './survey.component';
import { SurveyScaleComponent } from './components/survey-scale/survey-scale.component';
import { SurveySectionInfoDialogComponent } from './containers/survey-section-info-dialog/survey-section-info-dialog.component';
import { SurveySectionHeaderComponent } from './components/survey-section-header/survey-section-header.component';
import { SurveyInformationComponent } from './components/survey-information/survey-information.component';
import { SurveyNextStepButtonComponent } from './components/survey-next-step-button/survey-next-step-button.component';
import { SurveyReviewQuestionsComponent } from './containers/survey-review-questions/survey-review-questions.component';
import { SurveySectionTitleComponent } from './containers/survey-section-title/survey-section-title.component';
import { SurveyScalesQuestionComponent } from './components/survey-scales-question/survey-scales-question.component';
import { SurveyReviewQuestionComponent } from './components/survey-review-question/survey-review-question.component';
import { SurveyCompetenciesQuestionComponent } from './components/survey-competencies-question/survey-competencies-question.component';
import { SurveyDirectFeedbackQuestionComponent } from './components/survey-direct-feedback-question/survey-direct-feedback-question.component';
import { SurveyScalesQuestionsComponent } from './containers/survey-scales-questions/survey-scales-questions.component';
import { SurveyCompetenciesQuestionsComponent } from './containers/survey-competencies-questions/survey-competencies-questions.component';
import { SurveyCompleteComponent } from './containers/survey-complete/survey-complete.component';
import { SurveyContainerComponent } from './containers/survey-container/survey-container.component';
import { SurveyDemographicsQuestionsComponent } from './containers/survey-demographics-questions/survey-demographics-questions.component';
import { SurveyDirectFeedbackQuestionsComponent } from './containers/survey-direct-feedback-questions/survey-direct-feedback-questions.component';
import { SurveyDocumentState } from './store/survey.state';
import { SurveyWelcomeComponent } from './containers/survey-welcome/survey-welcome.component';
import { SurveyPreviousStepButtonComponent } from './components/survey-previous-step-button/survey-previous-step-button.component';
import { SurveyScalesQuestionsGroupComponent } from './containers/survey-scales-questions-group/survey-scales-questions-group.component';
import { TabbedSectionState } from './store/tabbed-section.state';
import { SurveyCompetenciesReviewComponent } from './containers/survey-competencies-review/survey-competencies-review.component';
import { SurveyWelcomeQuestionComponent } from './components/survey-welcome-question/survey-welcome-question.component';
import { SurveyMenuComponent } from './containers/survey-menu/survey-menu.component';
import { SurveyInstructionComponent } from './components/survey-instruction/survey-instruction.component';
import { SurveyAOrBQuestionComponent } from './components/survey-a-or-b-question/survey-a-or-b-question.component';
import { SurveyAOrBQuestionsComponent } from './containers/survey-a-or-b-questions/survey-a-or-b-questions.component';
import { SurveyAOrBQuestionsGroupComponent } from './containers/survey-a-or-b-questions-group/survey-a-or-b-questions-group.component';
import { SurveyAdditionalInstructionsComponent } from './components/survey-additional-instructions/survey-additional-instructions.component';
import { SurveyAAndBQuestionsGroupComponent } from './containers/survey-a-and-b-questions-group/survey-a-and-b-questions-group.component';
import { SurveyAAndBQuestionsComponent } from './containers/survey-a-and-b-questions/survey-a-and-b-questions.component';
import { SurveyAAndBQuestionComponent } from './components/survey-a-and-b-question/survey-a-and-b-question.component';
import { SurveySectionIntroductionComponent } from './containers/survey-section-introduction/survey-section-introduction.component';
import { SurveyDirectFeedbackTabbedQuestionsComponent } from './containers/survey-direct-feedback-tabbed-questions/survey-direct-feedback-tabbed-questions.component';
import { SurveyDirectFeedbackQuestionsGroupComponent } from './containers/survey-direct-feedback-questions-group/survey-direct-feedback-questions-group.component';
import { SurveyMultiDropdownQuestionsComponent } from './containers/survey-multi-dropdown-questions/survey-multi-dropdown-questions.component';
import { SurveyMultiDropdownQuestionsGroupComponent } from './containers/survey-multi-dropdown-questions-group/survey-multi-dropdown-questions-group.component';
import { SurveyMultiDropdownQuestionComponent } from './components/survey-multi-dropdown-question/survey-multi-dropdown-question.component';
import { SurveyMultiScalesQuestionComponent } from './components/survey-multi-scales-question/survey-multi-scales-question.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    NgxsModule.forFeature([SurveyDocumentState, TabbedSectionState]),
    NgxLoadingModule,
    SharedModule,
    SurveyRoutingModule,
    SurveyStepperModule,
    SurveyDemographicsModule,
  ],
  declarations: [
    SurveyComponent,
    SurveyScaleComponent,
    SurveySectionInfoDialogComponent,
    SurveySectionHeaderComponent,
    SurveyInformationComponent,
    SurveyNextStepButtonComponent,
    SurveyReviewQuestionsComponent,
    SurveySectionTitleComponent,
    SurveyScalesQuestionComponent,
    SurveyReviewQuestionComponent,
    SurveyCompetenciesQuestionComponent,
    SurveyDirectFeedbackQuestionComponent,
    SurveyScalesQuestionsComponent,
    SurveyCompetenciesQuestionsComponent,
    SurveyCompleteComponent,
    SurveyContainerComponent,
    SurveyDemographicsQuestionsComponent,
    SurveyDirectFeedbackQuestionsComponent,
    SurveyWelcomeComponent,
    SurveyPreviousStepButtonComponent,
    SurveyScalesQuestionsGroupComponent,
    SurveyCompetenciesReviewComponent,
    SurveyWelcomeQuestionComponent,
    SurveyMenuComponent,
    SurveyInstructionComponent,
    SurveyAOrBQuestionComponent,
    SurveyAOrBQuestionsComponent,
    SurveyAOrBQuestionsGroupComponent,
    SurveyAdditionalInstructionsComponent,
    SurveyAAndBQuestionsGroupComponent,
    SurveyAAndBQuestionsComponent,
    SurveyAAndBQuestionComponent,
    SurveySectionIntroductionComponent,
    SurveyDirectFeedbackTabbedQuestionsComponent,
    SurveyDirectFeedbackQuestionsGroupComponent,
    SurveyMultiDropdownQuestionsComponent,
    SurveyMultiDropdownQuestionsGroupComponent,
    SurveyMultiDropdownQuestionComponent,
    SurveyMultiScalesQuestionComponent,
  ],
  entryComponents: [
    SurveyWelcomeComponent,
    SurveyDemographicsQuestionsComponent,
    SurveyScalesQuestionsComponent,
    SurveyCompetenciesQuestionsComponent,
    SurveyDirectFeedbackQuestionsComponent,
    SurveyReviewQuestionsComponent,
    SurveyCompleteComponent,
    SurveyCompetenciesReviewComponent,
    SurveySectionInfoDialogComponent,
    SurveyAOrBQuestionsComponent,
    SurveyAAndBQuestionsComponent,
    SurveySectionIntroductionComponent,
    SurveyDirectFeedbackTabbedQuestionsComponent,
    SurveyMultiDropdownQuestionsComponent,
  ],
})
export class SurveyModule {
  constructor() {
    Registry.setTypeFor(
      'SurveyAAndBQuestionsComponent',
      SurveyAAndBQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyAOrBQuestionsComponent',
      SurveyAOrBQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyCompetenciesQuestionsComponent',
      SurveyCompetenciesQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyCompetenciesReviewComponent',
      SurveyCompetenciesReviewComponent
    );
    Registry.setTypeFor('SurveyCompleteComponent', SurveyCompleteComponent);
    Registry.setTypeFor(
      'SurveyDemographicsQuestionsComponent',
      SurveyDemographicsQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyDirectFeedbackQuestionsComponent',
      SurveyDirectFeedbackQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyDirectFeedbackTabbedQuestionsComponent',
      SurveyDirectFeedbackTabbedQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyMultiDropdownQuestionsComponent',
      SurveyMultiDropdownQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyReviewQuestionsComponent',
      SurveyReviewQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveyScalesQuestionsComponent',
      SurveyScalesQuestionsComponent
    );
    Registry.setTypeFor(
      'SurveySectionIntroductionComponent',
      SurveySectionIntroductionComponent
    );
    Registry.setTypeFor('SurveyWelcomeComponent', SurveyWelcomeComponent);
  }
}
