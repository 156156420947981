import { Component, OnInit } from '@angular/core';

import { Store, Select } from '@ngxs/store';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ExitSurvey, JumpTo } from '../../store/survey.actions';
import { SurveyDocumentState } from '../../store/survey.state';
import { JumpMenu, Extension } from '../../store/survey.model';
import { Config } from 'src/app/store/application.models';
import { ApplicationState } from 'src/app/store/application.state';

@Component({
  selector: 'assess-survey-menu',
  templateUrl: './survey-menu.component.html'
})
export class SurveyMenuComponent implements OnInit {
  @Select(SurveyDocumentState.getSurveyJumpMenu)
  jumpMenu$: Observable<JumpMenu[]>;

  canStart$: Observable<any>;

  extensions: Extension[];
  public assessments2Uri;

  constructor(private _store: Store) {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );
    this.assessments2Uri = config.assessments2Uri;
  }

  ngOnInit(): void {
    this.canStart$ = this._store
      .select(SurveyDocumentState.canStart)
      .pipe(switchMap(canStart => canStart));

    this.extensions = this._store.selectSnapshot(
      SurveyDocumentState.getSurveyExtensions
    );
  }

  get homeJumpMenuText(): string {
    return this.extensions.find(
      (extension: Extension) => extension.name === 'homeJumpMenu'
    ).value;
  }

  onExitClick(): void {
    localStorage.setItem("instrumentArray", JSON.stringify([]));
    window.location.href = this.assessments2Uri;
  }

  onJumpMenuClick(sectionId: string): void {
    this._store.dispatch(new JumpTo(sectionId));
  }
}
