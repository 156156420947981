import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Store } from '@ngxs/store';

import { Program, InstrumentStatus } from '../../models/participant.model';
import { User } from '../../../../core/auth/user.model';

import { Config } from '../../../../store/application.models';
import { ApplicationState } from '../../../../store/application.state';

@Component({
  selector: 'assess-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss'],
})
export class ProgramCardComponent {
  public assessmentsUri;
  public assessments2Uri;
  constructor(private _store: Store) {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );

    this.assessmentsUri = config.authRedirectUri;
    this.assessments2Uri = config.assessments2Uri;
  }

  @Input() program: Program;
  @Input() user: User;

  @Output()
  ratersClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  @Output()
  selfClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  @Output()
  viewAssetClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  @Output()
  viewDocumentClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  onRatersClick(instrumentStatus: InstrumentStatus): void {
    switch (instrumentStatus.instrumentId) {
      case 1913: // Skillscope
      case 1892: // Benchmarks for Managers
      case 689: // CDP
        window.location.href = `${this.assessments2Uri}/raters?id=${instrumentStatus.instrumentId}&pid=${instrumentStatus.participantId}&retUrl=${this.assessmentsUri}`;
        break;

      default:
        this.ratersClick.emit(instrumentStatus);
        break;
    }
  }

  onSelfClick(instrumentStatus: InstrumentStatus): void {
    switch (instrumentStatus.instrumentId) {
      case 2947: // Workplace Big 5 - v5.0
      case 2948: // REAL Companion Survey
      case 1913: // Skillscope
      case 1892: // Benchmarks for Managers
      case 1929: // CDP Indivial
      case 689: // CDP
        window.location.href = `${this.assessments2Uri}/survey?id=${instrumentStatus.instrumentId}&pid=${instrumentStatus.participantId}&retUrl=${this.assessmentsUri}&surveyType=0`;
        break;

      default:
        this.selfClick.emit(instrumentStatus);
        break;
    }
  }

  onViewAssetClick(instrumentStatus: InstrumentStatus): void {
    this.viewAssetClick.emit(instrumentStatus);
  }

  onViewDocumentClick(instrumentStatus: InstrumentStatus): void {
    this.viewDocumentClick.emit(instrumentStatus);
  }
}
