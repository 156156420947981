import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { LogEntriesConfigFactory } from './core/log/plugins/log-entries-plugin/log-entries-config.factory';
import { LogEntriesConfig } from './core/log/plugins/log-entries-plugin/log-entries-log.model';
import { AppConfigService } from './app-config.service';
import { Environment, Config } from './store/application.models';

@Injectable({
  providedIn: 'root'
})
export class LogEntriesConfigService extends LogEntriesConfigFactory {
  constructor(private _appConfigService: AppConfigService) {
    super();
  }

  getConfig(): Observable<LogEntriesConfig> {
    return this._appConfigService.loadEnvironment().pipe(
      switchMap((environment: Environment) =>
        this._appConfigService.loadConfig(environment.env)
      ),
      switchMap((config: Config) =>
        of({
          url: 'https://webhook.logentries.com/noformat/logs',
          token: config.logEntriesToken
        })
      )
    );
  }
}
