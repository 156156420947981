import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { NavigationEvent } from '../../store/survey.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyStepperService {
  sectionIndexChanged$: Subject<NavigationEvent> = new Subject<
    NavigationEvent
  >();
  next$: Subject<string> = new Subject<string>();
  previous$: Subject<string> = new Subject<string>();

  changeSectionIndex(event: NavigationEvent): void {
    this.sectionIndexChanged$.next(event);
  }

  next(source: string): void {
    this.next$.next(source);
  }

  previous(source: string): void {
    this.previous$.next(source);
  }
}
