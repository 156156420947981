import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Store, Actions, ofActionSuccessful } from '@ngxs/store';

import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import {
  SearchIndividual,
  SearchIndividualSuccess,
} from '../../store/add-rater.actions';

import {
  Language,
  Individual,
} from '../../../../store/rater-management.models';

import { AddRaterState } from '../../store/add-rater.state';
import { CustomValidators } from '../../../../../../shared/custom-validators';

@Component({
  selector: 'assess-rater-form',
  templateUrl: './rater-form.component.html',
})
export class RaterFormComponent implements OnInit, OnDestroy {
  @Input()
  index: number;
  @Input()
  raterFormGroup: FormGroup;

  @Output()
  removeRaterClick: EventEmitter<number> = new EventEmitter<number>();

  emailControl: FormControl;

  readonly: boolean = false;

  mruRaters: Individual[] = [];
  languages: Language[] = [];

  filteredRaters$: Observable<Individual[]>;

  private _searchIndividualSubscription: Subscription;

  constructor(private _store: Store, private _actions$: Actions) {}

  ngOnInit() {
    // listen for successful searches based on email address
    this._searchIndividualSubscription = this._actions$
      .pipe(ofActionSuccessful(SearchIndividualSuccess))
      .subscribe((searchIndividualSuccess: SearchIndividualSuccess) => {
        if (
          searchIndividualSuccess.source === this.index &&
          searchIndividualSuccess.individual !== null
        ) {
          this.raterFormGroup.patchValue({
            firstName: searchIndividualSuccess.individual.firstName,
            lastName: searchIndividualSuccess.individual.lastName,
          });
          this.email.disable();
          this.firstName.disable();
          this.lastName.disable();

          const language: Language = this.languages.find(
            (language: Language) =>
              language.id === searchIndividualSuccess.individual.language.id
          );
          if (language) {
            this.raterFormGroup.patchValue({
              language: language,
            });
            this.language.disable();
          }
        }
      });

    // get the MRU and languages lists
    this.mruRaters = this._store.selectSnapshot(AddRaterState.getMruRaters);
    this.languages = this._store.selectSnapshot(AddRaterState.getLanguages);

    // setup the email validator
    this.emailControl = new FormControl(null, [CustomValidators.emailWithTrim]);

    // filter the MRU list when email changes
    this.filteredRaters$ = this.emailControl.valueChanges.pipe(
      startWith(''),
      map((val: string) => this.filter(val))
    );

    // build the form
    this.raterFormGroup.addControl('email', this.emailControl);
    this.raterFormGroup.addControl('firstName', new FormControl(null));
    this.raterFormGroup.addControl('lastName', new FormControl(null));
    this.raterFormGroup.addControl('language', new FormControl(null));
  }

  ngOnDestroy(): void {
    this._searchIndividualSubscription.unsubscribe();
  }

  static buildRaterForm(): FormGroup {
    return new FormGroup({});
  }

  get email() {
    return this.raterFormGroup.get('email');
  }

  get firstName() {
    return this.raterFormGroup.get('firstName');
  }

  get lastName() {
    return this.raterFormGroup.get('lastName');
  }

  get language() {
    return this.raterFormGroup.get('language');
  }

  filter(val: string): Individual[] {
    return this.mruRaters.filter((individual: Individual) =>
      individual.email.toLowerCase().startsWith(val)
    );
  }

  onBlur(): void {
    if (
      this.email.valid &&
      this.email.value !== null &&
      this.email.value.trim() !== ''
    ) {
      this._store.dispatch(
        new SearchIndividual(this.email.value.trim(), this.index)
      );
    }
  }

  onRaterSelected(selectedRater: MatAutocompleteSelectedEvent): void {
    const email: string = selectedRater.option.value;
    const individual: Individual = this.mruRaters.find(
      (individual: Individual) => individual.email === email
    );

    this.raterFormGroup.setValue({
      email: individual.email,
      firstName: individual.firstName,
      lastName: individual.lastName,
      language: this.languages.find(
        (language) => language.id === individual.language.id
      ),
    });
    this.email.disable();
    this.firstName.disable();
    this.lastName.disable();
    this.language.disable();
  }

  onRemoveRaterClick(index: number): void {
    this.removeRaterClick.emit(index);
  }
}
