import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[apNoDblClickMat]'
})
export class NoDblClickMatDirective {
  @Input()
  enableAfter: number = 0;

  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    // see if the srcElement has the disabled property. If so then it is the actual button. If not then the user
    // clicked on the button's text (span element)
    let button = event.target !== undefined ? event.target : event.srcElement;

    while (button.disabled === undefined) {
      button = button.parentElement;
    }

    button.setAttribute('disabled', true);
    if (this.enableAfter > 0) {
      setTimeout(function() {
        button.removeAttribute('disabled');
      }, this.enableAfter);
    }
  }
}
