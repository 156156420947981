import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Question,
  SurveyScale,
  QuestionAnswer,
  QuestionListItem
} from '../../store/survey.model';

import { SurveyDocumentState } from '../../store/survey.state';

@Component({
  selector: 'assess-survey-scales-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-scales-question.component.html'
})
export class SurveyScalesQuestionComponent implements OnInit, OnDestroy {
  @Input()
  surveyScale: SurveyScale;
  @Input()
  question: Question;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  questionListItem$: Observable<QuestionListItem>;

  isAnswered: boolean = false;

  private _questionListItemSubscription: Subscription;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.questionListItem$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map(filterFn => filterFn(this.question.formItemId)));

    this._questionListItemSubscription = this.questionListItem$.subscribe(
      (questionListItem: QuestionListItem) => {
        this.isAnswered =
          questionListItem.answer && questionListItem.answer !== null;
      }
    );
  }

  ngOnDestroy(): void {
    this._questionListItemSubscription.unsubscribe();
  }

  onScaleSelected(value: number): void {
    this.isAnswered = value !== null;
    let question: QuestionAnswer = Object.assign(
      {},
      {
        ...this.question,
        previousAnswer: this.question.answer,
        answer: value
      }
    );
    this.questionAnswered.emit(question);
  }
}
