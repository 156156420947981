import { State, Selector, Action, StateContext, NgxsOnInit, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  Language,
  Config,
  Environment,
  Application,
  Exception,
  ApplicationMessage,
} from './application.models';

import { User } from '../core/auth/user.model';

import {
  LoadUser,
  LoadApplication,
  LoadApplicationSuccess,
  LoadConfig,
  LoadConfigSuccess,
  LoadEnvironmentSuccess,
  LoadEnvironment,
  Authenticate,
  AuthenticateSuccess,
  SetLanguage,
  SetLanguageSuccess,
  ThrowException,
  InitializeTranslateService,
  SetLandingTabIndex,
  NavigateHome,
  LogDeviceSettings,
  AboutMeComplete,
  MyProfileSaved,
} from './application.actions';

import { ApplicationService } from '../shared/services/application.service';
import { AppConfigService } from '../app-config.service';
import { AuthenticationService } from '../core/auth/authentication.service';
import { LogService } from '../core/log/log.service';
import { LoadBackground } from '../features/background-landing/store/background-landing.actions';
import { GlobalExceptionStatus } from '../global-exception/global-exception-status.enum';
import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { ParticipantService } from '../features/participant-landing/services/participant.service';
import { ParticipantLanding } from '../features/participant-landing/models/participant.model';
import { RaterLandingService } from '../features/rater-landing/services/rater-landing.service';
import { RaterLanding } from '../features/rater-landing/models/rater-landing.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

export class ApplicationStateModel {
  environment: Environment;
  config: Config;
  user: User;
  landingTabIndex: number;
  languages: Language[];
  languageDirection: string;
  aboutMeComplete: boolean;
  redirectToAp2: boolean;
  instrumentId: number;
  participantId: number;
  surveyTypeId: number;
  retUrl: string;
  applicationMessage: ApplicationMessage;
  exception: Exception;
}

@State<ApplicationStateModel>({
  name: 'application',
  defaults: {
    environment: null,
    config: null,
    user: null,
    landingTabIndex: -1,
    languages: [],
    languageDirection: 'ltr',
    aboutMeComplete: false,
    applicationMessage: {
      haveMessage: false,
      message: null,
    },
    redirectToAp2: true,
    instrumentId: 0,
    participantId: 0,
    surveyTypeId: -1,
    retUrl: "",
    exception: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ApplicationState implements NgxsOnInit, OnDestroy {
  
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false;
  }
  private subscription: Subscription; 

  constructor(
    private _appConfigService: AppConfigService,
    private _authenticationService: AuthenticationService,
    private _applicationService: ApplicationService,
    private _translateService: TranslateService,
    private _logService: LogService,
    private _deviceDetectorService: DeviceDetectorService,
    private _participantService: ParticipantService,
    private _raterLandingService: RaterLandingService,
    private _route: ActivatedRoute,
  ) {}

  // kick the entire thing off with call to load environment
  ngxsOnInit(ctx?: StateContext<any>) {
    ctx.dispatch(new InitializeTranslateService());

    this.subscription = this._route.queryParams.subscribe(params => {
      const instrumentId = params['id']; 
      const participantId = params['pid']; 
      const surveyTypeId = params['type'];
      const returnUrl = params['retUrl'];

      // if the user was sent from AP2, redirect them back to AP2
      let instArray = JSON.parse(localStorage.getItem("instrumentArray")) || [];
      if ((instrumentId > 0 && participantId > 0) || (instArray.length > 0)) {
        if (instArray.length > 0) {
          // reset before redirecting
          localStorage.setItem("instrumentArray",JSON.stringify([]));
          window.location.href = instArray[0].retUrl;
        }

        ctx.patchState({
          instrumentId: instrumentId ?? instArray[0].id,
          participantId: participantId ?? instArray[0].pid,  
          surveyTypeId: surveyTypeId ?? instArray[0].type,
          retUrl: returnUrl ?? instArray[0].retUrl
        });
        if (surveyTypeId == 0) {
          ctx.dispatch(new Navigate([`/participant`]));
        }
        else if (surveyTypeId == 1) {
          ctx.dispatch(new Navigate([`/rater`]));
        }
        else {
          return;
        }
      }
      else {
        const currentUrl = window.location.href;
        if (instArray.length > 0) {
          if (currentUrl.endsWith("participant")) {
            let newUrl = currentUrl.replace('/participant','');
            window.location.href = newUrl.replace('1','');
          }
          else if (currentUrl.endsWith("rater")) {
            let newUrl = currentUrl.replace('/rater','');
            window.location.href = newUrl.replace('1','');
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // selectors
  @Selector()
  static getLanguageDirection(state: ApplicationStateModel): string {
    return state.languageDirection;
  }

  @Selector()
  static getUser(state: ApplicationStateModel): User {
    return state.user;
  }

  @Selector()
  static getException(state: ApplicationStateModel): Exception {
    return state.exception;
  }

  @Selector()
  static getLanguages(state: ApplicationStateModel): Language[] {
    return state.languages;
  }

  @Selector()
  static getApplicationMessage(
    state: ApplicationStateModel
  ): ApplicationMessage {
    return state.applicationMessage;
  }

  @Selector()
  static getLandingTabIndex(state: ApplicationStateModel): number {
    return state.landingTabIndex;
  }

  @Selector()
  static getConfig(state: ApplicationStateModel): Config {
    return state.config;
  }

  @Selector()
  static getUserPlatform(state: ApplicationStateModel): boolean {
    return state.redirectToAp2;
  }

  @Selector()
  static getInstrumentId(state: ApplicationStateModel): number {
    return state.instrumentId;
  }

  @Selector()
  static getParticipantId(state: ApplicationStateModel): number {
    return state.participantId;
  }

  @Selector()
  static getRedirectUrl(state: ApplicationStateModel): string {
    return state.retUrl;
  }

  @Selector()
  static getSurveyType(state: ApplicationStateModel): number {
    return state.surveyTypeId;
  }

  // command handlers
  @Action(InitializeTranslateService)
  initializeTranslateService(ctx: StateContext<ApplicationStateModel>) {
    this._translateService.addLangs(['en-US']);
    this._translateService.setDefaultLang('en-US');
    ctx.dispatch(new LoadEnvironment());
  }

  @Action(LoadEnvironment)
  loadEnvironment(ctx: StateContext<ApplicationStateModel>) {
    this._appConfigService.loadEnvironment().subscribe(
      (environment) => ctx.dispatch(new LoadEnvironmentSuccess(environment)),
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'loadEnvironment',
            }
          )
        )
    );
  }

  @Action(LoadConfig)
  loadConfig(ctx: StateContext<ApplicationStateModel>) {
    const state = ctx.getState();
    this._appConfigService.loadConfig(state.environment.env).subscribe(
      (config) => ctx.dispatch(new LoadConfigSuccess(config)),
      (error) =>
        ctx.dispatch(
          new ThrowException(
            {
              error: error,
              status: error.status,
            },
            {
              action: 'loadConfig',
              data: `Environment ${state.environment.env}`,
            }
          )
        )
    );
  }

  @Action(Authenticate)
  authenticate(ctx: StateContext<ApplicationStateModel>) {
    const state = ctx.getState();
    this._authenticationService
      .authenticate(
        state.config.authClientId,
        state.config.authDomain,
        state.config.authRedirectUri,
        state.config.grandCentralLoginApplicationId,
        state.config.grandCentralUrl,
        state.config.grandCentralReturnUrl
      )
      .then((user: User) => {
        if (user.individualId === undefined) {
          ctx.dispatch(
            new ThrowException(
              {
                error: { statusText: 'Unknown user', user: user },
                status: GlobalExceptionStatus.UnknownUser,
              },
              {
                action: 'authenticate',
              }
            )
          );
        } else {
          ctx.dispatch(new AuthenticateSuccess(user));
        }
      })
      .catch(() => {
        console.log('Not authenticated. Navigating to Grand Central');
      });
  }

  @Action(LoadUser)
  loadUser(ctx: StateContext<ApplicationStateModel>, action: LoadUser) {
    ctx.patchState({
      user: action.user,
    });
    ctx.dispatch(new LoadApplication());
  }

  @Action(LoadApplication)
  loadApplication(ctx: StateContext<ApplicationStateModel>) {
    const state = ctx.getState();
    return this._applicationService
      .getApplication(state.user.preferredLanguageId)
      .subscribe(
        (application: Application) =>
          ctx.dispatch(new LoadApplicationSuccess(application)),
        (error) => {
          ctx.dispatch(
            new ThrowException(
              {
                error: error,
                status: error.status,
              },
              {
                action: 'loadApplication',
              }
            )
          );
        }
      );
  }

  @Action(SetLanguage)
  setLanguage(ctx: StateContext<ApplicationStateModel>) {
    const state = ctx.getState();
    this._translateService.use(state.user.preferredLanguageCultureName);

    const userLanguage: Language = state.languages.find(
      (language: Language) =>
        language.cultureName === state.user.preferredLanguageCultureName
    );
    ctx.patchState({
      languageDirection: userLanguage?.direction ?? "ltr",
    });
    ctx.dispatch(new LogDeviceSettings());
    ctx.dispatch(new SetLanguageSuccess());
  }

  @Action(LogDeviceSettings)
  logDeviceSettings(ctx: StateContext<ApplicationStateModel>) {
    const state: ApplicationStateModel = ctx.getState();

    this._logService.info({
      application: 'assessments-web-app',
      env: state.environment.env,
      system: 'assessments',
      user: state.user,
      message: 'Device Settings',
      deviceInfo: this._deviceDetectorService.getDeviceInfo(),
    });
  }

  @Action(SetLandingTabIndex)
  setLandingTabIndex(
    ctx: StateContext<ApplicationStateModel>,
    action: SetLandingTabIndex
  ) {
    ctx.patchState({
      landingTabIndex: action.landingPageIndex,
    });
  }

  @Action(AboutMeComplete)
  aboutMeComplete(
    ctx: StateContext<ApplicationStateModel>,
    action: AboutMeComplete
  ) {
    ctx.patchState({
      aboutMeComplete: action.complete,
    });
  }

  @Action(MyProfileSaved)
  myProfileSaved(
    ctx: StateContext<ApplicationStateModel>,
    action: MyProfileSaved
  ) {
    const state: ApplicationStateModel = ctx.getState();
    const userLanguage: Language = state.languages.find(
      (language: Language) =>
        language.cultureName ===
        action.profile.individual.preferredApplicationLanguageCultureName
    );
    const user: User = {
      ...state.user,
      firstName: action.profile.individual.firstName,
      lastName: action.profile.individual.lastName,
      preferredLanguageId:
        action.profile.individual.preferredApplicationLanguageId,
      preferredLanguageCultureName: userLanguage.cultureName,
    };

    this._translateService.use(userLanguage.cultureName);

    ctx.patchState({
      user: user,
      languageDirection: userLanguage?.direction ?? "ltr",
    });
    ctx.dispatch(new SetLanguageSuccess());
  }

  @Action(ThrowException)
  throwException(
    ctx: StateContext<ApplicationStateModel>,
    action: ThrowException
  ) {
    const state: ApplicationStateModel = ctx.getState();

    this._logService.error({
      application: 'assessments-web-app',
      env: state.environment.env,
      system: 'assessments',
      user: state.user,
      message: action.exception.error.message
        ? action.exception.error.message
        : action.exception.error,
      stack: action.exception.error.stack,
      correlationId: !!action.exception.error.error
        ? action.exception.error.error.correlationId
        : null,
      context: action.context,
      deviceInfo: this._deviceDetectorService.getDeviceInfo(),
    });

    ctx.patchState({
      exception: action.exception,
    });
    ctx.dispatch(new Navigate(['/exception']));
  }

  // event handlers
  @Action(LoadEnvironmentSuccess)
  loadEnvironmentSuccess(
    ctx: StateContext<ApplicationStateModel>,
    event: LoadEnvironmentSuccess
  ) {
    ctx.patchState({
      environment: event.env,
    });
    ctx.dispatch(new LoadConfig());
  }

  @Action(LoadConfigSuccess)
  loadConfigSuccess(
    ctx: StateContext<ApplicationStateModel>,
    event: LoadConfigSuccess
  ) {
    console.log('event.config', event.config);
    ctx.patchState({
      config: event.config,
    });
    ctx.dispatch(new Authenticate());
  }

  @Action(AuthenticateSuccess)
  authenticateSuccess(
    ctx: StateContext<ApplicationStateModel>,
    event: AuthenticateSuccess
  ) {
    ctx.patchState({
      user: event.user,
    });
    ctx.dispatch(new LoadApplication());
  }

  @Action(LoadApplicationSuccess)
  loadApplicationSuccess(
    ctx: StateContext<ApplicationStateModel>,
    event: LoadApplicationSuccess
  ) {
    ctx.patchState({
      languages: [...event.application.languages],
      applicationMessage: event.application.applicationMessage,
    });

    let languages: string[] = [];
    event.application.languages.forEach((language: Language) => {
      languages.push(language.cultureName);
    });

    this._translateService.addLangs(languages);
    this._translateService.setDefaultLang('en-US');

    ctx.dispatch(new SetLanguage());
  }

  @Action(SetLanguageSuccess)
  setLanguageSuccess(ctx: StateContext<ApplicationStateModel>) {
    const state: ApplicationStateModel = ctx.getState();
    let redirectUser = false;
    let hasOnlyAboutMe = false;
    let instruments = [];
    let raterInstruments = [];
    let existingInstruments = [];
    let hasDocuments = false;
    
    if (state.user.isParticipant) {
      this._participantService.getParticipantLanding(
        state.user.individualId,
        state.user.preferredLanguageId
      ).subscribe((res: ParticipantLanding) => 
      {
        res.programInstrumentStatuses.forEach(x => {
          x.instrumentStatuses.forEach(y => {
            if (y.isAssessment) {
              existingInstruments.push(y.instrumentId);
            }
            else {
              hasDocuments = true;
            }
          });

          // if user only has documents
          if (existingInstruments.length == 0 && hasDocuments == true) {
            redirectUser = false;
          }
          // if the user only has About Me, do not route them
          else if (existingInstruments.length == 1 && existingInstruments.includes(2946)) {
            hasOnlyAboutMe = true;
            if (!state.user.isRater) {
              redirectUser = false;  
            }
            else {
              redirectUser = true;  
            }
          }
          else {
            redirectUser = existingInstruments.every(elem => instruments.includes(elem));
          }
        });
        if (redirectUser) {
          if (!state.user.isRater) {
            // route to AP2 if only those surveys are assigned
            window.location.href = `${state.config.assessments2Uri}`;
          }
          else {
            let redirectRater = false;
            existingInstruments = [];
            this._raterLandingService.getRaterLanding(
              state.user.individualId,
              state.user.preferredLanguageId
            ).subscribe((res: RaterLanding) => {
              res.surveyStatuses.forEach(x => {
                if (x.instrument) {
                  existingInstruments.push(x.instrument.instrumentId);
                }
                redirectRater = existingInstruments.every(elem => raterInstruments.includes(elem));
              });
      
              if (redirectRater && !hasOnlyAboutMe) {
                // route to AP2 if only those surveys are assigned
                window.location.href = `${state.config.assessments2Uri}`;
              }
              else {
                ctx.patchState({
                  redirectToAp2: false
                });
                if (state.user.aboutMe) {
                  ctx.dispatch(
                    new LoadBackground(
                      state.user.individualId,
                      state.user.preferredLanguageId
                    )
                  );
                } else {
                  ctx.dispatch(new NavigateHome());
                }
              }
            });
          }
        }
        else {
          ctx.patchState({
            redirectToAp2: false
          });
          if (state.user.aboutMe) {
            ctx.dispatch(
              new LoadBackground(
                state.user.individualId,
                state.user.preferredLanguageId
              )
            );
          } else {
            ctx.dispatch(new NavigateHome());
          }
        }
      })
    }
    
    if (state.user.isRater) {
      let redirectRater = false;
      existingInstruments = [];
      this._raterLandingService.getRaterLanding(
        state.user.individualId,
        state.user.preferredLanguageId
      ).subscribe((res: RaterLanding) => {
        res.surveyStatuses.forEach(x => {
          if (x.instrument) {
            existingInstruments.push(x.instrument.instrumentId);
          }
          redirectRater = existingInstruments.every(elem => raterInstruments.includes(elem));
        });

        if (redirectRater) {
          if ((state.user.isParticipant && redirectUser) || !state.user.isParticipant) {
            // route to AP2 if only those surveys are assigned
            window.location.href = `${state.config.assessments2Uri}`;
          }
        }
        else {
          ctx.patchState({
            redirectToAp2: false
          });
          if (state.user.aboutMe) {
            ctx.dispatch(
              new LoadBackground(
                state.user.individualId,
                state.user.preferredLanguageId
              )
            );
          } else {
            ctx.dispatch(new NavigateHome());
          }
        }
      })
    }
    else {
      if (!state.user.isParticipant && !state.user.isRater) {
        ctx.patchState({
          redirectToAp2: false
        });
        if (state.user.aboutMe) {
          ctx.dispatch(
            new LoadBackground(
              state.user.individualId,
              state.user.preferredLanguageId
            )
          );
        } else {
          ctx.dispatch(new NavigateHome());
        }
      }
    }
  }

  @Action(NavigateHome)
  navigateHome(ctx: StateContext<ApplicationStateModel>, action: NavigateHome) {
    const state = ctx.getState();

    const participantTab: number = state.user.aboutMe ? 1 : 0;
    const raterTab: number = state.user.aboutMe ? 2 : 1;

    // if first time visit
    if (state.landingTabIndex === -1) {
      // there's an about me and it isn't completed
      if (state.user.aboutMe && !state.aboutMeComplete) {
        ctx.patchState({
          landingTabIndex: 0,
        });
        ctx.dispatch(new Navigate(['/tabbed']));
      } else if (
        state.user.aboutMe ||
        (state.user.isParticipant && state.user.isRater)
      ) {
        // no about me or a completed about me
        ctx.patchState({
          landingTabIndex:
            state.user.landingPage === '/participant'
              ? participantTab
              : raterTab,
        });
        ctx.dispatch(new Navigate(['/tabbed']));
      } else {
        // participant or rater with no about me
        // go directly to the proper landing page
        ctx.dispatch(new Navigate([state.user.landingPage]));
      }
    } else {
      // not the first time and we're on the first tab
      if (state.landingTabIndex === 0) {
        // could be about me or participant page
        // if it is about me and it is completed
        // then go to the participant or the rater
        if (action.skip || (state.user.aboutMe && state.aboutMeComplete)) {
          ctx.patchState({
            landingTabIndex:
              state.user.landingPage === '/participant'
                ? participantTab
                : raterTab,
          });
        }
        ctx.dispatch(new Navigate(['/tabbed']));
      } else if (
        state.user.aboutMe ||
        (state.user.isParticipant && state.user.isRater)
      ) {
        // not on the first tab
        // this means it is either the participant (no about me) or rater
        // so just go to the currently stored tab index
        if (!state.user.aboutMe) {
          ctx.patchState({
            landingTabIndex: state.user.isParticipant ? 0 : 1,
          });  
        }
        ctx.dispatch(new Navigate(['/tabbed']));
      } else {
        // participant or rater with no about me
        // go directly to the proper landing page
        ctx.dispatch(new Navigate([state.user.landingPage]));
      }
    }
  }
}
