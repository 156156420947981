import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HypermediaService } from '../../../../../core/hypermedia/hypermedia.service';
import { HypermediaFactoryService } from '../../../../../core/hypermedia/hypermedia-factory.service';

import { Config } from '../../../../../store/application.models';
import { Store } from '@ngxs/store';
import {
  Language,
  Rater,
  AddRaterRequest,
  Individual
} from '../../../store/rater-management.models';

interface LanguagesResponse {
  languages: Language[];
}

interface MruRatersResponse {
  individuals: Individual[];
}

interface AddRaterResponse {
  status: number;
  email: string;
}

interface SearchResponse {
  individual: Individual;
}

@Injectable({
  providedIn: 'root'
})
export class RatersService {
  private _hypermediaService: HypermediaService = null;

  public constructor(
    private _store: Store,
    private _hypermediaFactoryService: HypermediaFactoryService
  ) {}

  initializeService(): void {
    if (this._hypermediaService === null) {
      const config: Config = this._store.selectSnapshot(
        state => state.application.config
      );

      this._hypermediaService = this._hypermediaFactoryService.create(
        config.assessmentWebApi,
        'add-raters'
      );
    }
  }

  getLanguages(
    instrumentId: number,
    preferredLanguageId: number
  ): Observable<Language[]> {
    this.initializeService();
    return this._hypermediaService
      .get('getLanguages', {
        instrumentId: instrumentId,
        preferredLanguageId: preferredLanguageId
      })
      .pipe(map((r: LanguagesResponse) => r.languages));
  }

  getMruRaters(
    participantId: number,
    preferredLanguageId: number
  ): Observable<Individual[]> {
    this.initializeService();
    return this._hypermediaService
      .get('getMruRaters', {
        participantId: participantId,
        preferredLanguageId: preferredLanguageId
      })
      .pipe(map((r: MruRatersResponse) => r.individuals));
  }

  saveRaters(
    addRaterRequests: AddRaterRequest[]
  ): Observable<AddRaterResponse> {
    this.initializeService();
    return this._hypermediaService
      .post('post', { addRaterRequests: addRaterRequests })
      .pipe(map((r: AddRaterResponse) => Object.assign({}, r)));
  }

  search(email: string): Observable<Individual> {
    this.initializeService();
    return this._hypermediaService
      .post('search', { email: email })
      .pipe(map((r: SearchResponse) => r.individual));
  }
}
