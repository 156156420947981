import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export class CustomValidators {
  static emailWithTrim(c: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(c.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    const value = c.value.trim();
    return EMAIL_REGEXP.test(value) ? null : { email: true };
  }
}
