import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import {
  Option,
  QuestionAnswer,
  QuestionListItem,
} from '../../../../../survey/store/survey.model';

import { SurveyDocumentState } from '../../../../../survey/store/survey.state';

@Component({
  selector: 'assess-multi-option',
  templateUrl: './multi-option.component.html',
})
export class MultiOptionComponent implements OnInit, OnDestroy {
  @Input()
  option: Option;

  @Output()
  questionAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  @Output()
  otherAnswered: EventEmitter<QuestionAnswer> = new EventEmitter<
    QuestionAnswer
  >();

  otherFormGroup: FormGroup;
  showOther: boolean = false;

  questionListItemCheckbox$: Observable<QuestionListItem>;
  questionListItemOther$: Observable<QuestionListItem>;

  private _lastCheckedValue: string;
  private _lastOtherValue: string;

  private _questionListItemCheckboxSubscription: Subscription;
  private _questionListItemOtherSubscription: Subscription;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.questionListItemCheckbox$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(map((filterFn) => filterFn(this.option.formItemId)));

    this.questionListItemOther$ = this._store
      .select(SurveyDocumentState.getQuestionAnswer)
      .pipe(
        map((filterFn) =>
          filterFn(
            this.option.dependentOption
              ? this.option.dependentOption.formItemId
              : 0
          )
        )
      );

    this.otherFormGroup = new FormGroup({});
    this.otherFormGroup.addControl('option', new FormControl(null));
    this.otherFormGroup.addControl(
      'other',
      new FormControl(null, { updateOn: 'blur' })
    );

    this._questionListItemCheckboxSubscription = this.questionListItemCheckbox$.subscribe(
      (questionListItem: QuestionListItem) => {
        const checked =
          questionListItem.answer && questionListItem.answer === '1'
            ? true
            : false;
        this.optionCheckbox.setValue(checked, { emitEvent: false });

        this.showOther = checked;
      }
    );

    this._questionListItemOtherSubscription = this.questionListItemOther$.subscribe(
      (questionListItem: QuestionListItem) => {
        const other = this.option.dependentOption
          ? questionListItem.answer
          : null;

        this.otherTextbox.setValue(other, { emitEvent: false });
      }
    );

    this.otherTextbox.valueChanges
      .pipe(
        map((value: any) => {
          const questionAnswer: QuestionAnswer = {
            formItemId: this.option.dependentOption.formItemId,
            optional: this.option.dependentOption.optional,
            formItemSequenceNumber: this.option.dependentOption
              .formItemSequenceNumber,
            answeredTimeMs: new Date().getTime(),
            answer: value && value.length > 0 ? value : null,
            previousAnswer: this._lastOtherValue,
          };
          this._lastOtherValue = value;

          this.otherAnswered.emit(questionAnswer);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._questionListItemCheckboxSubscription.unsubscribe();
    this._questionListItemOtherSubscription.unsubscribe();
  }

  onOptionChanged(event: MatCheckboxChange): void {
    this.showOther = event.checked;

    const questionAnswer: QuestionAnswer = {
      formItemId: this.option.formItemId,
      optional: true,
      formItemSequenceNumber: this.option.formItemSequenceNumber,
      answeredTimeMs: new Date().getTime(),
      answer: event.checked ? '1' : '0',
      previousAnswer: this._lastCheckedValue,
    };
    this.questionAnswered.emit(questionAnswer);
    this._lastCheckedValue = event.checked ? '1' : '0';

    if (!event.checked && this.option.dependentOption) {
      this.otherTextbox.setValue(null);
    }
  }

  get optionCheckbox() {
    return this.otherFormGroup.get('option');
  }

  get otherTextbox() {
    return this.otherFormGroup.get('other');
  }
}
