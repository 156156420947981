<div style="padding: 10px;">
  <mat-card [ngClass]="{ 'border-warn': !completed, 'muted': sectionReviewCard.optional }">
    <mat-card-content>
      <div fxLayout fxLayoutAlign="space-between center">
        <div>
          <div class="text-bold" tabindex="0">{{ sectionReviewCard.heading }}
            <span *ngIf="sectionReviewCard.optional" class="text-muted" tabindex="0">{{getExtensionValue('optional')}}</span>
          </div>
          <div>
            <span *ngIf="completed else notComplete" class="text-muted" tabindex="0">{{getExtensionValue('completed')}}</span>
            <ng-template #notComplete>
              <div *ngIf="itemsToComplete > 0">
                <span class="text-warn text-link" tabindex="0" (click)="onSectionReviewCardClicked()" (keypress)="onSectionReviewCardClicked()" >{{ itemsToComplete }}
                  <span *ngIf="itemsToComplete === 1 else plural" tabindex="0">{{getExtensionValue('itemToComplete')}}</span></span>
                <ng-template #plural>{{getExtensionValue('itemsToComplete')}}</ng-template>
              </div>
            </ng-template>
          </div>
          <div *ngIf="!sectionReviewCard.optional">
            <div *ngIf="completed && sectionReviewCard.unansweredQuestions.length > 0" class="text-muted">
              {{getExtensionValue('optionalNotCompleteWarning')}}
            </div>
            <div>
              <span *ngFor="let question of sectionReviewCard.unansweredQuestions; last as last;"
                class="text-warn text-link" tabindex="0" (click)="onQuestionNumberClicked(question)" (keypress)="onQuestionNumberClicked(question)">{{question.number}}<span
                  *ngIf="!last">,
                </span></span>
            </div>
          </div>
          <div *ngIf="notObserved" class="text-warn text-link" (click)="onSectionReviewCardClicked()" (keypress)="onSectionReviewCardClicked()">
            <div>{{getExtensionValue('notObservedExceeded')}}</div>
            <div>
              <span *ngFor="let question of sectionReviewCard.notObservedQuestions; last as last"
                class="text-warn text-link" (click)="onQuestionNumberClicked(question)" (keypress)="onQuestionNumberClicked(question)">{{question.number}}<span
                  *ngIf="!last">, </span></span>
            </div>
          </div>
        </div>
        <div *ngIf="completed">
          <span fxLayout fxLayoutAlign="start center" class="text-success">
            <i class="fa fa-check-circle-o fa-2x"></i>
          </span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>