<div style="margin-top: 5px;">
  <button mat-icon-button [matMenuTriggerFor]="surveyMenu" aria-label="Survey Menu Type">
    <i class="fa fa-bars fa-2x" aria-hidden="true"></i>
  </button>
  <mat-menu #surveyMenu="matMenu">
    <button mat-menu-item (click)="onExitClick()">
      <i class="fa fa-home fa-fw" aria-hidden="true"></i><span class="menu icon-item">{{homeJumpMenuText}}</span>
    </button>
    <mat-divider></mat-divider>
    <div *ngIf="(canStart$ | async) as canStart">
      <button mat-menu-item *ngFor="let jumpMenuItem of jumpMenu$ | async" (click)="onJumpMenuClick(jumpMenuItem.sectionId)"
        [disabled]="!canStart.enabled">
        {{jumpMenuItem.jumpMenu}}
      </button>
    </div>
  </mat-menu>
</div>