import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Select, Store } from '@ngxs/store';

import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SurveyDocumentState } from '../../store/survey.state';
import {
  SurveySection,
  QuestionAnswer,
  Language,
  Extension,
} from '../../store/survey.model';
import { AnswerQuestions, ChangeLanguage } from '../../store/survey.actions';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'assess-survey-welcome',
  templateUrl: './survey-welcome.component.html',
})
export class SurveyWelcomeComponent implements OnInit, OnDestroy {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  @Select(SurveyDocumentState.getAvailableLanguages)
  availableLanguages$: Observable<Language[]>;

  @Select(SurveyDocumentState.canStart)
  canStart$: Observable<boolean>;

  canStart: boolean;

  selectedLanguage: number = null;

  extensions: Extension[];

  loading: boolean = false;

  private _canStartSubscription: Subscription;

  constructor(
    private _dialog: MatDialog,
    private _store: Store,
    private _changeDectectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._canStartSubscription = this._store
      .select(SurveyDocumentState.canStart)
      .pipe(switchMap((canStart) => canStart))
      .subscribe((canStart: any) => {
        if (this.canStart !== canStart.enabled) {
          this.loading = false;
        }
        this.canStart = canStart.enabled;
      });

    this.selectedLanguage = this._store.selectSnapshot(
      SurveyDocumentState.getCurrentLanguage
    ).languageId;

    this.extensions = this._store.selectSnapshot(
      SurveyDocumentState.getSurveySectionExtensions
    );
  }

  ngOnDestroy(): void {
    this._canStartSubscription.unsubscribe();
  }

  onLanguageChange(selection: MatSelectChange): void {
    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this.getExtensionValue('confirmationTitle'),
        question: this.getExtensionValue('confirmationBody'),
        yesButton: this.getExtensionValue('yesButton'),
        noButton: this.getExtensionValue('noButton'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this._changeDectectorRef.detectChanges();
        this._store.dispatch(new ChangeLanguage(selection.value));
      } else {
        this.selectedLanguage = this._store.selectSnapshot(
          SurveyDocumentState.getCurrentLanguage
        ).languageId;
        this._changeDectectorRef.detectChanges();
      }
    });
  }

  onWelcomeQuestionChange(answer: QuestionAnswer): void {
    this.loading = true;

    const questionAnswer: QuestionAnswer = {
      formItemId: answer.formItemId,
      previousAnswer: answer.previousAnswer,
      answer: answer.answer,
      optional: answer.optional,
      formItemSequenceNumber: answer.formItemSequenceNumber,
      answeredTimeMs: new Date().getTime(),
    };
    this._store.dispatch(new AnswerQuestions([questionAnswer]));
  }

  getExtensionValue(extensionName): string {
    const extension: Extension = this.extensions.find(
      (extension: Extension) => extension.name === extensionName
    );

    return extension ? extension.value : null;
  }
}
