<div fxLayout="column" fxLayoutGap="5px" style="padding: 10px;">
  <div *ngIf="question.text && question.text.length > 0; else noQuestion" fxLayout="column"
    fxLayoutAlign="start stretch" fxLayoutGap="5px" [id]="question.formItemId">
    <div fxLayout fxLayoutAlign="start start" [ngClass]="{'not-answered': !isAnswered}">
      <div fxFlex="0 0 20px">
        <i class="fa fa-star text-success" *ngIf="isAnswered"></i>
      </div>
      <p style="margin: 0;" fxLayout fxLayoutGap="5px">
        <span>{{question.number}}.</span>
        <span [innerHtml]="question.text"></span>
      </p>
    </div>
    <div style="padding-left: 40px;">
      <div *ngFor="let option of question.options; let i = index;">
        <div fxLayout fxHide="true" fxShow.gt-xs="true">
          <mat-select fxFlex="0 0 150px" [placeholder]="question.placeholder"
            (selectionChange)="onSelectionChanged($event, i)" [ngModel]="getAnswer(i)">
            <mat-option *ngFor="let answerGroupItem of answerGroup" [value]="answerGroupItem.value">
              {{ answerGroupItem.text }}
            </mat-option>
          </mat-select>
          <span style="margin-left: 10px;">{{option.number}}. {{option.text}}</span>
        </div>
        <div fxLayout="column" fxHide="true" fxShow.xs="true">
          <span>{{option.number}}. {{option.text}}</span>
          <mat-select [placeholder]="question.placeholder" (selectionChange)="onSelectionChanged($event, i)"
            [ngModel]="getAnswer(i)">
            <mat-option *ngFor="let answerGroupItem of answerGroup" [value]="answerGroupItem.value">
              {{ answerGroupItem.text }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noQuestion>
  <div fxLayout fxLayoutAlign="start start" [id]="question.formItemId">
    <div fxFlex="0 0 20px">
      <i class="fa fa-star text-success" *ngIf="isAnswered"></i>
    </div>
    <div fxLayout fxLayoutGap="5px">
      <div>{{question.number}}.</div>
    </div>
    <div style="padding-left: 10px;">
      <div *ngFor="let option of question.options; let i = index;">
        <div fxLayout fxHide="true" fxShow.gt-xs="true">
          <mat-select fxFlex="0 0 150px" [placeholder]="question.placeholder"
            (selectionChange)="onSelectionChanged($event, i)" [ngModel]="getAnswer(i)">
            <mat-option *ngFor="let answerGroupItem of answerGroup" [value]="answerGroupItem.value">
              {{ answerGroupItem.text }}
            </mat-option>
          </mat-select>
          <span style="margin-left: 10px;">{{option.number}}. {{option.text}}</span>
        </div>
        <div fxLayout="column" fxHide="true" fxShow.xs="true">
          <span>{{option.number}}. {{option.text}}</span>
          <mat-select [placeholder]="question.placeholder" (selectionChange)="onSelectionChanged($event, i)"
            [ngModel]="getAnswer(i)">
            <mat-option *ngFor="let answerGroupItem of answerGroup" [value]="answerGroupItem.value">
              {{ answerGroupItem.text }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>