import { Directive, OnDestroy, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[apBackground]'
})
export class BackgroundDirective implements OnDestroy, AfterViewInit {
  ngAfterViewInit() {
    document.querySelector('body').classList.add('white');
  }
  ngOnDestroy(): void {
    document.querySelector('body').classList.remove('white');
  }
}
