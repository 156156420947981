<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div style="padding: 3px 0;">
    <mat-accordion multi="true">
      <mat-expansion-panel *ngFor="let question of data.questions; let i = index" [id]="'faq' + i"
        [expanded]="expandForScrollTo('faq' + i)">
        <mat-expansion-panel-header [ngStyle.xs]="{'padding-top.px': 5, 'padding-bottom.px': 5}">
          <mat-panel-title>
            <div [innerHtml]="question.question"></div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHtml]="question.answer"></div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div mat-dialog-actions fxLayout fxLayoutAlign="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ data.okButton }}</button>
</div>