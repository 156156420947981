import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SurveySection } from '../../store/survey.model';
import { ExitSurvey } from '../../store/survey.actions';
import { SurveyDocumentState } from '../../store/survey.state';

@Component({
  selector: 'assess-survey-section-introduction',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-section-introduction.component.html',
})
export class SurveySectionIntroductionComponent {
  @Select(SurveyDocumentState.getCurrentSurveySection)
  surveySection$: Observable<SurveySection>;

  constructor(private _store: Store) {}

  onExitClick(): void {
    this._store.dispatch(new ExitSurvey());
  }
}
