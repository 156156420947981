import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'assess-faq-dialog',
  templateUrl: './faq-dialog.component.html',
})
export class FaqDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<FaqDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _scrollToService: ScrollToService
  ) {}

  ngAfterViewInit(): void {
    if (this.data.scrollTo) {
      setTimeout(() => {
        const config: ScrollToConfigOptions = {
          target: this.data.scrollTo,
        };
        this._scrollToService.scrollTo(config);
      }, 250);
    }
  }

  expandForScrollTo(id: string): boolean {
    return id === this.data.scrollTo;
  }
}
