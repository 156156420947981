import { ErrorHandler, Injector, Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { ThrowException } from '../store/application.actions';
import { Exception } from '../store/application.models';

@Injectable()
export class GlobalExceptionHandler implements ErrorHandler {
  constructor(private _injector: Injector) {}

  handleError(error: any): void {
    const store: Store = this._injector.get(Store);

    let exception: Exception = {
      error: error,
      status: error.status
    };

    store.dispatch(
      new ThrowException(exception, {
        action: 'globalExceptionHandler.handleError'
      })
    );
  }
}
