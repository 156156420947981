import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import polly from 'polly-js';
import { Info } from 'polly-js';

import { HypermediaLink } from './hypermedia-link';
import { HypermediaResponse } from './hypermedia-response';
import { flatMap, find, map } from 'rxjs/operators';

export class HypermediaService {
  public apiUrl: string;
  public apiIndex: string;

  public constructor(private _httpClient: HttpClient) {}

  public get(rel: string, parameters?: any, options?: any): Observable<any> {
    // remove trailing slash
    this.apiUrl = this.apiUrl.replace(/\/$/, '');

    return from(
      polly()
        .waitAndRetry(2)
        .executeForPromise((info: Info) => {
          return this._httpClient
            .options(`${this.apiUrl}/${this.apiIndex}`)
            .pipe(
              flatMap((r: HypermediaResponse) => r._links),
              find((link: HypermediaLink) => link.rel === rel),
              flatMap((link: HypermediaLink) => {
                let href: string = link.href;
                if (parameters) {
                  Object.keys(parameters).forEach((key: string) => {
                    href = href.replace(`{${key}}`, parameters[key]);
                  });
                }
                return this._httpClient.get(`${this.apiUrl}/${href}`, options);
              }),
              map((r: any) => r)
            )
            .toPromise();
        })
    );
  }

  public post(rel: string, body: any, options?: any): Observable<any> {
    // remove trailing slash
    this.apiUrl = this.apiUrl.replace(/\/$/, '');

    return from(
      polly()
        .waitAndRetry(2)
        .executeForPromise((info: Info) => {
          return this._httpClient
            .options(`${this.apiUrl}/${this.apiIndex}`)
            .pipe(
              flatMap((r: HypermediaResponse) => r._links),
              find((link: HypermediaLink) => link.rel === rel),
              flatMap((link: HypermediaLink) => {
                let href: string = link.href;
                return this._httpClient.post(
                  `${this.apiUrl}/${href}`,
                  body,
                  options
                );
              }),
              map((r: any) => r)
            )
            .toPromise();
        })
    );
  }

  public put(rel: string, body: any, options?: any): Observable<any> {
    // remove trailing slash
    this.apiUrl = this.apiUrl.replace(/\/$/, '');

    return from(
      polly()
        .waitAndRetry(2)
        .executeForPromise((info: Info) => {
          return this._httpClient
            .options(`${this.apiUrl}/${this.apiIndex}`)
            .pipe(
              flatMap((r: HypermediaResponse) => r._links),
              find((link: HypermediaLink) => link.rel === rel),
              flatMap((link: HypermediaLink) => {
                let href: string = link.href;
                return this._httpClient.put(
                  `${this.apiUrl}/${href}`,
                  body,
                  options
                );
              }),
              map((r: any) => r)
            )
            .toPromise();
        })
    );
  }

  public delete(rel: string, id: string, options?: any): Observable<any> {
    // remove trailing slash
    this.apiUrl = this.apiUrl.replace(/\/$/, '');

    return from(
      polly()
        .waitAndRetry(2)
        .executeForPromise((info: Info) => {
          return this._httpClient
            .options(`${this.apiUrl}/${this.apiIndex}`)
            .pipe(
              flatMap((r: HypermediaResponse) => r._links),
              find((link: HypermediaLink) => link.rel === rel),
              flatMap((link: HypermediaLink) => {
                let href: string = `${link.href}/${id}`;

                return this._httpClient.delete(
                  `${this.apiUrl}/${href}`,
                  options
                );
              }),
              map((r: any) => r)
            )
            .toPromise();
        })
    );
  }
}
