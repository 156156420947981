import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { HypermediaService } from '../../../core/hypermedia/hypermedia.service';
import { HypermediaFactoryService } from '../../../core/hypermedia/hypermedia-factory.service';

import { Config } from '../../../store/application.models';
import {
  QuestionAnswer,
  StartSurveyResponse,
  SurveyAnswer
} from '../store/survey.model';

interface AnswerQuestionResponse {
  answers: QuestionAnswer[];
}

interface SubmitSurveyResponse {
  status: string;
}

interface RefreshAnswersResponse {
  answers: SurveyAnswer[];
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private _hypermediaService: HypermediaService = null;

  public constructor(
    private _store: Store,
    private _hypermediaFactoryService: HypermediaFactoryService
  ) {}

  initializeService(): void {
    if (this._hypermediaService === null) {
      const config: Config = this._store.selectSnapshot(
        state => state.application.config
      );

      this._hypermediaService = this._hypermediaFactoryService.create(
        config.assessmentWebApi,
        'surveys'
      );
    }
  }

  startSurvey(
    instrumentId: number,
    surveyTypeId: number,
    languageId: number,
    participantId: number,
    individualId: number
  ): Observable<StartSurveyResponse> {
    this.initializeService();
    return this._hypermediaService
      .put('start', {
        instrumentId: instrumentId,
        surveyTypeId: surveyTypeId,
        languageId: languageId,
        participantId: participantId,
        individualId: individualId
      })
      .pipe(map((r: StartSurveyResponse) => r));
  }

  answerQuestion(
    surveyId: string,
    answers: QuestionAnswer[]
  ): Observable<QuestionAnswer[]> {
    this.initializeService();
    return this._hypermediaService
      .put('answer', {
        surveyId: surveyId,
        answers: answers
      })
      .pipe(map((r: AnswerQuestionResponse) => r.answers));
  }

  refreshAnswers(surveyId: string): Observable<SurveyAnswer[]> {
    this.initializeService();
    return this._hypermediaService
      .get('answers', {
        surveyId: surveyId
      })
      .pipe(map((r: RefreshAnswersResponse) => r.answers));
  }

  submitSurvey(surveyId: string): Observable<string> {
    this.initializeService();
    return this._hypermediaService
      .put('submit', {
        surveyId: surveyId
      })
      .pipe(map((r: SubmitSurveyResponse) => r.status));
  }

  changeLanguage(
    participantId: number,
    individualId: number,
    instrumentId: number,
    surveyTypeId: number,
    currentLanguageId: number,
    newLanguageId: number
  ): Observable<StartSurveyResponse> {
    this.initializeService();
    return this._hypermediaService
      .put('change', {
        participantId: participantId,
        individualId: individualId,
        instrumentId: instrumentId,
        surveyTypeId: surveyTypeId,
        currentLanguageId: currentLanguageId,
        newLanguageId: newLanguageId
      })
      .pipe(map((r: StartSurveyResponse) => r));
  }
}
