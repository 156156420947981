import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Store } from '@ngxs/store';

import {
  InstrumentStatus,
  AboutMeStatus,
} from '../../models/participant.model';
import { User } from '../../../../core/auth/user.model';

import { Config } from '../../../../store/application.models';
import { ApplicationState } from '../../../../store/application.state';

@Component({
  selector: 'assess-aboutme-status',
  templateUrl: './aboutme-status.component.html',
})
export class AboutmeStatusComponent {
  public assessmentsUri;
  public assessments2Uri;
  constructor(private _store: Store) {
    const config: Config = this._store.selectSnapshot(
      ApplicationState.getConfig
    );

    this.assessmentsUri = config.authRedirectUri;
    this.assessments2Uri = config.assessments2Uri;
  }

  @Input() program: AboutMeStatus;
  @Input() user: User;

  @Output()
  ratersClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  @Output()
  selfClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  @Output()
  viewAssetClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  @Output()
  viewDocumentClick: EventEmitter<InstrumentStatus> =
    new EventEmitter<InstrumentStatus>();

  aboutMeClick() {
    window.location.href = `${this.assessments2Uri}/aboutme?retUrl=${this.assessmentsUri}`;
  }
}
