import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { MatCardModule } from '@angular/material/card';

import { GlobalExceptionHandler } from './global-exception-handler';
import { GlobalExceptionRoutingModule } from './global-exception-routing.module';
import { GlobalExceptionComponent } from './global-exception.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatCardModule,
    GlobalExceptionRoutingModule,
  ],
  declarations: [GlobalExceptionComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalExceptionHandler,
    },
  ],
})
export class GlobalExceptionModule {}
