import { Component, Input } from '@angular/core';

import {
  Question,
  QuestionAnswer,
} from '../../../../../survey/store/survey.model';
import { SurveyDemographicsService } from '../../services/survey-demographics.service';

@Component({
  selector: 'assess-multi-options',
  templateUrl: './multi-options.component.html',
})
export class MultiOptionsComponent {
  @Input() question: Question;
  @Input() index: number;

  constructor(private _surveyDemographicsService: SurveyDemographicsService) {}

  onQuestionAnswered(questionAnswer: QuestionAnswer): void {
    this._surveyDemographicsService.answerQuestion(questionAnswer);
  }

  onOtherAnswered(questionAnswer: QuestionAnswer): void {
    this._surveyDemographicsService.answerQuestion(questionAnswer);
  }
}
