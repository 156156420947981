import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'participant',
    redirectTo: '/participant',
    pathMatch: 'full',
  },
  {
    path: 'rater',
    redirectTo: '/rater',
    pathMatch: 'full',
  },
  {
    path: 'background',
    redirectTo: '/background',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
