import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HypermediaService } from '../../../core/hypermedia/hypermedia.service';
import { HypermediaFactoryService } from '../../../core/hypermedia/hypermedia-factory.service';

import {
  RaterStatus,
  Instrument,
  RaterManagement,
  Rater,
  EmailRequest,
  Program,
} from '../store/rater-management.models';
import { Config } from '../../../store/application.models';
import { Store } from '@ngxs/store';

interface RaterManagementResponse {
  totalRaterInvitedCount: number;
  allSurveysReceived: boolean;
  instrument: Instrument;
  raterStatuses: RaterStatus[];
  program: Program;
}

@Injectable({
  providedIn: 'root',
})
export class RaterManagementService {
  private _hypermediaService: HypermediaService = null;

  public constructor(
    private _store: Store,
    private _hypermediaFactoryService: HypermediaFactoryService
  ) {}

  initializeService(): void {
    if (this._hypermediaService === null) {
      const config: Config = this._store.selectSnapshot(
        (state) => state.application.config
      );

      this._hypermediaService = this._hypermediaFactoryService.create(
        config.assessmentWebApi,
        'rater-management'
      );
    }
  }

  getRaterManagement(
    participantId: number,
    instrumentId: number,
    preferredLanguageId: number
  ): Observable<RaterManagement> {
    this.initializeService();
    return this._hypermediaService
      .get('get', {
        participantId: participantId,
        instrumentId: instrumentId,
        preferredLanguageId: preferredLanguageId,
      })
      .pipe(map((r: RaterManagementResponse) => Object.assign({}, r)));
  }

  deleteRater(rater: Rater): Observable<void> {
    this.initializeService();
    return this._hypermediaService.delete('delete', rater.raterId.toString());
  }

  resendInvitation(emailRequest: EmailRequest): Observable<void> {
    this.initializeService();
    return this._hypermediaService.post('resend-invitation', {
      emailRequest: emailRequest,
    });
  }

  remindRaters(emailRequests: EmailRequest[]): Observable<void> {
    this.initializeService();
    return this._hypermediaService.post('send-reminders', {
      emailRequests: emailRequests,
    });
  }
}
