import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  ComponentFactoryResolver,
  Injector,
  Input,
  ViewChild,
  ViewContainerRef,
  ComponentFactory,
  ComponentRef,
  ChangeDetectionStrategy,
  Type,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { ComponentDescriptor } from './component-descriptor';

import * as Registry from './type-registry';

import { SurveyStepperService } from './survey-stepper.service';
import { SELF } from './survey-stepper.model';
import { CardHostDirective } from './card-host.directive';

@Component({
  selector: 'assess-survey-stepper',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-template assessCardHost></ng-template>',
  styles: [':host { height: 100%; width: 100% }'],
})
export class SurveyStepperComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(CardHostDirective, { static: true })
  cardHost: CardHostDirective;

  @Input()
  componentDescriptors: ComponentDescriptor[];
  @Input()
  currentSectionIndex: number = 0;

  nextSubscription: Subscription;
  previousSubscription: Subscription;

  private source: string = null;

  constructor(
    private _resolver: ComponentFactoryResolver,
    private _surveyStepperService: SurveyStepperService
  ) {}

  ngOnInit(): void {
    this.nextSubscription = this._surveyStepperService.next$.subscribe(
      (source: string) => this.next(source)
    );
    this.previousSubscription = this._surveyStepperService.previous$.subscribe(
      (source: string) => this.previous(source)
    );
  }

  ngOnChanges(): void {
    // If source is 'self' then component is already rendered
    // so reset for the next event and return.
    if (this.source === SELF) {
      this.source = null;
      return;
    }
    // create the card because of some external source changed the currentCard
    this.createCard();
  }

  ngOnDestroy(): void {
    if (this.nextSubscription) {
      this.nextSubscription.unsubscribe();
    }

    if (this.previousSubscription) {
      this.previousSubscription.unsubscribe();
    }
  }
  createCard(): void {
    let componentDescriptor = this.componentDescriptors[
      this.currentSectionIndex
    ];

    if (!componentDescriptor) return;

    let type = Registry.getTypeFor(componentDescriptor.component);
    if (!type)
      throw `
      ${componentDescriptor.component} not registered.
      Usage:
      The following line must be placed in the module's constructor:
      Registry.setTypeFor('${componentDescriptor.component}', ${componentDescriptor.component});
    `;

    this.renderComponent(type, this.createInjector());
  }

  private createInjector(): Injector {
    let injector: Injector = Injector.create(
      [],
      this.cardHost.viewContainerRef.parentInjector
    );

    return injector;
  }

  private next(source: string): void {
    this.source = SELF;
    this.currentSectionIndex += 1;
    this.createCard();

    this._surveyStepperService.changeSectionIndex({
      source: source,
      sectionIndex: this.currentSectionIndex,
    });
  }

  private previous(source: string): void {
    this.source = SELF;
    this.currentSectionIndex -= 1;
    this.createCard();

    this._surveyStepperService.changeSectionIndex({
      source: source,
      sectionIndex: this.currentSectionIndex,
    });
  }

  private renderComponent(type: Type<any>, injector: Injector): void {
    let factory: ComponentFactory<any> = this._resolver.resolveComponentFactory(
      type
    );

    let component: ComponentRef<any> = factory.create(injector);

    this.cardHost.viewContainerRef.clear();
    this.cardHost.viewContainerRef.insert(component.hostView);
  }
}
